import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import useHandleClassName from "../../hooks/useHandleClassName";
import {useTranslation} from "react-i18next";

const Step = forwardRef((props,ref) => {
  const {
    activeColor, completeColor, defaultColor, circleFontColor,
    activeTitleColor, completeTitleColor, defaultTitleColor,
    size, circleFontSize, titleFontSize,
    circleTop, titleTop, completeOpacity, activeOpacity, defaultOpacity, defaultTitleOpacity, barStyle, defaultBarColor,
    completeBarColor, defaultBorderColor, completeBorderColor, activeBorderColor,
    defaultBorderStyle, completeBorderStyle, activeBorderStyle, lineMarginOffset, defaultBorderWidth, descriptionFontSize,
    descriptionColor, activeCircleSize, activeDescriptionColor, activeCircleFontsize,dir,slideStyles
  } = props;
const {translate}=useHandleClassName();
  const getStyles = () => {
    return {
      step: {
      },
      activeStep: {
      },
      circle: {
        width: size,
        height: size,
        margin: `${activeCircleSize/2}px auto 0px`,
        backgroundColor: defaultColor,
        fontWeight: '700',
        borderRadius: '50%',
        textAlign: 'center',
        padding: 1,
        fontSize: circleFontSize,
        color: circleFontColor,
        display: 'block',
        opacity: defaultOpacity,
        borderWidth: (defaultBorderColor ? defaultBorderWidth : 0),
        borderColor: defaultBorderColor,
        borderStyle: defaultBorderStyle
      },
      activeCircle: {
        backgroundColor: activeColor,
        opacity: activeOpacity,
        borderWidth: (activeBorderColor ? defaultBorderWidth : 0),
        borderColor: activeBorderColor,
        borderStyle: activeBorderStyle,
        width: activeCircleSize,
        height: activeCircleSize,
        position: 'relative',
        fontSize: activeCircleFontsize,
        zIndex: 2,
        marginTop: `${size/2}px`,
      },
      completedCircle: {
        backgroundColor: completeColor,
        opacity: completeOpacity,
        borderWidth: (completeBorderColor ? defaultBorderWidth : 0),
        borderColor: completeBorderColor,
        borderStyle: completeBorderStyle,
      },
      index: {
        lineHeight: `${props.active ? activeCircleSize : size}px`,
        color: circleFontColor
      },
      title: {
        margin: props.active ? `${titleTop}px auto` : `${titleTop + (activeCircleSize - size) / 2}px auto`,
        fontSize: titleFontSize,
        fontWeight: '400',
        textAlign: 'center',
        whiteSpace:'normal',
        maxWidth:'50%',
        color: defaultTitleColor,
        opacity: defaultTitleOpacity,
      },
      activeTitle: {
        color: activeTitleColor,
      },
      completedTitle: {
        color: completeTitleColor,
      },
      description: {
        marginTop: titleTop,
        fontSize: descriptionFontSize,
        fontWeight: '400',
        textAlign: 'center',
        display: 'block',
        color: descriptionColor,
      },
      activeDescription: {
        color: activeDescriptionColor,
      },
      leftBar: {
        position: 'absolute',
        top: circleTop + size / 2,
        height: 1,
        borderTopStyle: barStyle,
        borderTopWidth: 1,
        borderTopColor: defaultBarColor,
        left: 0,
        right: '50%',
        marginRight: size / 2 + lineMarginOffset,
        opacity: defaultOpacity,
      },
      rightBar: {
        position: 'absolute',
        top: circleTop + size / 2,
        height: 1,
        borderTopStyle: barStyle,
        borderTopWidth: 1,
        borderTopColor: defaultBarColor,
        right: 0,
        left: '50%',
        marginLeft: size / 2 + lineMarginOffset,
        opacity: defaultOpacity,
      },
      completedBar: {
        borderTopStyle: barStyle,
        borderTopWidth: 1,
        borderTopColor: completeBarColor,
        opacity: completeOpacity,
      },
    };
  };
  const styles = getStyles();
  const stepStyle = Object.assign(
      styles.step,slideStyles,
      props.active ? styles.activeStep : {}
  );
  const circleStyle = Object.assign(
      styles.circle,
      props.completed ? styles.completedCircle : {},
      props.active ? styles.activeCircle : {},
  );
  const titleStyle = Object.assign(
      styles.title,
      props.completed ? styles.completedTitle : {},
      props.active ? styles.activeTitle : {},
  );
  const descriptionStyle = Object.assign(
      styles.description,
      props.active ? styles.activeDescription : {},
  );
  const leftStyle = Object.assign(styles.leftBar, (props.active || props.completed) ? styles.completedBar : {});
  const rightStyle = Object.assign(styles.rightBar, props.completed ? styles.completedBar : {});

  const stepContent = props.icon ? <img src={props.icon} alt={props.index + 1} /> : props.index + 1;
  return (
      <div style={stepStyle} className={props.className} dir={dir} ref={ref}>
        <div style={circleStyle}>
          {props.active || props.completed ? (
              <a className={translate("bold")} href={props.href} onClick={props.onClick} style={styles.index}>{stepContent}</a>
          ) : (
              <span style={styles.index}>{stepContent}</span>
          )}
        </div>
        <div className={translate("regular")} style={titleStyle}>{props.title}</div>
        <div className={translate("regular")} style={descriptionStyle}>{props.description}</div>
        {!props.first &&  <div style={leftStyle}></div>}
        {!props.isLast && <div style={rightStyle}></div>}
      </div>
  );
},{})

Step.defaultProps = {
  activeColor: '#0369A1',
  completeColor: '#059669',
  defaultColor: '#9CA3AF',
  activeTitleColor: '#505F74',
  completeTitleColor: '#505F74',
  defaultTitleColor: '#505F74',
  circleFontColor: '#FFFFFF',
  size: 28,
  circleFontSize: 15,
  activeCircleFontsize: 20,
  titleFontSize: 14,
  circleTop: 24,
  titleTop: 8,
  defaultBarColor: '#EAEAEA',
  completeBarColor: '#EAEAEA',
  barStyle: 'solid',
  borderStyle: 'solid',
  lineMarginOffset: 4,
  defaultBorderWidth: 3,
  descriptionFontSize: 13,
  descriptionColor: '#0369A1',
  activeDescriptionColor: '#EF4444',
  activeCircleSize: 46
};

Step.propTypes = {
  width: PropTypes.number.isRequired,
  activeColor: PropTypes.string,
  completeColor: PropTypes.string,
  defaultColor: PropTypes.string,
  activeTitleColor: PropTypes.string,
  completeTitleColor: PropTypes.string,
  defaultTitleColor: PropTypes.string,
  circleFontColor: PropTypes.string,
  size: PropTypes.number,
  circleFontSize: PropTypes.number,
  titleFontSize: PropTypes.number,
  circleTop: PropTypes.number,
  titleTop: PropTypes.number,
  title: PropTypes.string,
  index: PropTypes.number,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  first: PropTypes.bool,
  isLast: PropTypes.bool,
  completeOpacity: PropTypes.string,
  activeOpacity: PropTypes.string,
  defaultOpacity: PropTypes.string,
  completeTitleOpacity: PropTypes.string,
  activeTitleOpacity: PropTypes.string,
  defaultTitleOpacity: PropTypes.string,
  barStyle: PropTypes.string,
  defaultBarColor: PropTypes.string,
  completeBarColor: PropTypes.string,
  defaultBorderColor: PropTypes.string,
  completeBorderColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
  defaultBorderStyle: PropTypes.string,
  completeBorderStyle: PropTypes.string,
  activeBorderStyle: PropTypes.string,
  lineMarginOffset: PropTypes.number,
  defaultBorderWidth: PropTypes.number,
  activeDescriptionColor: PropTypes.string
};

export default Step;
