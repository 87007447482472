import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const useRegisterForm = (schema) => {
    const { register,setValue,watch, control,formState, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    return { register, formState, handleSubmit,setValue,watch,control};
}

export default useRegisterForm;