import styles from "./input.module.css"
import globalStyles from "../../globalStyles.module.css"
import useHandleTranslation from "../../hooks/useHandleTranslation";
import classNames from "classnames";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useState} from "react";
import {useTranslation} from "react-i18next";
const InputForm=({dir,important,label,placeholder,name,register,errors,password,suffix,cannotPaste,inputDir,rootClassName,disable,withoutLabel=false,containerClassName,stopTab,errorMessageDir})=>{
    const [isHiddenPassword,setHiddenPasswordStatus]=useState(true);
    const {t:tER}=useTranslation("formErrors");
    const {translateClassName}=useHandleTranslation()
    const inputFormStyles=classNames({
        [styles.inputFormItemContainer]:true,
        "!border-red-600": !!errors?.[name]?.message
    })
    const inputFormLabelStyles=classNames({
        [styles.inputFormLabel]:true,
        "!text-red-600": !!errors?.[name]?.message
    })
    const inputFormItemStyles=classNames({
        [styles.inputFormItem]:true,
        [styles.inputFormItemWithoutLabel]:withoutLabel,
        [styles.inputFormItemWithLabel]:!withoutLabel,
    })
    const errorMessageStyles=classNames({
        [globalStyles[translateClassName("regular")]]:true,
        [styles.errorMessage]:true,
        [styles.emptyErrorMessage]:!errors?.[name]?.message,
        [styles.notEmptyErrorMessage]:!!errors?.[name]?.message
    })
    const handlePaste=(e)=>{
      if(cannotPaste) e.preventDefault();
    }
    return(
        <div className={`${styles.inputFormContainer} ${rootClassName}`} dir={dir}>
            {!withoutLabel && <div className={inputFormLabelStyles} >
                {label}
                {important && <span className="text-red-500"> *</span>}
            </div>}
            <div className={`${inputFormStyles} ${containerClassName}`} dir={inputDir}>
                <input className={`${inputFormItemStyles} ${globalStyles[translateClassName("regular")]}`}
                       placeholder={placeholder}
                       type={password && isHiddenPassword ? "password":"text"}
                       {...register(name)}
                       onPaste={handlePaste}
                       dir={inputDir}
                       disabled={disable}
                       {... stopTab && {tabIndex:"-1"}}
                />
                {suffix && <div className={styles.inputSuffixItem}>
                    {suffix}
                </div>}
                {password && <div className="flex flex-col justify-center">
                    {isHiddenPassword ?
                        <EyeInvisibleOutlined onClick={()=>setHiddenPasswordStatus(false)}/>
                        : <EyeOutlined onClick={()=>setHiddenPasswordStatus(true)}/>
                    }
                </div>}
            </div>
            <div dir={errorMessageDir} className={errorMessageStyles}>{tER(errors?.[name]?.message)}</div>
        </div>
    )
}
export default InputForm;