import React, {useEffect, useState} from "react";
import useHandleTranstation from "../../hooks/useHandleTranslation";
import styles from "../../pages/sign/sign.module.css";

const Stepper=({steps,active,validator,displayed})=>{
    const [transform,setTransform]=useState();
    const {isLngFr,isLngAr}=useHandleTranstation();
    useEffect(() => {
        if(!isNaN(active)){
            let validActive=0;
            if(active<steps.length && active>=0) validActive=active;
            else if(active>=steps.length) validActive=steps.length-1;
            if (isLngFr) setTransform(validActive*(-100));
            if (isLngAr) setTransform(validActive*100);
        }
    }, [active]);
    return (
        <div className="w-full flex overflow-x-clip mx-auto min-h-full">
            {steps.map((Step,index)=> (
                displayed[Step.name]?.display && <div key={index} className={`${styles.sliderStyle}`} style={{transform:`translateX(${transform}%)`}}>
                    <Step.slide {...Step.props} validate={validator?.[Step.name]} validators={validator}  />
                </div>
            ))}
        </div>
    )
}
export default Stepper;