import BodyCellAction from './tcell/BodyCellAction.jsx';
import BodyCell from './tcell/BodyCell.jsx';
import PropTypes from 'prop-types';
import classNames from "classnames";
import styles from './tbody.module.css'
const TDataBody = ({ rows, prepareRow, bodyProps, actions }) => {
    const rowStyle=(index)=>classNames({
        [styles.firstRowColor]:index % 2 ===0,
        [styles.secondRowColor]: index % 2 ===1
    })
    return (
        <tbody {...bodyProps} className="bg-white divide-y divide-gray-200">
        {rows.map((row, index_1) => {
            prepareRow(row);
            return (
                <tr key={index_1} {...row.getRowProps()} className={rowStyle(index_1)}>
                    {row.cells.map((cell, index_2) => {
                        if (cell.column.Header === 'Actions')
                            return (
                                <BodyCellAction
                                    actionCell={cell}
                                    key={index_2}
                                    actions={actions}
                                />
                            );
                         else
                            return cell.column.show ? (
                                <BodyCell cell={cell} key={index_2} />
                            ) : null;
                    })}
                </tr>
            );
        })}
        </tbody>
    );
};
export default TDataBody;
TDataBody.propTypes = {
    rows: PropTypes.array,
    prepareRow: PropTypes.func,
    bodyProps: PropTypes.func,
    actions: PropTypes.array,
};