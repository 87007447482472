import { useEffect } from 'react';
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';
import Thead from './thead/Thead';
import Pagination from './Pagination';
import PropTypes from 'prop-types';
import TBody from './tbody/TBody';
import styles from './table.module.css'

const Table = ({
                   rows,
                   totalElements = 1,
                   columns,
                   setPagination = () => {},
                   pagination = { size: 1, page: 1 },
                   isLoading,
                   isError,
                   isSuccess,
                   actions,
                   emptyDataPrimaryMessage,
                   emptyDataSecondaryMessage,
                   disablePagination = false,
                   sorting = [{}],
                   setSorting = () => {},
                   translate=()=>{}
               }) => {
    const getTotalPages = () => Math.ceil(totalElements / pagination.size);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        setPageSize,
        canNextPage,
        canPreviousPage,
        state: { pageIndex, pageSize,sortBy },
    } = useTable(
        {
            columns,
            data: rows,
            manualPagination: true,
            manualSortBy: true,
            pageCount: getTotalPages(),
            autoResetPage: true,
            initialState: {
                pageIndex: pagination.page - 1,
                pageSize: pagination.size,
                sortBy:sorting,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const pageOptions = {
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
    };

    useEffect(() => {
        setPagination(() => {
            return { page: pageIndex + 1, size: pageSize };
        });
    }, [pageIndex, pageSize]);

    useEffect(() => {
        setSorting(sortBy.length!==0 ? sortBy:[{id:"",desc:false}]);
    }, [sortBy]);
    return (
        <>
            <div className="overflow-x-auto">
            <table {...getTableProps} className={styles.table}>
                <Thead header={headerGroups}    translate={translate} />
                <TBody
                    isLoading={isLoading}
                    isError={isError}
                    isSuccess={isSuccess}
                    page={page}
                    prepareRow={prepareRow}
                    bodyProps={getTableBodyProps}
                    actions={actions}
                    headerGroups={headerGroups}
                    emptyDataSecondaryMessage={emptyDataSecondaryMessage}
                    emptyDataPrimaryMessage={emptyDataPrimaryMessage}
                />
            </table>
            </div>
            {disablePagination || isLoading || isError || rows.length === 0 ? (
                ''
            ) : (
                <Pagination
                    page={pageIndex}
                    pages={getTotalPages()}
                    size={pageSize}
                    pageOptions={pageOptions}
                />
            )}
        </>
    );
};
export default Table;

Table.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array,
    setPagination: PropTypes.func,
    pagination: PropTypes.object,
    isLoading: PropTypes.bool,
    actions: PropTypes.array,
    isError: PropTypes.bool,
    totalRows: PropTypes.number,
    isSuccess: PropTypes.bool,
    totalElements: PropTypes.number,
    emptyDataSecondaryMessage: PropTypes.string,
    emptyDataPrimaryMessage: PropTypes.string,
    disablePagination: PropTypes.bool,
};