import React, {useState} from 'react';
import Btn from '../components/button/Btn';
import apple from "../asset/images/footer/apple.svg";
import playstore from "../asset/images/footer/playstore.svg";
import socialmedia1 from "../asset/images/footer/socialmedia1.svg";
import socialmedia5 from "../asset/images/footer/socialmedia5.svg";
import Icon from '../components/util/Icon';
import {LogoFooterAr, LogoFooterFr, LogoFr} from "../asset/images/svgImages";
import {useTranslation} from "react-i18next";
import useHandleClassName from "../hooks/useHandleClassName";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {FACEBOOK_URL, YOUTUBE_URL} from "../util/constants";
import {LinkAdapter} from "./home/LinkAncre";
import {openInNewTab} from "../util/functionsUtil";

const Column = (props) => {
    const [isCollapsed, setCollapsed] = useState(false);
    const containerClassName = `footer-column-items ${isCollapsed ? 'collapsed-footer-mini' : 'nonCollapsed-footer-mini'}`;

    const toggleCollapse = () => {
        setCollapsed(!isCollapsed);
    };
    const items = props.data?.column?.filter(item=>item.Link.length!==0).map((item, index) => (
        <React.Fragment key={index}>
            <LinkAdapter newTab  link={item?.Link} data={item?.Label} className="leading-normal cursor-pointer"/>
        </React.Fragment>
    ));
    return (
        <>
        <div className="footer-column responsive-mobile">
            <div onClick={toggleCollapse} className="footer-column-title-mini" ><div>{props.data?.title?.toUpperCase()}</div> <div> {!isCollapsed? <DownOutlined />: <UpOutlined />} </div></div>
            <div className={`${containerClassName}`}>{items}</div>
        </div>
        <div className="footer-column responsive-web">
            <div>{props.data?.title?.toUpperCase()}</div>
            <div className={"footer-column-items"}>{items}</div>
        </div>
        </>
    );
};
const FooterLarge = (props) => {
    const {i18n,t}=useTranslation();
    return (
        <>
            <div className='footer-top' style={{marginBottom: "3%"}}>
                <div>
                    {i18n.language === "fr" ?  <LogoFooterFr/> : (i18n.language === "ar" ? <LogoFooterAr/> : (""))}
                    <div style={{fontSize: "14px"}}>{props.textRokhasFooter}</div>
                    <div>{props.socialMedia}</div>
                </div>
                <div className='footer-columns'> {props.columns} </div>
            </div>

            <div dir={"ltr"} className='footer-rights-container'>
                {props.footerRigths}
                {/*{props.buttonsDownload}*/}
            </div>

            <Icon iconName="waves1_system" className='footer-waves-left'/>
            <Icon iconName="waves2_system" className='footer-waves-right'/>
        </>
    );
}

const FooterMini = (props) => {
    const {i18n,t}=useTranslation();

    return (<div className='footer-mini'>
        <div className='footer-mini-logo'>
            {i18n.language === "fr" ?  <LogoFooterFr/> : (i18n.language === "ar" ? <LogoFooterAr/> : (""))}
        </div>
        <div className='footer-columns-mini'>
            {props.columns?.map(
                (column, index) => (
                    <React.Fragment key={index}>
                        {column}
                    </React.Fragment>
                )
            )}
        </div>
        {/*<div>{props.buttonsDownload}</div>*/}
        <div className='footer-mini-socialmedia'>{props.socialMedia}</div>
        <div className='footer-rights-container'>{props.footerRigths}</div>
    </div>)
};






const Footer = (props) => {
    const {footerFontClassName}=useHandleClassName();
    const columns = props.data?.columns?.map(
        (column, index) => <Column key={index} data={column} />
    );
    const socialMedia = [{Icon:socialmedia1,Link:`${FACEBOOK_URL}/Rokhas.ma`}, {Icon:socialmedia5,Link:`${YOUTUBE_URL}/rokhas`}].map(
        (img, index) => (
            <React.Fragment key={index}>
                <span  className="cursor-pointer" onClick={()=>openInNewTab(img.Link)}><img src={img.Icon} alt={`social-media-${index}`} /></span>
            </React.Fragment>
        )
    );

    const buttonsDownload = (
        <div className="btns-footer-mini" dir={"ltr"}>
            <Btn className="btn-footer"
                 content={<span className='footer-button-content'>
                    <img src={apple} alt="apple" />
                    <span>
                        <span>{props.data?.topButtons}</span>
                        <span>Apple Store</span>
                    </span>
                </span>} />
            <Btn className="btn-footer"
                 content={<span className='footer-button-content'>
                    <img src={playstore} alt="playstore" />
                    <span>
                        <span>{props.data?.topButtons}</span>
                        <span>Google Play</span>
                    </span>
                </span>} />
        </div>
    );

    const footerRigths = (
        <div style={{ color: "#DDDDDD", display: 'flex', alignItems: 'center' }}>
            © 2024 {props.data?.rokhas?.toUpperCase()} - {props.data?.reservedRights?.toUpperCase()}
        </div>
    );

    return (
        <div className={`footer ${footerFontClassName()}`}>
            <div className='footer-mini-layout'>
                <FooterMini columns={columns} buttonsDownload={buttonsDownload} socialMedia={socialMedia} footerRigths={footerRigths} />
            </div>
            <div className='footer-large-layout' style={{ padding: '45px' }} >
                <FooterLarge columns={columns} buttonsDownload={buttonsDownload} socialMedia={socialMedia} footerRigths={footerRigths} textRokhasFooter={props.data?.textRokhasFooter} />
            </div>
        </div>
    );
};

export default Footer;