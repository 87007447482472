import React, { useState, useEffect, useCallback } from 'react';
import httpService from '../../service/HttpService';

const Icon = ({ iconName, iconType, className="",iconClassName="" }) => {
    const [base64Image, setBase64Image] = useState(undefined);
    const axios=httpService.getAxiosClient();
    const [urlImage, setUrlImage] = useState("");

    let callback = useCallback(()=> {
        if (iconName?.split(':system')?.length > 1) {
            axios.get(`/front/karazal/kas/api-el/content/icons/${iconName?.split(':system')[0]}`)
                .then((response) => response.data)
                .then((data) => {
                    setBase64Image(data?._source?.base64Image);

                })
                .catch((error)=>setBase64Image(null))
        }else{
            setBase64Image(null);
        }
    },[iconName]);

    useEffect(() => callback(), []);
    useEffect(() => {
    if(base64Image!==undefined) setUrlImage( base64Image? atob(base64Image):('/iconsSupplimentaires/' + iconNameWithoutSystem + '.svg'))
    }, [base64Image]);

    let iconNameWithoutSystem = iconName?.split('_system')[0]?.split(':system')[0];
    return (
        <>
            {iconNameWithoutSystem === iconName ? (
                <span className={`to-unset-font fa-${iconType} fa-${iconName} ${className}`}></span>
            ) : (
                <span className={`to-unset-font ${className}`}>
          <img
              className={`logo-img ${iconClassName}`}
              src={urlImage}
              alt="Icon"
          />
        </span>
            )}
        </>
    );
};

export default Icon