import PropTypes from 'prop-types';
import styles from './cell.module.css';
const BodyCell = ({ cell }) => {
    const cellColor=cell.column.color?? "inherit";
    return (
        <td
            {...cell.getCellProps()}
            className={`${styles.td}`}
            role="cell"
            style={{background:cellColor}}
        >
            {cell.column.Cell.name === 'defaultRenderer' ? (
                <div className="text-sm text-gray-500 w-full">{cell.render('Cell')}</div>
            ) : (
                <div
                    className="text-sm text-gray-500 w-full text-center"
                    style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                    }}
                >
                    {cell.render('Cell')}
                </div>
            )}
        </td>
    );
};

export default BodyCell;
BodyCell.propTypes = {
    cell: PropTypes.object,
    handleEdit: PropTypes.func,
};