import {ConfigProvider, Spin} from "antd";

export const TLoadingBody = ({ columnSize }) => {
    return (
        <>
            <tbody className="bg-white divide-y divide-gray-200">
            <tr>
                <td className="text-center" colSpan={columnSize}>
                    <div className="flex justify-center p-12">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Spin: {
                                        dotSizeLG:70
                                    },
                                },
                            }}
                        ><div className={"flex justify-center w-full"}>
                            <Spin size={"large"}/>
                        </div>
                        </ConfigProvider>
                    </div>
                </td>
            </tr>
            </tbody>
        </>
    );
};