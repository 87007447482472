import classNames from "classnames";
import styles from "../../pages/sign/sign.module.css";
import {SignupConfirmationFaillure, SignupConfirmationSuccess} from "../../asset/images/svgImages";
import React from "react";

export const ConfirmationCard=({title,firstMessage,secondMessage,error})=> {
    const titleStyle=classNames({
        ["!text-[#F59E0B]"]:error,
        [styles.confirmTitle]:true
    })
    const supportEmail="support@rokhas.ma"
    return(<div className="flex  flex-column gap-8 items-center pb-6 pt-24">
        {error? <SignupConfirmationFaillure/>: <SignupConfirmationSuccess/>}
        <div className={titleStyle}>{title}</div>
        <div className="flex flex-column items-center">
            <p className={styles.confirmMessage}>{
                firstMessage.includes("support@rokhas.ma") ?
                    <>
                        {firstMessage.split("support@rokhas.ma")[0]}
                        <a className={styles.signLink}>{supportEmail}</a>
                        {firstMessage.split("support@rokhas.ma")[1]}
                    </>
                    :firstMessage
            }</p>
            <p className={styles.confirmMessage}>
                {
                    secondMessage.includes("support@rokhas.ma") ?
                        <>
                            {secondMessage.split("support@rokhas.ma")[0]}
                            <a className={styles.signLink}>{supportEmail}</a>
                            {secondMessage.split("support@rokhas.ma")[1]}
                        </>
                        :secondMessage}
            </p>
        </div>

    </div>)
}
