import React, {useEffect, useState} from 'react';
import left from './../../asset/images/left.svg';
import right from './../../asset/images/right.svg';
import './css/Card.css';
import { useGetArticleDataByMenu} from '../../api/caller';
import {useTranslation} from 'react-i18next';
import useHandleTranslation from "../../hooks/useHandleTranslation";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import ArticleSkeleton from "../../pagescomponents/articlecard/ArticleSkeleton";
import ErrorBoundaries from "../../pagescomponents/errorBoundaries/ErrorBoundaries";
import {scrollToTopOnMount} from "../../util/functionsUtil";


const SingleArticleComponent = (props) => {
  const {currLng} = useHandleTranslation();
  const getArticleData = useGetArticleDataByMenu(props.menu,currLng);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { t: tBC } = useTranslation('breadcrumb');

  const breadcrumbParts = [
    { text: tBC("Accueil"), link: '/' },
    { text: tBC(props.menu), link: '/' }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    scrollToTopOnMount();
  }, [props.menu]);

  const content=()=>{
    if(isMobile) return getArticleData.data?.page?.[0]?.contentMob ;
    return getArticleData.data?.page?.[0]?.content
  }

  return (
      <div className={"min-h-svh"}>
        <div className="breadcrumb">
          <BreadCrumb parts={breadcrumbParts}/>
        </div>
        <div>
          {   getArticleData.isSuccess
            && getArticleData.isFetched
            && getArticleData.data?.page?.length!==0
            && <>
                { (!getArticleData.isFetching || !getArticleData.isLoading)
                    && <div dir={"ltr"} className={'header-single-article ' + currLng}>
                      <img src={left} className='header-single-article-img' alt=""/>
                      <div className="header-single-article-menu">{tBC(props.menu)}</div>
                      <img src={right} className='header-single-article-img' alt=""/>
                    </div>
                }
                <HTMLContent content={content()}/>
              </>
          }
          {   (getArticleData.isError || getArticleData.data?.page?.length===0)
              && <ErrorBoundaries text={"L'article introuvable"}/>
          }

        </div>
        {getArticleData.isLoading && getArticleData.isFetching && <ArticleSkeleton/>}
      </div>
  );
};

const HTMLContent = ({content}) => (<div className='content'  dir="ltr" dangerouslySetInnerHTML={{__html: content}} style={{width: "100%", overflow: "auto"}}/>)


export default SingleArticleComponent;
