import {useEffect, useRef, useState} from "react";

export  const useComponentVisible=()=> {
    const [isOpen, setOpenStatus] = useState(false);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenStatus(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener(
                "click",
                handleClickOutside
            );
        };
    });

    return { ref,isOpen, setOpenStatus };
}
export default useComponentVisible;