import TagCard from "../../components/cards/tagcard/TagCard";
import './articlecard.css'
import {Skeleton} from 'antd';
import {useNavigate} from "react-router-dom";
import {redirect} from "../../util/functionsUtil";




export const ArticleCardData=(props)=>{
    const navigate = useNavigate();
    return(
        <>
        <div className={"card-container"}>
            <div className={"card-image-container"}>
                <img className="card-image-item" src={`/front${props.data?.image}`}/>
            </div>
            <div className={"card-content-container"} >
            <div className="card-container-infos" onClick={() => navigate(`/articles/${props.data?.menu[0]}/${props.data?.id}`)}>
                <p className={"card-title-item regular-fr"}>{props.data?.title}</p>
                <p className={"card-description-item regular-fr"}>{props.data?.description}</p>
            </div>
            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                {props.data?.tags.map((object,index)=> <TagCard key={index} text={object.tag} color={"#0369A1"} backgroud={"#E0F2FE"} size="12px" margin={"5px 2px"}/>) }
            </div>
            </div>
        </div>
        </>
    )
}
export const ArticleCardSkeleton=()=>{
    return  (<>
        <div className={"card-container"}>
            <div className={"card-image-container"}>
                <Skeleton.Image active={true} style={{width:"100%",height:"200px", borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px"}}/>
            </div>
            <div className={"card-content-container"}>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <Skeleton paragraph={{ rows: 3 }} active={true} />
                </div>
                <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-evenly"}}>
                    <Skeleton.Input active={true} size={17} />
                </div>
            </div>
        </div>
    </>)
}
