import React, { useEffect} from "react";
import { useParams } from "react-router-dom";
import SingleArticleComponent from "../../pages/article/SingleArticleComponent";
import ArticleListPage from "./ArticleListPage";
import { MULTIPLE_ARTICLES_MENU, SINGLE_ARTICLES_MENU} from "../../util/constants";
import { scrollToTopOnMount} from "../../util/functionsUtil";
import PageNotFound from "../../pagescomponents/errorPages/PageNotFound";

const ArticlesPage = () => {
    const { menu } = useParams();
    const isSingleArticle= SINGLE_ARTICLES_MENU.includes(menu);
    const isMultipleArticle= MULTIPLE_ARTICLES_MENU.includes(menu);
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    return (
    <div>
        {isSingleArticle && <SingleArticleComponent  menu={menu} />}
        {isMultipleArticle && <ArticleListPage/>}
        {!isMultipleArticle && !isSingleArticle && <PageNotFound/>}
    </div>
  );
};
export default ArticlesPage;