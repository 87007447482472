import React from 'react';

const VideoPlayer = (props) => {
  const videoUrl = props.videoUrl;

  return (
    <>
    <video  className="card-media" controls>
    <source src={videoUrl} type="video/ogg" />
    </video>
    </>
      
      
    
  );
};

export default VideoPlayer;
 