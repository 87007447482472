import React, { Component } from 'react'
import Icon from '../../components/util/Icon';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {
    REDIRECTION_PATHS,
} from "../../util/constants";
import {openInNewTab, redirect} from "../../util/functionsUtil";
import useRokhasNavigate from "../../hooks/useRokhasNavigate";

const  LinkAncre =(props) =>  {
  const {navigateTo}=useRokhasNavigate();
  const {i18n}=useTranslation();

        return (
            <div className={`flex-vcenter ${props.className}`} style={{gap:"2%"}} >
                <div style={{height:"70px",display:'flex',alignItems:"center",maxWidth:"90%"}}>
                    <span className='global-components-link' onClick={()=>navigateTo(props.link,props.redirectAll,props.newTab)}>{props.data}</span>
                </div>
                <div style={{paddingTop:"4px"}}>
                    { i18n.language==="fr" && <Icon iconType="light" iconName={"arrow-right"} />}
                    {i18n.language==="ar" && <Icon iconType="light" iconName={"arrow-left"} />}
                </div>
            </div>
        )
}

export const LinkAdapter =(props) =>  {
    const {navigateTo}=useRokhasNavigate();
    if(props.children) return <a className={props.className} onClick={onClick}>{props.children}</a>
    return(<span className={props.className} onClick={()=>navigateTo(props.link,props.redirectAll,props.newTab)}>{props.data}</span>)
}
export default LinkAncre;