import {useTranslation} from "react-i18next";
import {Carousel} from "antd";
import styles from "../../pages/sign/sign.module.css";
import {LoginCarousel1, LoginCarousel2, LoginCarousel3} from "../../asset/images/svgImages";
import React from "react";

const SignCarousel=()=>{
    const {t:tS}=useTranslation("signup")
    return (
        <div className="flex flex-col justify-center h-screen">
            <Carousel autoplay autoplaySpeed={8000} >
                <div className="!flex !flex-col !items-center !justify-center !gap-6 px-2 ">
                    <div className="px-4">
                        <p className={styles.carouselText}>{tS("caroussel_title1")}</p>
                        <p className={styles.carouselDescription}>{tS("caroussel_description1")}</p>
                    </div>
                    <LoginCarousel1/>
                </div>
                <div className="!flex !flex-col !items-center !justify-center !gap-6 px-2 py-16">
                    <div className="px-4">
                        <p className={styles.carouselText}>{tS("caroussel_title2")}</p>
                        <p className={styles.carouselDescription}>{tS("caroussel_description2")}</p>
                    </div>
                    <LoginCarousel2/>
                </div>
                <div className="!flex !flex-col !items-center !justify-center !gap-6 px-2 py-16">
                    <div className="px-4">
                        <p className={styles.carouselText}>{tS("caroussel_title3")}</p>
                        <p className={styles.carouselDescription}>{tS("caroussel_description3")}</p>
                    </div>
                    <LoginCarousel3/>
                </div>

            </Carousel>
            <div className="h-1/8"/>
        </div>
    )
}
export default SignCarousel;