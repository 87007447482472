import {useTranslation} from "react-i18next";

const useHandleClassName=()=>{
    const {i18n}=useTranslation()
    const translate= (className) => {
        if (!className) {
            console.warn("Class name is undefined or null");
            return "";
        }

        return `${className}-${i18n.language}`;
    };
    const footerFontClassName=()=>{
        switch (i18n.language){
            case "fr" : return "rubik";
            case "ar" : return "regular-ar";
            default : return "";
        }
    }
    return {translate,footerFontClassName};
}

export default useHandleClassName;