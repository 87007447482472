import * as React from 'react';
import { useState } from 'react';
import './BasicButtons.css';
import {LoadingOutlined} from "@ant-design/icons";
import classNames from "classnames";
import useHandleClassName from "../../hooks/useHandleClassName";
const BasicButtons=(props)=> {
const {translate}=useHandleClassName();
    const buttonClasses = classNames({
        'disabled-button': props.loading,
        'basic-btn-ui mt-ui-btn': true,
    });
  return (
    <div className={"button-container"} >
      <button className={`${buttonClasses} ${translate("regular")}`} onClick={props.onClick} disabled={props.loading} >{props.loading? <LoadingOutlined />:""} {props.title}</button>
    </div>
  );
}
export default BasicButtons;