import styles from "./sign.module.css";
import React, {createContext, useContext, useEffect, useState} from "react";
import { ConfigProvider, Spin} from "antd";
import { LogoFr} from "../../asset/images/svgImages";
import Btn from "../../components/button/Btn";
import classNames from "classnames";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import globalPagesStyles from "../globalstyles.module.css";
import SignCarousel from "../../pagescomponents/login/SignCarousel";
import LegalFooter from "../../pagescomponents/login/LegalFooter";
import {useTranslation} from "react-i18next";
import {toHome,toLogin} from "../../util/functionsUtil";
import Stepper from "../../pagescomponents/login/Stepper";
import InputForm from "../../components/input/InputForm";
import {SIGN_PASSWORD_CHANGE} from "../../holders/schemas";
import useRegisterForm from "../../hooks/useRegisterForm";
import {useCheckTokenResetPassword, useSendResetPassword} from "../../api/caller";
import {useParams} from "react-router-dom";
import {ConfirmationCard} from "../../pagescomponents/signupcard/SignCards";

const StepsContext=createContext();

const PasswordChange=()=>{
    const {token}=useParams();
    const {t: tS} = useTranslation("signup")
    const {isLngAr,isLngFr}=useHandleTranslation();
    const [activeStep,setActiveStep]=useState(0);
    const stepsOptions= {stepA:{display:true,skip:false},stepB:{display:true,skip:false}}
    const checkTokenResetPassword=useCheckTokenResetPassword(token);
    const steps=[
        {key: 0, name: "stepA", slide: ChangePasswordStep,props:{}},
        {key: 1, name: "stepB", slide: SuccessNotificationStep,props:{}}
    ]
    const forms= {
        stepA: useRegisterForm(SIGN_PASSWORD_CHANGE),
    }
    const toLastStep=()=>{
        setActiveStep(1);
    }
    const pageContentContainerStyles=classNames({
        [styles.pageContentContainerAr]:isLngAr,
        [styles.pageContentContainerFr]:isLngFr,
        [styles.pageContentContainer]:true
    })
    return (
        <div className="flex w-full gap-2">
            <div className={`${styles.carouselContainer} ${globalPagesStyles.responsiveWeb}`}>
                <SignCarousel/>
            </div>
            <div className={pageContentContainerStyles}>
                <div className={styles.minHight}>
                    <div className={styles.logoContainer}>
                        <LogoFr/>
                    </div>
                    {
                        checkTokenResetPassword.customHandler.isSuccess &&
                        <StepsContext.Provider value={{toLastStep}}>
                            <Stepper active={activeStep} validator={forms} steps={steps} displayed={stepsOptions}/>
                        </StepsContext.Provider>
                    }
                    {
                        checkTokenResetPassword.customHandler.isError &&
                        <div className="w-11/12 flex h-full">
                            <div className="mx-auto flex flex-col items-center justify-center gap-10">

                                <ConfirmationCard
                                    title={tS("sign_changepass_errortoken_title")}
                                    firstMessage={tS("sign_changepass_errortoken_firstMessage")}
                                    secondMessage={tS("sign_changepass_errortoken_secondMessage")}
                                    error
                                />
                            </div>
                        </div>
                    }
                    {
                    (checkTokenResetPassword.isLoading || checkTokenResetPassword.isFetching) &&
                        <div className="w-11/12 flex flex-col gap-2 h-full">
                            <div className="mx-auto flex flex-col items-center justify-center gap-10 pt-24">
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Spin: {
                                                dotSizeLG:120
                                            },
                                        },
                                    }}
                                ><div className={"flex justify-center py-36 w-full"}>
                                    <Spin size={"large"}/>
                                </div>
                                </ConfigProvider>
                            </div>
                        </div>
                    }
                </div>
                <LegalFooter/>
            </div>
        </div>)
}

const ChangePasswordStep = ({validate}) =>{
    const {token}=useParams();
    const {t: tS} = useTranslation("signup")
    const {toLastStep} = useContext(StepsContext);
    const resetPassword=useSendResetPassword();
    const onSubmitData=(data)=>{
        resetPassword.mutate({token,password:data.password,repassword:data.repassword})
    }

    useEffect(() => {
        if (resetPassword.customHandler.isSuccess ) toLastStep();
    }, [resetPassword.customHandler.isSuccess]);

    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <p className={styles.pageTitle}>{tS("sign_changepass_successnotif_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("sign_changepass_passenter_description")}</p>
            </div>
            <div className="grid grid-rows-2 w-2/3 mx-auto">
                <div className="flex flex-col gap-3">
                    <label className={styles.forgetPassInputLabel}>{tS("sign_changepass_passenter_input1_label")}</label>
                    <InputForm placeholder={tS("sign_changepass_passenter_input1_label")}  register={validate?.register} name={"password"}
                               errors={validate?.formState?.errors} password withoutLabel/>
                </div>
                <div className="flex flex-col gap-3">
                    <label className={styles.forgetPassInputLabel}>{tS("sign_changepass_passenter_input2_label")}</label>
                    <InputForm placeholder={tS("sign_changepass_passenter_input1_label")}  register={validate?.register} name={"repassword"}
                               errors={validate?.formState?.errors} password withoutLabel/>
                </div>
                <div>
                    <div className="w-1/2 py-4 mx-auto">
                        <SignInBtn label={tS("sign_changepass_passenter_button")} isLoading={resetPassword.isLoading} paddingY={18} onClick={validate?.handleSubmit(onSubmitData)}/>
                    </div>
                    <div className={styles.singIn}>
                        <a className={styles.signLink} onClick={() => toHome()}>{tS("sign_changepass_passenter_link")}</a>
                    </div>

                </div>
            </div>
        </div>
    )
}
const SuccessNotificationStep = () => {
    const {t: tS} = useTranslation("signup")
    return (
        <div className="w-11/12 flex flex-col justify-between gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("sign_changepass_successnotif_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("sign_changepass_successnotif_description")}</p>
            </div>
            <div className="mx-auto flex flex-col items-center justify-center gap-10">
                <i className="fa-regular fa-square-check !text-primary text-7xl"></i>
                <div className="flex flex-col items-center gap-3">
                    <span
                        className={styles.pageSignUpMessage}>{tS("sign_changepass_successnotif_description2_1")}</span>
                    <span
                        className={styles.pageSignUpMessage}>{tS("sign_changepass_successnotif_description2_2")}</span>

                </div>
                <SignInBtn label={tS("login_button")} onClick={() => toLogin()} paddingX={100} paddingY={12}/>
                <a className={styles.signLink} onClick={() => toLogin()}>{tS("return_button")}</a>
                <div className="min-h-3.5"></div>
            </div>

        </div>
    )
}

const SignInBtn = ({
                       label,
                       disabled = false,
                       iconName,
                       color = "#ffffff",
                       backgroudColor = "#0369A1",
                       borderColor = "#0369A1",
                       paddingX = 0,
                       paddingY = 0,
                       onClick = () => {return},
                       isLoading = false
                   }) => {
    return (
        <Btn
            className="blue-button flex items-center justify-center" color={color}
            backgroundColor={disabled ? "#C6C6C6" : backgroudColor} borderColor={disabled ? "#C6C6C6" : borderColor}
            rootClassName={"!max-w-full"}
            paddingX={paddingX} paddingY={paddingY}
            onClick={!disabled ? onClick : () => {
            }}
        >
            <div className="flex gap-4 items-center">
                {label}
                {isLoading ? <i className="fa-solid fa-spinner-third fa-spin"/> : iconName && <i className={`fa-solid fa-${iconName}`}/>}
            </div>
        </Btn>
    )
}

export default PasswordChange;