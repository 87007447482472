import globalStyles from "../globalstyles.module.css";
import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./sign.module.css"
import Btn from "../../components/button/Btn";
import {redirect} from "../../util/functionsUtil";
import {LOGIN_URL} from "../../util/constants";
import {useGetSignUpConfirmation} from "../../api/caller";
import {ConfigProvider, Spin} from "antd";
import {useParams} from "react-router-dom";

import {ConfirmationCard} from "../../pagescomponents/signupcard/SignCards";
const SignConfirmation = () => {
       const {t:tS} = useTranslation("signup");
       const {dataObjectId}=useParams();
       const getSignUpConfirmation=useGetSignUpConfirmation(dataObjectId);
    const login=()=>{
        redirect( LOGIN_URL);
    }
    return(
        <>
            <div className={`${globalStyles.pageTitleContainer} ${globalStyles.responsiveWeb}`}>
                <div className={globalStyles.pageTitle}>{tS("sign_confirm_title")}</div>
            </div>
            <div className={`${globalStyles.pageTitleContainerMobile} ${globalStyles.responsiveMobile}`}>
                <div className={globalStyles.pageTitleMobile}>{tS("sign_confirm_title")}</div>
            </div>
            <div className={styles.confirmPageContent}>

                {
                    (getSignUpConfirmation.isLoading || getSignUpConfirmation.isFetching) &&
                    <ConfigProvider
                        theme={{
                            components: {
                                Spin: {
                                    dotSizeLG:120
                                },
                            },
                        }}
                    ><div className={"flex justify-center py-36 w-full"}>
                        <Spin size={"large"}/>
                    </div>
                    </ConfigProvider>
                }

                {
                   getSignUpConfirmation.isFetched && getSignUpConfirmation.data?.status===2000 &&
                    <>
                    <ConfirmationCard
                        title={tS("sign_confirm_success_title")}
                        firstMessage={tS("sign_confirm_success_firstMessage")}
                        secondMessage={tS("sign_confirm_success_secondMessage")}
                    />
                        <div className="flex justify-center w-full py-8" >
                            <SignUpBtn label={tS("login_button")} onClick={login} paddingX={100} paddingY={12}/>
                        </div>

                    </>

                }
                {
                    getSignUpConfirmation.isFetched && getSignUpConfirmation.data?.status===2001 &&
                    <ConfirmationCard
                    title={tS("sign_confirm_error_title")}
                    firstMessage={tS("sign_confirm_error_firstMessage")}
                    secondMessage={tS("sign_confirm_error_secondMessage")}
                    error
                    />
                }
                {
                    getSignUpConfirmation.isFetched && getSignUpConfirmation.data?.status===2002 &&
                    <ConfirmationCard
                    title={tS("sign_confirm_progress_title")}
                    firstMessage={tS("sign_confirm_progress_firstMessage")}
                    secondMessage={tS("sign_confirm_progress_secondMessage")}
                    />
                }


            </div>


        </>
    )
}

const SignUpBtn = ({
                       label,
                       disabled = false,
                       iconName,
                       color = "#ffffff",
                       backgroudColor="#0369A1",borderColor="#0369A1",paddingX=0,paddingY=0,onClick=()=>{},isLoading=false})=>{
    return (
        <Btn
            className="flex items-center justify-center" color={color}
            backgroundColor={disabled ? "#C6C6C6" : backgroudColor} borderColor={disabled ? "#C6C6C6" :borderColor}
            paddingX={paddingX} paddingY={paddingY}
            onClick={!disabled ? onClick: ()=>{}}
            hover
        >
            <div className="flex gap-4 items-center">
                {label}
                {isLoading ? <i className="fa-solid fa-spinner-third fa-spin"/> : iconName && <i className={`fa-solid fa-${iconName}`}/>}
            </div>
        </Btn>
    )
}



export default SignConfirmation;