import styles from "./errorPages.module.css";
import logo from "../../asset/images/404.png";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {scrollToTopOnMount} from "../../util/functionsUtil";

const PageNotFound=()=> {
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const {t: tC} = useTranslation("components");
    return <div className={styles.container}>
        <div className={styles.errorContainer}>
            <img src={logo} alt="" className={styles.logo}/>
            <div className="flex flex-col items-center gap-8">
                <span className={styles.errorTitle}>{tC("error_title")}</span>
                <span className={styles.errorDescription}>{tC("error_description")}</span>
            </div>

        </div>
    </div>
}

export default PageNotFound;