import HttpService from "../service/HttpService";
import {
    GET_ARTICLE_DATA_BY_CRITERIA,
    GET_ARTICLE_DATA_BY_ID, GET_ARTICLE_DATA_BY_MENU, GET_ARTICLE_EVIDENCE,
    GET_AUTO_COMPLETE_ACTIVITY,
    GET_AUTO_COMPLETE_ARTICLE, GET_CHECK_TOKEN_RESET_PASSWORD, GET_DYNAMIC_LIST,
    GET_FOLDER_DATA_BY_REFERENCE_PATH,
    GET_INQUIRIES_DATA,
    GET_RANKING_DATA,
    GET_RANKING_FILTER_CRITERIA,
    GET_RANKING_FILTER_MANNERS,
    GET_REF_ACTIVITIES_BY_CRITERIA,
    GET_REF_ACTIVITIES_COMMUNES, GET_REF_ACTIVITIES_DETAILS,
    GET_REF_ACTIVITIES_NATURES,
    GET_REF_ACTIVITIES_PROVINCES, GET_SIGNUP_CONFIRM, GET_SIGNUP_DEPARTEMENTS, GET_SIGNUP_PROCEDURES,
    GET_TAGS_DATA, POST_RESET_PASSWORD, POST_SEND_MAIL_RESET_PASSWORD, POST_SIGNUP
} from "./apiPath";
import {
    formatRankingMannersData,
    formatRankingData, formatDynamicList, formatSignUpProceduresData, formatArticleEvidenceData,
} from "./formatter";
import {DYNAMIC_LIST_PARAMS} from "../util/constants";


const axios = HttpService.getAxiosClient();

export const getFolderDataByReference= async (reference,lng) => {
    const { data } = await axios.get(
        `${GET_FOLDER_DATA_BY_REFERENCE_PATH.path}/?reference=${reference}&lng=${lng}`
    );
    return data;
};
export const getArticleDataByCriteria= async (noPage,pageSize,search,tags,menu,lng="fr") => {
    const {data} = await axios.get(
        `${GET_ARTICLE_DATA_BY_CRITERIA.path}/?noPage=${noPage}&pageSize=${pageSize}&search=${search}&tags=${tags}&menu=${menu}&lng=${lng}`
    );
    return data;
};
export const getTagsData= async (menu,lng="fr") => {
    const { data:{data} } = await axios.get(
        `${GET_TAGS_DATA.path}/?menu=${menu}&lng=${lng}`
    );
    return data
};
export const getAutoCompleteArticle= async (prefix="",menu,lng="fr") => {
    const { data} = await axios.get(
        `${GET_AUTO_COMPLETE_ARTICLE.path}/?prefix=${prefix}&menu=${menu}&lng=${lng}`
    );
    return data;
};
export const getActivityDataByCriteria= async (noPage,pageSize,search,type,nature,lng) => {
    const { data } = await axios.get(
        `${GET_REF_ACTIVITIES_BY_CRITERIA.path}/?noPage=${noPage}&pageSize=${pageSize}&search=${search}&type=${type}&natureCode=${nature}&lng=${lng}`
    );
    return data;
};
export const getKarazalResourceProvince= async (lng) => {
    const { data } = await axios.get(
        `${GET_REF_ACTIVITIES_PROVINCES.path}?lng=${lng}`,

    );
    return data;
};
export const getKarazalResourceCommune= async (province,lng) => {
    const { data } = await axios.get(
        `${GET_REF_ACTIVITIES_COMMUNES.path}?province=${province}&lng=${lng}`,
    );
    return data;
};

export const getActivityDetail= async (communeId="",codeActivity="",lng="fr") => {
    const { data } = await axios.get(
        `${GET_REF_ACTIVITIES_DETAILS.path}?communeId=${communeId}&codeActivity=${codeActivity}&lng=${lng}`,
    );
    return data;
};


export const getArticleDataById= async (articleId,lng) => {
    const { data} = await axios.get(
        `${GET_ARTICLE_DATA_BY_ID.path}/${articleId}?lng=${lng}`,
    );
    return data;
};
export const getArticleDataByMenu= async (menu,lng) => {
    const { data} = await axios.get(
        `${GET_ARTICLE_DATA_BY_MENU.path}/${menu}?lng=${lng}`,
    );
    return data;
};
export const getAutoCompleteActivity= async (prefix="",type="",lng="fr") => {
    const {data} = await axios.get(
        `${GET_AUTO_COMPLETE_ACTIVITY.path}/?prefix=${prefix}&type=${type}&lng=${lng}`
    );
    return data;
};
export const getKarazalResourceActivityNatures= async (type,lng) => {
    const { data } = await axios.get(
        `${GET_REF_ACTIVITIES_NATURES.path}/?type=${type}&lng=${lng}`,
    );
    return data;
};
export const getRankingFilterCriteria= async (criteria,lng) => {
    const { data:{data} } = await axios.get(
        `${GET_RANKING_FILTER_CRITERIA.path}/?criteria=${criteria}&lng=${lng}`
    );
    return data;
};

export const getRankingFilterManners= async (lng="fr") => {
    const { data } = await axios.get(
        `${GET_RANKING_FILTER_MANNERS.path}?lng=${lng}`
    );
    return formatRankingMannersData(data);
};
export const getRankingData=async (page, size, search, periode, region, nbRanking, localite,sortField,asc,lng)=>{
    const { data:{data} } = await axios.get(
        `${GET_RANKING_DATA.path}?noPage=${page}&pageSize=${size}&search=${search}&periode=${periode}&region=${region}&ranking=${nbRanking}&loacalite=${localite}&sortField=${sortField}&asc=${asc}&lng=${lng}`
    );
    return formatRankingData(data);
}
export const getInquiriesData=async (page, size,search,commune,reference,province,lng)=>{
    const { data } = await axios.get(
        `${GET_INQUIRIES_DATA.path}?noPage=${page}&pageSize=${size}&search=${search}&commune=${commune}&province=${province}&reference=${reference}&lng=${lng}`
    );
    return data;
}
export  const getDynamicList=async (listName)=>{
    const formData={
        apiKey:GET_DYNAMIC_LIST.apiKey,
        userId:GET_DYNAMIC_LIST.userId,
        lvName:listName,
    }
    const formEncodedData = new URLSearchParams(formData).toString();
    let { data } = await axios.post(
        GET_DYNAMIC_LIST.path,
        formEncodedData
    );
    return formatDynamicList(data);
}
export const getKarazalResourceDepartments= async (lng="",profile="",domaine="",search="") => {
    const { data } = await axios.get(
        `${GET_SIGNUP_DEPARTEMENTS.path}?lng=${lng}&profile=${profile}&domaine=${domaine}&search=${search}`,
    );
    return data;
};
export const getSignUpProcedures= async (lng="",profile="") => {
    const { data } = await axios.get(
        `${GET_SIGNUP_PROCEDURES.path}?lng=${lng}&profile=${profile}`,
    );
    return formatSignUpProceduresData(data);
};
export const sendSignUpData= async (data,lng) => {
    const {data:result}= await axios.post(
        `${POST_SIGNUP.path}?lng=${lng}`,
        data
    );
    return result;
};
export const getSignUpConfirmation= async (object) => {
    const {data:result}= await axios.get(
        `${GET_SIGNUP_CONFIRM.path}?object=${object}`,
    );
    return result;
};
export const sendEmailResetPassword= async (email) => {
    const {data} = await axios.post(
        `${POST_SEND_MAIL_RESET_PASSWORD.path}?email=${email}`,
    );
    return data;
}
export const sendResetPassword= async (token,password,repassword) => {
    const {data} = await axios.post(
        `${POST_RESET_PASSWORD.path}?token=${token}&password=${password}&repassword=${repassword}`,
    );
    return data;
}
export const getCheckTokenResetPassword= async (token) => {
    const {data} = await axios.get(
        `${GET_CHECK_TOKEN_RESET_PASSWORD.path}?token=${token}`,
    );
    return data;
}
export const getArticleEvidence=async (lng="fr")=>{
    const {data} = await axios.get(
        `${GET_ARTICLE_EVIDENCE.path}/?lng=${lng}`,
    );
    return data;
}