import * as React from 'react';
import './basicTextFields.css';
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import useHandleClassName from "../../hooks/useHandleClassName";

const BasicTextFields=(props)=> {
    const {t:tER}=useTranslation("formErrors");
    const {translate}=useHandleClassName();
    const inputClasses = classNames({
        'basic-input': true,
        'in-error': props.errorMessage ,
        'in-normal':!props.errorMessage,
    });
    const errorMessageStyle = classNames({
        'in-error-message': true,
        'opacity-100 ': props.errorMessage ,
        'opacity-0':!props.errorMessage,
    });
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (event.target.value.trim()!=="") props.onPressEnterKey()
        }
    };
  return (<div className={"text-field-container"}>
      <input onKeyPress={handleKeyPress} className={`${inputClasses} ${translate("regular")}`} placeholder={props.placeholder} name={props.name} {...props.register(props.name)} />
          <div id="data-display" className={translate("regular")} style={{color: '#374151', fontSize: '14px'}}>
              <p style={{
                  color: 'var(--gray-700, #374151)',
                  fontSize: '12px',
                  lineHeight: '150%',
              }}>
                  {props.example}</p>
              <p className={errorMessageStyle}>
                  {tER(props.errorMessage)}
              </p>
          </div>
      </div>
  );
}
export default  BasicTextFields;