import HeaderCell from './HeaderCell.jsx';
import PropTypes from 'prop-types';
import styles from "./thead.module.css"
const Thead = ({ header,translate }) => {
    return (
        <thead >
        {header.map((headerGroup, index_1) => (
            <tr key={index_1} {...headerGroup.getHeaderGroupProps()} >
                {headerGroup.headers.map(
                    (column, index_2) =>
                        column.show && <HeaderCell column={column} key={index_2} translate={translate} />
                )}
            </tr>
        ))}
        </thead>
    );
};
export default Thead;
Thead.propTypes = {
    header: PropTypes.array,
};