import GlobalLoader from "./global-loader/GlobalLoader";
import {useState, createContext, useEffect} from "react";
import {useTranslation} from "react-i18next";

export const AppContext=createContext({
    showLoader:()=>{},
    hideLoader:()=>{},
    isLoading:true,
})

const AppProvider=(props)=>{
    const {i18n} =useTranslation();
    const [loading,setLoading]=useState(false);
    useEffect(() => {
        document.body.dir=i18n.dir() || "rtl" ;
        document.body.lang= i18n.language || "ar";
    }, [i18n.language]);
    const funcs={
        showLoader:()=>setLoading(true),
        hideLoader:()=>setLoading(false),
        isLoading: loading,
    }
    return (
        <AppContext.Provider value={funcs}>
            {props.children}
            <GlobalLoader loading={loading} />
        </AppContext.Provider>
    )
}
export default AppProvider;