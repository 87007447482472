import {useTranslation} from "react-i18next";
import styles from "../../pages/sign/sign.module.css";
import React from "react";
import {redirect} from "../../util/functionsUtil";
import {LOGIN_URL} from "../../util/constants";

const LegalFooter = ({connectLink}) => {
    const {t:tS}=useTranslation("signup")
    const login=()=>{
        redirect( LOGIN_URL);
    }
    return (
        <div className="py-2">
            {connectLink && <div className={styles.singIn}>
                {tS("legal_footer_label1")}
                <a className={styles.signLink} onClick={login}>{tS("login_button")}</a>
            </div>}
            <div className={styles.legalFooter}>
                <p className={"px-4"}>{tS("legal_footer_label2")}</p>
                <div className={"flex justify-center gap-6 items-center"}>
                    {tS("legal_footer_label3")}
                </div>
            </div>
        </div>
    )
}
export default LegalFooter;