import React, {useEffect, useRef, useState} from "react";
import BasicTextFields from "../../pagescomponents/suivi-dossier/BasicTextFields";
import BasicButtons from "../../pagescomponents/suivi-dossier/BasicButtons";
import "./folderState.css";
import {TrackFolder} from "../../asset/images/svgImages";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Stepper from "../../components/stepper";
import Modal from "../../components/modal/Modal";
import {useGetFolderDataByReference} from "../../api/caller";
import {SUIVI_DOSSIER_SCHEMA} from "../../holders/schemas";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {useTranslation} from "react-i18next";
import useHandleClassName from "../../hooks/useHandleClassName";
import {useIsFetching} from "react-query";
import step from "../../components/stepper/Step";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {scrollToTopOnMount} from "../../util/functionsUtil";


const FolderState = () => {
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const [reference, setReference] = useState("");
    const [enabled, setEnabled] = useState(false);
    const {currLng}=useHandleTranslation();
    const getFolderDataByReference = useGetFolderDataByReference(reference, enabled,currLng);
    const [activeStep,setActiveStep]=useState(null);
    const [open, setOpen] = useState(false);
    const [refTitle,setRefTitle]=useState('');
    const [isRefExist,setRefStatus]=useState(false);
    const {t:tED}=useTranslation("suiviDossier");
    const {t:tBC}=useTranslation("breadcrumb");
    const {translate}=useHandleClassName();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(SUIVI_DOSSIER_SCHEMA),
    });
    useEffect(() => {
        if(getFolderDataByReference.isSuccess && getFolderDataByReference.isFetched && getFolderDataByReference.data?.body?.page) {
            setRefStatus(true);
            if(getFolderDataByReference.data?.body?.page?.length===0) {
                setOpen(true);
                setRefStatus(false);
            }
            setRefTitle(reference);
             getFolderDataByReference.data?.body?.page?.map((item, index) => {
                 if(item.status==="active") setActiveStep(index);
            })
            setActiveStep(null);
            for(let i=0;i<getFolderDataByReference.data?.body?.page?.length;i++){
                if (getFolderDataByReference.data?.body?.page[i].status==="active") {
                  setActiveStep(i);
                  break;
                }
            }
        }
    }, [getFolderDataByReference.isLoading,getFolderDataByReference.isFetching]);
    useEffect(() => {
        if (!getFolderDataByReference.isFetching && getFolderDataByReference.isFetched && (getFolderDataByReference.isError || !getFolderDataByReference.data?.body?.page)){
            setOpen(true)
            setRefStatus(false);
        }
    }, [getFolderDataByReference.isError,  getFolderDataByReference.isFetching]);


    const updateReference = async (form) => {
        if(form.reference===reference) await getFolderDataByReference.refetch();
        setReference(form?.reference ||"");
        if (!enabled) setEnabled(true);
    }
        const handleClose = () => {
            setOpen(false);
        };

        const breadcrumbParts = [
            {text: tBC("Accueil"), link: '/'},
            {text: tBC("Suivre_dossier"), link: ''}
        ];
        return (
            < div className={"max-content"}>
                <div className="breadcrumb">
                    <BreadCrumb parts={breadcrumbParts}/>
                </div>
                <div className="flex-center"  style={{margin: "5% 0 0"}}>
                    <div style={{justifyContent:"center"}} className="flex-item-search-bar" >
                    <div style={{maxWidth:"550px",flexGrow:1}} className="flex-item">
                        <div>
                            <p className={`${translate("bold")} ed-request-title`}>{tED("ed_request_title")}</p>
                            <p className={translate("regular")} style={{color: '#374151', fontSize: '15px',lineHeight: 1.5,maxWidth:"400px"}}>{tED("ed_request_message1")}</p>
                        </div>
                        <div className="ed-request-form-container">
                            <BasicTextFields  register={register} errorMessage={errors?.reference?.message} onPressEnterKey={handleSubmit(updateReference)}
                                             isRequired={false} example={`${tED("ed_request_form_example")} : PCT-PSHM-ASB-1234/2022`} name={"reference"} placeholder={tED("ed_request_form_placeholder")}/>
                            <BasicButtons title={`${tED("ed_request_form_button")}`} loading={getFolderDataByReference.isFetching} onClick={handleSubmit(updateReference)}/>
                        </div>
                    </div>
                    <div className="flex-item hidden-mobile" style={{
                        height: 'fit-content',
                    }}>
                        <TrackFolder/>
                    </div>
                    </div>
                </div>
                {isRefExist && getFolderDataByReference.isFetched && getFolderDataByReference.data?.body?.page && !getFolderDataByReference.isFetching && (
                    <>
                        <div className="flex-column ed-container  mb-24">
                            <div className="ed-res-title">
                                <p style={{textAlign: "center"}}>
                                    <span className="ed-res-txt break-line-mobile">{tED("ed_response_title")} : &nbsp;</span>
                                    <span className="ed-reference">{refTitle}</span>{" "}
                                </p>
                            </div>
                            <Stepper steps={getFolderDataByReference.data?.body?.page} maxSteps={"7"} activeStep={activeStep} className={"stepper-web"}/>
                            <Stepper steps={getFolderDataByReference.data?.body?.page} maxSteps={"3"} activeStep={activeStep} className={"stepper-mobile"}/>
                        </div>
                    </>
                )}
               <Modal isOpen={open}>
                    <>
                        <div className={`ed-modal-title ${translate("bold")}`}>{tED("modal_title")}</div>
                        <div className={`ed-modal-message ${translate("regular")}`}>{tED("modal_description")}</div>
                        <button className={`ed-modal-close-btn ${translate("regular")}`} onClick={handleClose}>{tED("modal_button")}</button>
                    </>
                </Modal>
            </div>
        );
    };

export default FolderState;