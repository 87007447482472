import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useGetArticleDataById } from '../../api/caller';
import { useTranslation } from 'react-i18next';
import {formatDate, scrollToTopOnMount} from '../../util/functionsUtil';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import useHandleTranslation from "../../hooks/useHandleTranslation";
import TagCard from "../../components/cards/tagcard/TagCard";
import ArticleSkeleton from "../../pagescomponents/articlecard/ArticleSkeleton";
import  styles from "./css/article_read.module.css"
import globalStyles from "../globalstyles.module.css";


const ArticleReadPage = (props) => {
    const { menu, id } = useParams();
    const {t:tBC}=useTranslation("breadcrumb");
    const {currLng,getDirX, } = useHandleTranslation()
    const navigate=useNavigate();
    const [breadcrumbParts,]=useState([
        {text: tBC("Accueil"), link: '/'},
        {text: tBC("Articles"), link: '/'},
        {text: tBC(menu), link: `/articles/${menu}`},
        {text: tBC("ReadArticle"), link: ''}
    ])
    const getArticleData = useGetArticleDataById(id,currLng);
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    return (
        <>
            <div className="breadcrumb">
                <BreadCrumb parts={breadcrumbParts}/>
            </div>
            <div className={styles.pagetitleContainerRead}>
                <div className={`${globalStyles.pageTitleContainer} ${globalStyles.responsiveWeb}`}>
                    <div className={styles.articleMenu}>{tBC(menu)}</div>
                </div>

                <div className={`${globalStyles.pageTitleContainerMobile} ${globalStyles.responsiveMobile} ${styles.titleMobileResponsive}`}>
                    <div className={"flex gap-5 items-center"}>
                          <i onClick={() => navigate(`/articles/${menu}`)} className={`cursor-pointer fa-solid fa-${getDirX()}
                          text-2xl`}></i>
                         <div className={styles.articleMenu}>{tBC(menu)}</div>
                    </div>
                </div>

            </div>

            {getArticleData.isSuccess && <ReadArticle menu={menu} data={getArticleData.data.page[0]}/>}
            {getArticleData.isLoading && getArticleData.isFetching && <ArticleSkeleton/>}
        </>
    );
};

const ReadArticle = (props) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const {t: tBC} = useTranslation("breadcrumb")
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className={styles.articleContent}>
            <div className="flex justify-center">
                <img src={`/front/${props.data.imageLink}`} height="500" width="100%" className="rounded-lg"/>
            </div>

            <div className="read-art-web">
                <div className={styles.contentHeader} >
                    <p className={styles.contentHeaderText}>📑{tBC(props.menu)} - {formatDate(props.data?.date)} </p>
                     <div  className="flex items-center gap-2">
                         {props.data?.tags.map((tag,index)=> <TagCard key={index} text={tag.tag} color={"#0369A1"} backgroud={"#E0F2FE"} size="12px" margin={"5px 2px"}/>) }
                     </div>
                </div>
                <div className={styles.articleTitle}>
                    {props.data?.title}
                    <div className={styles.articleTitleLine}></div>
                </div>
            </div>
            <div className={styles.contentStyle}>
                {screenWidth < 768 ? (
                   <HTMLContent content={props.data.contentMob}/>
                ) : (
                    <HTMLContent content={props.data.content}/>
                )}

            </div>
        </div>

    );
};
const HTMLContent = ({content}) => (<div className='content' dangerouslySetInnerHTML={{__html: content}} style={{width: "100%", overflow: "auto"}}/>)

export default ArticleReadPage;