import useHandleTranslation from "./useHandleTranslation";

export const useFormatData=()=>{
    const {isLngAr,isLngFr}=useHandleTranslation();

    const simpleFormat=(data,inputs,outputs)=>{
     return data.map((item)=>{
         return Object.assign({}, ...outputs.map((out,index)=>({[out]:item[inputs[index]]})));
      })
  }
    const formatFilterRankingPeriodeData=(item)=>{
      if (!item) return "";
        const content=(pattern)=>{
            switch (pattern){
                case "1":
                    if (isLngFr) return "er trimestre";
                    if (isLngAr) return "الربع الأول من عام ";
                    break;
                case "2":
                    if (isLngFr) return "éme trimestre ";
                    if (isLngAr) return "الربع الثاني من عام ";
                    break;
                case "3":;
                    if (isLngFr) return "éme trimestre ";
                    if (isLngAr) return "الربع الثالث من عام "
                    break;
                case "4":;
                    if (isLngFr) return "éme trimestre ";
                    if (isLngAr) return "الربع الرابع من عام "
                    break;
            }
            return "";
        }
        const prefix=item.split("-")[0];
        const suffix=item.split("-")[1];
        if(isLngAr) return  content(prefix)+suffix
        return prefix+content(prefix)+suffix
    }
  return {simpleFormat,formatFilterRankingPeriodeData};
 }