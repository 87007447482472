import {Skeleton} from "antd";
import React from "react";

const ArticleSkeleton = () => {
    return (
        <div className="p-16">
            <div className="w-fit mx-auto">
                <Skeleton.Input active={true} size={35}/>
            </div>
            <div className="flex gap-4 py-16">
                <div className="w-2/3 mx-auto">
                    <Skeleton.Image active={true} style={{width:"100%",height:"300px", borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px"}}/>
                </div>
                <Skeleton paragraph={{ rows: 8}} active={true} />
            </div>
            <Skeleton paragraph={{ rows: 10}} active={true} />
        </div>
    )
}
export default ArticleSkeleton;