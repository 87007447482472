import BackEnd from './backend/BackEnd';
import FrontEnd from './frontend/FrontEnd';
import {Routes, Route} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js';
import {QueryClient, QueryClientProvider} from "react-query";
import {ConfigProvider} from "antd";
import AppProvider from "./pagescomponents/AppProvider";
import PdfViewer from "./frontend/PdfViewer";
import SignUp from "./pages/sign/SignUp";
import React, {useEffect} from "react";
import PasswordForget from "./pages/sign/PasswordForget";
import {LOGIN_URL} from "./util/constants";
import {redirect} from "./util/functionsUtil";
import PasswordChange from "./pages/sign/PasswordChange";


const App=()=> {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                retry: false,
                staleTime: 5 * 60 * 1000,
            },
        },
    });
    return(
        <ConfigProvider
            direction={i18n.dir()}
            theme={{
                token: {
                    fontFamily:"",
                    colorPrimary:"#0369A1",
                    colorPrimaryActive:"#0369A1"
                },
                components: {
                    Tabs: {
                        inkBarColor:"#0369A1",
                        itemActiveColor:"#0369A1",
                        itemColor:"#9CA3AF",
                        itemHoverColor:"#0369A1",
                        itemSelectedColor:"#0369A1",
            },
                    Input: {
                        activeShadow:"0"
                    },
                    Progress:{
                        defaultColor:"#0369A1"
                    },
                },
            }}
        >
        <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
         <AppProvider>
        <Routes>
            <Route path="/" element={<FrontEnd  />}/>
            {/*<Route path="/BackEnd" element={<BackEnd/>}/>*/}
            <Route path="/RefActivities" element={<FrontEnd refActivities={true}/>}/>
            <Route path="/articles/:menu" element={<FrontEnd article={true} />} />
            <Route path="/articles/:menu/:id" element={<FrontEnd articleId={true}/>} />
            <Route path="/enquete" element={<FrontEnd inquiry={true}/>} />
            <Route path="/completesignup" element={<FrontEnd completesignup={true}/>}/>
            <Route path="/EmailVerification" element={<FrontEnd EmailVerification={true}/>}/>
            <Route path="/signin" element={<FrontEnd signin={true}/>}/>
            <Route path="/suivi-dossier" element={<FrontEnd suivDoss={true}/>}/>
            <Route path="/RefActivities/:type" element={<FrontEnd refList={true}/>}/>
            <Route path="/classement-national" element={<FrontEnd nationalRanking={true}/>}/>
            <Route path="/classement-national/methodology-detail" element={<FrontEnd methodologyDetail={true}/>}/>
            <Route path="/viewPdf/:fileId" element={<PdfViewer/>}/>
            <Route path="/signup" element={<SignUp/>}/>
            <Route path="/signup-confirmation/:dataObjectId" element={<FrontEnd signupConfirmation={true}/>}/>
            <Route path="/sign/forget-password"  element={<PasswordForget/>}/>
            <Route path="/sign/change-password/:token"  element={<PasswordChange/>}/>
            <Route
                path="*"
                element={<FrontEnd errorPage={true}/>}
            />
        </Routes>
         </AppProvider>
        </I18nextProvider>
        </QueryClientProvider>
        </ConfigProvider>
    );
}

const Login=()=>{
    useEffect(() => {
        redirect( LOGIN_URL);
    }, []);
    return <></>
}
export default App;

