import styles from "./input.module.css"
import globalStyles from "../../globalStyles.module.css"
import useHandleTranslation from "../../hooks/useHandleTranslation";
import classNames from "classnames";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ConfigProvider, Select} from "antd";
import {COUNTRIES_CODES} from "../../util/constants";


const InputPhoneForm = ({
                            dir,
                            important,
                            label,
                            placeholder,
                            name,
                            register,
                            errors,
                            setter=()=>{},
                            password,
                            suffix,
                            cannotPaste,
                            inputDir,
                            rootClassName,
                            disable
                        }) => {
    const [isHiddenPassword, setHiddenPasswordStatus] = useState(true);
    const {t: tER} = useTranslation("formErrors");
    const {translateClassName} = useHandleTranslation()
    const inputFormStyles = classNames({
        [styles.inputPhoneFormItemContainer]: true,
        "!border-red-600": !!errors?.[name]?.message
    })
    const inputFormLabelStyles = classNames({
        [styles.inputFormLabel]: true,
        "!text-red-600": !!errors?.[name]?.message
    })
    const handlePaste = (e) => {
        if (cannotPaste) e.preventDefault();
    }
    useEffect(() => {
        setter("indicatif","+212");
    }, []);
    const handleIndicatifChange=(value)=>{setter("indicatif",value);}
    const phoneOptions=COUNTRIES_CODES
        .map((country) => (
            {value: country.dial_code, label: country.dial_code }))
    return (
        <div className={`${styles.inputFormContainer} ${rootClassName}`} dir={dir}>
            <div className={inputFormLabelStyles}>
                {label}
                {important && <span className="text-red-500"> *</span>}
            </div>
            <div className={inputFormStyles} dir={inputDir}>
                <ConfigProvider
                    theme={{
                        token:{
                            colorText:"#374151",
                        },
                        components: {
                            Select: {
                                optionSelectedColor: "#374151",
                                optionSelectedBg: "#E1F5FE"
                            },
                        },
                    }}
                    direction="ltr"
                >
                    <Select
                        style={{width: 120}}
                        className="flex items-center"
                        defaultValue={"+212"}
                        onChange={handleIndicatifChange}
                        options={phoneOptions}
                        showSearch
                    />
                </ConfigProvider>
                <input className={`${styles.inputPhoneFormItem} ${globalStyles[translateClassName("regular")]}`}
                       placeholder={placeholder}
                       type={password && isHiddenPassword ? "password" : "text"}
                       {...register(name)}
                       onPaste={handlePaste}
                       dir={inputDir}
                       disabled={disable}
                />
                {suffix && <div className={styles.inputSuffixItem}>
                    {suffix}
                </div>}
                {password && <div className="flex flex-col justify-center">
                    {isHiddenPassword ?
                        <EyeInvisibleOutlined onClick={() => setHiddenPasswordStatus(false)}/>
                        : <EyeOutlined onClick={() => setHiddenPasswordStatus(true)}/>
                    }
                </div>}
            </div>
            <div className={styles.errorMessage}>{tER(errors?.[name]?.message)}</div>
        </div>
    )
}
export default InputPhoneForm;