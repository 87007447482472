import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import globalStyles from "../globalstyles.module.css";
import styles from "./nationalranking.module.css";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import delai_1 from "../../asset/images/national-ranking/expressions/delai_1.png";
import delai_2 from "../../asset/images/national-ranking/expressions/delai_2.png";
import alpha from "../../asset/images/national-ranking/expressions/alpha.png";
import delai_3 from "../../asset/images/national-ranking/expressions/delai_3.png";
import attractivite_1 from "../../asset/images/national-ranking/expressions/attractivite_1.png";
import ecosystem_1  from "../../asset/images/national-ranking/expressions/ecosystem_1.png";
import digitalisation_1 from "../../asset/images/national-ranking/expressions/digitalisation_1.png";
import fiscalite_1 from "../../asset/images/national-ranking/expressions/fiscalite_1.png";
import fiscalite_2 from "../../asset/images/national-ranking/expressions/fiscalite_2.png";
import fiscalite_3 from "../../asset/images/national-ranking/expressions/fiscalite_3.png";
import score_1 from "../../asset/images/national-ranking/expressions/score_1.png";
import {scrollToTopOnMount} from "../../util/functionsUtil";

const MethodologyDetail=()=>{
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const {t:tBC}=useTranslation("breadcrumb");
    const {t:tNR}=useTranslation("nationalRanking");
    const breadcrumbParts = [
         {text: tBC("Accueil"), link: '/'},
         {text: tBC("J’explore les indicateurs"), link: '/'},
         {text: tBC("Classement national"), link: '/classement-national'},
         {text: tBC("Index Rokhas pour la performance territoriale"), link: '/'}
     ];
    return(
        <>
            <div className="breadcrumb">
                <BreadCrumb parts={breadcrumbParts}/>
            </div>
            <div className={globalStyles.pageTitleContainer + " responsive-web"}>
                <div className={globalStyles.pageTitle}>{tNR("method_title")}</div>
                <div className={globalStyles.pageTitleDescription}>
                    {tNR("method_description")}
                </div>
            </div>
            <div className={globalStyles.pageTitleContainerMobile + " responsive-mobile"}>
                <div className={globalStyles.pageTitleMobile}>{tNR("method_title")}</div>
            </div>
            <div className={styles.articleContentContainer}>
                <div className={styles.articleTitle}>📑 {tNR("method_title_2")}</div>
                <div className={styles.readLine}/>
                <div className={styles.articleBasePolice}>
                    <p>
                        {tNR("method_paragh_1")}
                    </p>
                    <p>
                        {tNR("method_paragh_2")}
                    </p>
                    <p>
                        {tNR("method_paragh_3")}
                    </p>
                    <p>
                        {tNR("method_paragh_4")}
                    </p>
                </div>
                <div>
                    <p className={styles.articleStrongTitle}>{tNR("method_content_strong_title_1")}</p>
                    <ul className={styles.articleBasePolice}>
                        <li>{tNR("method_content_list_1_item_1")}</li>
                        <li>{tNR("method_content_list_1_item_2")}</li>
                        <li>{tNR("method_content_list_1_item_3")}</li>
                        <li>{tNR("method_content_list_1_item_4")}</li>
                        <li>{tNR("method_content_list_1_item_5")}</li>
                    </ul>
                </div>
                <div>
                    <p className={styles.articleWarningTitle}>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        {tNR("method_content_strong_title_2")}
                    </p>
                    <ul className={styles.articleBasePolice}>
                        <li>{tNR("method_content_list_2_item_1")}</li>
                        <li>{tNR("method_content_list_2_item_2")}</li>
                        <li>{tNR("method_content_list_2_item_3")}</li>
                    </ul>
                </div>
                <div>
                <p className={styles.articleStrongTitle}>{tNR("method_content_strong_title_3")}</p>
                    <p className={styles.articleBasePolice}>
                        {tNR("method_paragh_5")}
                    </p>
                </div>
                <div className="pb-12 pt-3">
                <ItemDropDown title={tNR("method_dropdown_1_title")}>
                    <div className={styles.articleBasePolice}>
                        <p >{tNR("method_dropdown_1_paragh_1")}</p>
                        <img className="ml-12 py-4" alt="" src={delai_1}/>
                        <p>{tNR("method_et")}</p>
                        <img className="ml-12 py-4" alt="" src={alpha}/>
                        <p>{tNR("method_avec")}</p>
                        <img className="ml-12 py-4" alt="" src={delai_2}/>
                        <p>
                            {tNR("method_dropdown_1_paragh_2")}
                        </p>
                        <p>
                            {tNR("method_dropdown_1_paragh_3")}
                        </p>
                        <p>
                            {tNR("method_dropdown_1_paragh_4")}
                        </p>
                        <img className="ml-12 py-4" alt={""} src={delai_3}/>
                    </div>
                </ItemDropDown>
                <ItemDropDown title={tNR("method_dropdown_2_title")}>
                    <div className={styles.articleBasePolice}>
                        <p>{tNR("method_dropdown_2_paragh_1")}</p>
                        <img className="ml-12 py-4" src={attractivite_1} alt=""/>
                        <p>{tNR("method_avec")}</p>
                        <img className="ml-12 py-4" src={alpha} alt=""/>
                        <p>{tNR("method_dropdown_2_paragh_2")}</p>
                        <p>{tNR("method_dropdown_2_paragh_3")}</p>
                    </div>
                </ItemDropDown>
                <ItemDropDown title={tNR("method_dropdown_3_title")}>
                    <div className={styles.articleBasePolice}>
                        <p>{tNR("method_dropdown_3_paragh_1")}</p>
                        <img className="ml-12 py-4" src={ecosystem_1} alt=""/>
                        <p>{tNR("method_avec")}</p>
                        <img className="ml-12 py-4" src={alpha} alt=""/>
                        <p>{tNR("method_dropdown_3_paragh_2")}</p>
                        <p>{tNR("method_dropdown_3_paragh_3")}</p>
                    </div>
                </ItemDropDown>
                <ItemDropDown title={tNR("method_dropdown_4_title")}>
                    <div className={styles.articleBasePolice}>
                        <p>{tNR("method_dropdown_4_paragh_1")}</p>
                        <img className="ml-12 py-4" src={digitalisation_1} alt=""/>
                        <p>{tNR("method_avec")}</p>
                        <img  className="ml-12 py-4" src={alpha} alt=""/>
                        <p>{tNR("method_dropdown_4_paragh_2")}</p>
                        <p>{tNR("method_dropdown_4_paragh_3")}</p>
                    </div>
                </ItemDropDown>
                <ItemDropDown title={tNR("method_dropdown_5_title")}>
                    <div className={styles.articleBasePolice}>
                        <p>{tNR("method_dropdown_5_paragh_1")}</p>
                        <img className="ml-12 py-4" src={fiscalite_1} alt=""/>
                        <p>{tNR("method_avec")}</p>
                        <img className="ml-12 py-4" src={fiscalite_2} alt=""/>
                        <p>{tNR("method_tellque")}</p>
                        <p>{tNR("method_dropdown_5_paragh_2")}</p>
                        <p>{tNR("method_dropdown_5_paragh_3")}</p>
                        <p>{tNR("method_dropdown_5_paragh_4")}</p>
                        <p>{tNR("method_dropdown_5_paragh_5")}</p>
                        <p className={styles.articleStrongTitle}>{tNR("method_dropdown_5_strong_title")}</p>
                        <p>
                            {tNR("method_dropdown_5_paragh_4")}
                        </p>
                        <img className="ml-12 py-4" src={fiscalite_3} alt=""/>

                    </div>
                </ItemDropDown>
                <ItemDropDown title={tNR("method_dropdown_6_title")}>
                    <div className={styles.articleBasePolice}>
                        <p>{tNR("method_dropdown_6_paragh_1")}</p>
                        <img className="ml-12 py-4" src={digitalisation_1} alt=""/>
                        <p>{tNR("method_avec")}</p>
                        <img className="ml-12 py-4" src={score_1} alt=""/>
                        <p>{tNR("method_dropdown_6_paragh_2")}</p>
                        <p>{tNR("method_dropdown_6_paragh_3")}</p>
                        <p>{tNR("method_dropdown_6_paragh_4")}</p>
                        <p>{tNR("method_dropdown_6_paragh_5")}</p>
                        <p>{tNR("method_dropdown_6_paragh_6")}</p>
                    </div>
                </ItemDropDown>
                </div>
            </div>
        </>
    )
}


const ItemDropDown=(props)=>{
    const [isOpen,setOpenStatus]=useState(false);
    const onCaretClick=()=>{
        setOpenStatus(!isOpen);
    }
    const descriptionStyles=classNames({
        [styles.articleDropDownDescription]:true,
        [styles.appearArticleDropDownDesription]:isOpen,
        [styles.notShow]:!isOpen
    })
    const caretStyles=classNames({
        "fa-solid fa-caret-down hover:cursor-pointer flex items-center duration-300 ease-in-out ":true,
        "fa-rotate-180":isOpen,
    })
    return (
        <div>
        <div className={styles.articleDropDownTitle} onClick={onCaretClick}>
            <i className={caretStyles} ></i>
            <span className="hover:cursor-pointer">{props.title}</span>
        </div>
        <div className={descriptionStyles}>
            {props.children}
        </div>
    </div>
    )
}
export default MethodologyDetail;