import styles from './signupcard.module.css'
const ProcessCard=({title,description,onDelete})=>{
return (
    <div className={styles.processCardContainer}>
        <div className={styles.processCardTitleContainer}>
            <div className="inline-grid">
            <span className={styles.elipsisText}>{title}</span>
            </div>
            <i className="fa-light fa-trash-can !text-red-400 hover:!text-red-600 cursor-pointer" onClick={onDelete}/>
        </div>
        <span className={styles.processCardDescription}>{description.join(" , ")}</span>
    </div>
)
}
export default ProcessCard;