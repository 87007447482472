import React, {createContext, useContext, useEffect, useRef, useState} from 'react' ;
import TitleParagraph from '../home/TitleParagraph';
import TitleParagraphButtons from '../home/TitleParagraphButtons';
import StepsProfil from './StepsProfil';
import Consult from './Consult';
import ButtonRadio from '../../components/button/ButtonRadio';
import MenuComponent from '../home/MenuComponent';
import Icon from '../../components/util/Icon';
import {CheckOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import classNames from "classnames";

const ProfilContext=createContext({
    activeProfilCheck: null ,
    setActiveProfilCheck:()=>{}
})
 const Profils=(props)=>  {
    const [activedProfilMini,setActivedProfilMini]=useState("");
     const menuItems = props.data?.profilsItems.map(
         (profil , index) => {
             return {
                 "categorie" : <ProfilMiniItem index={index} activedProfilMini={activedProfilMini} data={profil} asAIcan={props.data?.asAIcan}/> ,
                 "items" : <ProfilMiniDescription data={profil}/>
             }
         }
     )

    useEffect(() => {
        [...document.querySelectorAll( ".landing-page-profils-mini-container .menu-categorie-globalcomponent" )].forEach( profile => profile.classList.remove( "selected" ) )

        if(activedProfilMini.length!="" )
            document.querySelector( ".landing-page-profils-mini-container .menu-categorie-globalcomponent:nth-child("+(activedProfilMini+1)+")" ).classList.add( "selected" ) ;
    }, [activedProfilMini]);

            return (
                <>
                 {props.data &&
                <div className={'landing-page-functionalities '} >
                    {
                        props.className == "profils-large" ?
                        <div className='landing-page-profils-large-container'>
                            <TitleParagraph title={props.data.title} paragraph={props.data.paragraph}/>
                            <ProfilsLarge data={props.data}/>
                            {
                                (props.data.profilsItems[activedProfilMini]?.steps) &&
                                <StepsProfil data={props.data.profilsItems[activedProfilMini].steps}/>
                            }
                        </div>
                        :
                        <div className='landing-page-profils-mini-container'>
                            <div className='landing-page-profils-mini-container-top'>
                                <TitleParagraph title={props.data.title} paragraph={props.data.paragraph} paragraphColor="#fff" titleColor="#fff"/>
                                <MenuComponent menuItems={menuItems} selected={activedProfilMini} handleCategorieChecked={( actived )=>{ setActivedProfilMini(actived) }} />
                            </div>
                            {
                                (activedProfilMini !== "" &&
                                props.data.profilsItems[activedProfilMini]?.steps) &&
                                <StepsProfil data={props.data.profilsItems[activedProfilMini].steps}/>
                            }
                            {
                                (activedProfilMini !== "" &&
                                props.data.profilsItems[activedProfilMini]?.consult) &&
                                <Consult data={props.data.profilsItems[activedProfilMini].consult}/>
                            }
                        </div>
                    }

                </div>}
                </>
            )
}

const Profil=( props )=> {
    let state = props.active ;
    const {currLng}=useHandleTranslation();
    return (
        <div className='landing-page-functionality'>
            <button className={'landing-page-functionality-' + (props.active?'':'none-') + 'selected-button'} onClick={ () => {  state=!state ; props.handleClick( state ) } } >
                <span className='landing-page-functionality-img-container'><Icon className='landing-page-functionality-img' iconName={props.data.avatarProfil}/></span>
                <span className={`bold-${currLng}`}>{props.data.profilTitle}</span>
            </button>
            { props.active && <Icon iconType='sharp fa-solid' iconName='triangle' />}
        </div>
    );
}

const ProfilDescription=( props )=> {
    const {i18n}=useTranslation();
    let profilDescriptionLength = props.profil?.profilDescriptionList.length ;
    const {activeProfilCheck,setActiveProfilCheck}=useContext(ProfilContext);

    const onRadioCheck=(checkValue)=>{
        checkValue?setActiveProfilCheck(props.index):setActiveProfilCheck(null);
    };

   const functionalityMessageStyle= i18n.language==="ar"? {right:"20px"} :{left:"20px"};
    return (
        <>
            {props.profil &&
            <div className={`landing-page-functionality-description-bottom ${props.className}`}>
                <div className='landing-page-functionality-description'>
                    <div>
                        <div className='landing-page-functionality-message'>
                            <span style={functionalityMessageStyle} className={"functionality-message"}> {props.asAIcan?.[0]} {props.profil.profilTitle}{props.asAIcan?.[1]} </span>
                            <Icon iconName={"asAXICanDo_system"} iconClassName={i18n.language==="ar"? "rotate-y-180":""}/>
                        </div>
                        <div></div>
                    </div>

                    <div>
                        <div className='landing-page-functionality-description-left-side'>
                            <ul className={"check-ul"}>
                                {   
                                    props.profil.profilDescriptionList.slice( 0 , Math.ceil(profilDescriptionLength/2) ).map( (profilDesc,index) => 
                                        <React.Fragment key={index}><li className={"landing-page-functionality-description-item"}><div><CheckOutlined/></div><div>{profilDesc}</div></li></React.Fragment>
                                    )    
                                }    
                            </ul>
                        </div>
                        <div className='landing-page-functionality-description-center-side'>
                            <Icon iconName={props.profil.avatarProfilDescription} />
                        </div>
                        <div className='landing-page-functionality-description-right-side'> 
                            <ul className={"check-ul"}>
                                {
                                    props.profil.profilDescriptionList.slice( Math.ceil(profilDescriptionLength/2) , profilDescriptionLength ).map( (profilDesc,index) =>
                                        <React.Fragment key={index}><li className={"landing-page-functionality-description-item"}><div><CheckOutlined/></div><div>{profilDesc}</div></li></React.Fragment>
                                    )
                                }
                                <li ref={props.checkRef} style={{ "listStyleType":"none", "margin": "15px 0" }} ><ButtonRadio label={props.remainProfilType} isChecked={activeProfilCheck==props.index} onCheck={onRadioCheck} /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <TitleParagraphButtons data={props.profil} />
                <div style={{margin:"5% auto",width:"70%"}}> <Icon iconName={props.profil.vector} iconClassName={"img-fixed"} /></div>
            </div>}
            </>
    )
}

const ProfilMiniItem=( props )=>{
    return <div className='landingpage-profil-mini-title'>
            <div className='landing-page-profil-mini-first-part'>
                <div className='landing-page-profil-mini-img'>
                    <Icon iconName={props.data.avatarProfil} />
                </div>
                <div className='landing-page-profil-mini-as-ican'>
                    <div style={{"fontSize":"16px","fontWeight":"600", "letterSpacing":"1px"}}>{  props.asAIcan[0] + props.data.profilTitle } </div>{ props.activedProfilMini === props.index && <div style={{"fontSize":"13px","fontWeight":"lighter"}}> {  props.asAIcan[1]  } </div>}
                </div>
            </div>
            <div className='landing-page-profil-mini-icon'>
                <Icon iconType='light' iconName="circle-chevron-down"/>
            </div>
    </div> 
}

const ProfilMiniDescription=( props )=>{
    return <ul className='landingpage-profil-mini-description'>
        {
            props.data.profilDescriptionList.map( 
                (li, index) => <React.Fragment key={index}><li className={"landing-page-functionality-description-item"}><div><CheckOutlined/></div><div>{li}</div></li></React.Fragment>
            )
        }
    </ul>
}

const ProfilsLarge=(props)=> {
    const [activedProfil , setActivedProfil] = useState(localStorage.getItem("checked-profil"));
    const [activedProfilCheck , setActivedProfilCheck] = useState(localStorage.getItem("checked-profil"));
    const activeProfilRef=useRef(null);
    const profilStepsRef=useRef([]);

    const scrollToComponent = (index) => {
        const element = profilStepsRef.current[index];
        const elementToScroll=activeProfilRef.current;
        const rect = element.getBoundingClientRect();
        if (rect.top < 0 || rect.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
            elementToScroll.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
     if([0,1,2,3].includes(Number(activedProfilCheck))) localStorage.setItem("checked-profil",activedProfilCheck);
     else{
        localStorage.removeItem("checked-profil");
     }
    }, [activedProfilCheck]);

    useEffect(() => {
        if ([0,1,2,3,4].includes(activedProfil)) scrollToComponent(activedProfil);
    }, [activedProfil]);
    const profilesStyle=(index)=>classNames({
        ["hidden"]:index!= Number(activedProfil),
    })
    return (
        <div>
                    <div className='landing-page-functionalities-items' ref={activeProfilRef}>
                        {
                            props.data.profilsItems.map(
                                (profil, index) => <Profil
                                                            key={index} index={index}
                                                            data={ profil }
                                                            active={ activedProfil!=="" ?parseInt(activedProfil) == index:false } 
                                                            handleClick={( state )=>{ setActivedProfil( state?index:"-1" ) }} />
                            )
                        }                        
                    </div>
                      <ProfilContext.Provider value={{activeProfilCheck:activedProfilCheck,setActiveProfilCheck:setActivedProfilCheck}}>
                        {props.data.profilsItems.map((item,index)=><ProfilDescription
                            key={index}
                            checkRef={(el) => (profilStepsRef.current[index] = el)}
                            asAIcan={props.data.asAIcan}
                            profil={item}
                            className={profilesStyle(index)}
                            remainProfilType={props.data.remainProfilType}
                            index={index}/>)
                        }
                      </ProfilContext.Provider>

                    {
                       props?.data?.profilsItems?.map((item,index)=>
                                <StepsProfil key={index} className={profilesStyle(index)} divRef={profilStepsRef} data={item?.steps}/>
                        )
                    }
                    
                    {
                        props?.data?.profilsItems?.map((item,index)=>
                            <Consult key={index} className={profilesStyle(index)} data={item?.consult}/>)
                    }
        </div>
    )
}
export default Profils;