import React, {Component, useEffect} from 'react'
import Btn from '../../components/button/Btn'
import TitleParagraph from './TitleParagraph'
import useHandleClassName from "../../hooks/useHandleClassName";
import {useNavigate} from "react-router-dom";
import {customNavigate, redirect, scrollToTopOnMount} from "../../util/functionsUtil";
import useRokhasNavigate from "../../hooks/useRokhasNavigate";

const TitleParagraphButtons =(props)=> {
  useEffect(() => {
    scrollToTopOnMount();
  }, []);
  const {translate}=useHandleClassName();
  const {navigateTo}=useRokhasNavigate();
  const buttonColors=["#0369A1","#fff"];
  const buttonClassNameColors=["blue","white"];
    return(
        <>
          { props.data &&
      <div className='landing-page-first-paragraph'>
        <TitleParagraph title={props.data.title} paragraph={props.data.paragraph}/>
        <div  className={"landing-page-first-paragraph-desc"}>
            {props.data?.buttons?.map((button,index)=>(
                <Btn
                    key={index}
                    paddingX="12" paddingY="14"
                    className={`${buttonClassNameColors[index]}-button ${translate("medium")}`}
                    content={button} onClick={()=>navigateTo(props.data.__link[index],false,true)}
                    backgroundColor={buttonColors[index]} color={buttonColors[1-index]}
                    borderColor={buttonColors[1-index]}/>))}
        </div>
      </div>}
        </>
    );
}
export default TitleParagraphButtons;
