import {useTranslation} from "react-i18next";

const useHandleTranstation=()=>{
    const {i18n}=useTranslation();
    const isLngAr=(i18n.language==="ar");
    const isLngFr=(i18n.language==="fr");
    const getDirX=()=>{
        if (isLngFr) return "left";
        if (isLngAr) return "right";
        return "";
    }
    const getInverseDirX=()=>{
        if (isLngFr) return "right";
        if (isLngAr) return "left";
        return "";
    }
    const getInverseDivDir=()=>{
        if (isLngFr) return "rtl";
        if (isLngAr) return "ltr";
        return "";
    }
    const translateClassName= (className,lng=i18n.language) => {
        if (!className) {
            console.warn("Class name is undefined or null");
            return "";
        }

        return `${className}${(lng).toUpperCase()}`;
    };


    const translateSuffixCamelCase= (className) => {
        if (!className) {
            console.warn("Class name is undefined or null");
            return "";
        }
       if (isLngFr) return `${className}Fr`;
       if (isLngAr) return `${className}Ar`;

       return null;
    };
    const footerFontClassName=()=>{
        switch (i18n.language){
            case "fr" : return "rubik";
            case "ar" : return "regularAR";
            default : return "";
        }
    }
    const currLng=i18n.language;
    const otherLng=isLngAr? "fr":"ar"
    const currDir=i18n.dir;

    return {isLngAr,isLngFr,currLng,otherLng,currDir,getDirX,getInverseDirX,translateClassName,footerFontClassName,getInverseDivDir,translateSuffixCamelCase};
}
export default useHandleTranstation;