import {useNavigate} from "react-router-dom";
import {REDIRECTION_PATHS} from "../util/constants";
import {openInNewTab, redirect} from "../util/functionsUtil";

  const  useRokhasNavigate = () => {
    const navigate = useNavigate();
    const navigateTo=(link,redirectAll=false,newTab=false)=>{
        if(REDIRECTION_PATHS.some(item=>item.path===link)) {
            if(!newTab)  redirect((REDIRECTION_PATHS.find(item=>item.path===link)).link)
            else openInNewTab((REDIRECTION_PATHS.find(item=>item.path===link)).link)
        }
        else{
            if(redirectAll) redirect(link);
            else navigate(link);
        }
    }
    return {navigateTo};
}
export default useRokhasNavigate;