import {xml2js} from 'xml-js';
import {isEmptyString} from "../util/functionsUtil";


export const xmlParser=(xml)=>{
    const options={
        ignoreDeclaration:true,
        trim:true,
        compact: true,
        ignoreComment:true,
        ignoreCdata:true,
    }
    try {
         return  xml2js(xml,options);
    }catch (error){
        return {}
    }
}



export const formatRankingMannersData=(data)=>{
    let formatedData=[];
   for (let key in data){
       formatedData=[...formatedData,{label:data[key],value:key}]
   }
   return formatedData;
}
export const formatRankingData=(data)=>{
    const getLocaliteKey=(string)=>{
        switch(string){
            case "region": return "région";
            case "prefecture": return "prefecture";
            case "commune": return "commune";
            case "ville": return "ville";
            default : return "";
        }
    }
    const getLocaliteValue=(item)=>{
        let value=item?.contents?.[getLocaliteKey(data?.data[0]._type)];
        if (getLocaliteKey(data?.data[0]?._type)==="commune") value+=`,${item?.contents?.prefecture}`
        return value;
    }
   return {page:data?.data?.map((item)=>({
        rank:item?.contents?.rank,
        localite:getLocaliteValue(item),
        rankComp:item?.contents?.rankComp,
        score:item?.contents?.indecators.score,
        delai:item?.contents?.indecators.delai,
        attractivite:item?.contents?.indecators.attractivite,
        digital:item?.contents?.indecators.digital,
        ecosystem:item?.contents?.indecators.ecosystem,
        fiscalite:item?.contents?.indecators.fiscalite
    })),total:data?.total?.value,isError:data?.total?.value===0}
}
export const formatDynamicList=(data)=>{
    return  data?.option?.filter(item=>!isEmptyString(item._label)).map(item=>({label:item._label,value: item._value}));
}
export const formatSignUpProceduresData=(data)=>{
    const removedProc=["Demande e-Signature","Saisie demande","Validation demande"];
    const removedAct=["Ajout compléments"]
    return {data : data.data.filter((item)=>!removedProc.includes(item.label)).map(item=>({...item,options:item.options.filter((element)=>!removedAct.includes(element.label))}))};
}


