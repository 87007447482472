import styles from "./select.module.css"
import {useEffect, useState} from "react";
import classNames from "classnames";
import useHandleClassName from "../../hooks/useHandleClassName";
import {autoCompleteBold} from "../../util/functionsUtil";
import {Empty} from "antd";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useComponentVisible from "../../hooks/useComponentVisible";
export const Select=({disabled=false,...props})=> {
    const {ref,isOpen,setOpenStatus}=useComponentVisible();
    const [activeOption,setActiveOption]=useState(null);
    const [search,setSearch]=useState("");
    const [options,setOptions]=useState(props.options)
    const {translate}=useHandleClassName();
    const {t:tC}=useTranslation("components")

    useEffect(() => {
        setOptions(props.options);
        if (props.options===null) setActiveOption(null);
    }, [props.options]);

    useEffect(() => {
        if (disabled) {
            onOptionChange(null);
            setActiveOption(null);
        }
    }, [disabled]);

    useEffect(() => {
       if(props.active && props.active<=options.length) setActiveOption(options[props.active-1]);
    }, [props.active,options]);

    const onOptionChange=(option)=>{
        setActiveOption(option)
        props.onChange && props.onChange(option?.value?? option);
        setOpenStatus(false);
    }
    const onSearchChange=(e)=>{
     setSearch(e.target.value);
     const newOptions=props
         .options
         .filter((option)=> option?.label? option.label.toLowerCase().includes(e.target.value.toLowerCase()) : option.toLowerCase().includes(e.target.value.toLowerCase()) )
      setOptions(newOptions)
    }

    const onSelectClick=()=> {
        if (!disabled &&(!activeOption || !props.erasable)) {
            props.onClick && props.onClick(isOpen);
            setOpenStatus(!isOpen)
        }
    }
   const onCloseOption=()=>{
       if(!disabled) onOptionChange(null);
   }
    const angleIconStyles=classNames({
        "fa-solid fa-caret-down hover:cursor-pointer flex items-center duration-300 ease-in-out ":true,
        "fa-rotate-180":isOpen,
    })
    let icon = activeOption && props.erasable ? <i className="fa-light fa-circle-xmark" onClick={onCloseOption}/> :<i className={angleIconStyles}/>

    icon= props.loading && !disabled ? <i className="fa-solid fa-spinner-third fa-spin"/> : icon;
    const selectedOption = classNames({
        [styles.optionSelected]:activeOption,
        [styles.optionNotSelected]:!activeOption,
        [styles.optionError]:props.error,
        [styles.disabled]:disabled,
        [styles.selectedOption]:true

    })
    const optionsClass=classNames({
        [styles.options]:true,
        [styles.notDisplay]:props.loading || !isOpen,
    })
    return (
        <div className={`${styles.selectButton} ${props.className}`} ref={ref}>
            <div className={selectedOption} onClick={onSelectClick}>
                <div className="inline-grid">
                <span className={styles.selectedText}>{(activeOption?activeOption.label??activeOption:props.label)}</span>
                </div>
                {icon}
            </div>
            <div className={optionsClass} >
                {props.search &&
                    <div className={styles.searchInputContainer}>
                        <input className={`${styles.searchInputItem} ${translate("regular")}`}
                               placeholder={`${tC("select_search")} ...`}  onChange={onSearchChange}/>
                        <i className="fa-thin blue-color fa-magnifying-glass"></i>
                    </div>}
                {options && options.length!==0 ? options.map((option, index) => <div className={styles.option} key={index} onClick={() => onOptionChange(option)}>{autoCompleteBold(search,(option.label??option))
                    .map((element,key)=>(element.isBold ?(<b key={key}>{element.label}</b>):element.label))}</div>)
                    :<div className={styles.noData}><Empty description={"Aucun donnée"} /></div>
                }

            </div>
        </div>
    )
}