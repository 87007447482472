import styles from './ranking.module.css'
import {BAR_RANKING_DATA} from "../../util/constants";
import {useEffect, useState} from "react";
import classNames from "classnames";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {Tag} from "antd";

const HorizontalBarChart=(props)=>{
    const {isLngFr,isLngAr}=useHandleTranslation();
    const [percentValues,setPercentValues]=useState([0,0,0,0,0,0,0,0,0,0]);
    const maxValue=props.data.reduce((maxValue,currentValue)=>(Math.max(maxValue,currentValue.value)),0);
    useEffect(() => {
       if(props.data) setPercentValues([95,90,85,80,75,70,65,60,55,50]);
    }, [props.data]);
    const barsStyle=(index)=>{
        const commonStyle={background:BAR_RANKING_DATA[index],width:`${percentValues[index]}%`}
        const brBX=isLngAr? "borderBottomleftRadius":"borderBottomRightRadius";
        switch (index){
            case 0 : return {...commonStyle,borderTopRightRadius:"8px",borderTopLeftRadius:"8px",};
            case 9 : return {...commonStyle,borderBottomRightRadius:"8px",borderBottomLeftRadius:"8px",background:BAR_RANKING_DATA[index]};
            default: return {...commonStyle,[brBX]: "8px",background:BAR_RANKING_DATA[index]}
        }
    }
    const chatBarStyle=(index)=>classNames({
        ["flex gap-3"]:true,
        [styles.chartBar]:isLngFr,
        [styles.chartBarAr]:isLngAr,
        "text-white":index<4,
        "text-black":index>3
    })
    const chartBarScoreStyle=(index)=>classNames({
        "text-white":index<4,
        "text-black":index>3
    })
    return(
        <div className={styles.barChartContainer}>
            <div className={styles.barValuesContainer}>
                { props.data.map((item,index) =>(<div key={index} className={styles.barValue}>{index+1}</div>))}
            </div>
        <div className={styles.chartContainer}>
            {props.data.map((item,index) =>
                <div key={index} style={barsStyle(index)} className={chatBarStyle(index)}>
                    <div> {item.label}</div>
                    <Tag color="default" bordered={false}><div className={chartBarScoreStyle(index)}>{item.value}</div></Tag>
                </div>)}
        </div>
        </div>
    )
}
export default HorizontalBarChart;