import styles from "./input.module.css";
import * as React from "react";
import useHandleClassName from "../../hooks/useHandleClassName";

const Input=(props)=> {
    const {translate}=useHandleClassName();
    const onInputChange=(e)=>{
        props.onChange && props.onChange(e.target.value);
    }
    return(
        <div className={`${styles.inputContainer} ${translate("regular")}`}>
            <input onChange={onInputChange} className={styles.inputItem} placeholder={props.placeholder}/>
            <i className="fa-thin fa-magnifying-glass cursor-auto"></i>
        </div>)

}
export default Input;