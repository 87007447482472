import React, { Component } from 'react' ;
import {TopFlexComponent} from '../home/TopComponent';
import TitleParagraph from '../home/TitleParagraph';
import LinkAncre from '../home/LinkAncre';
import Icon from '../../components/util/Icon';

export default class Accompaniments extends Component {
    render() {
        return (
            <>
            {this.props.data &&
            <div className='landingpage-accompaniments'>
                <TitleParagraph title={this.props.data.title} paragraph={this.props.data.paragraph}/>
                <div className='landingpage-accompaniments-content-large'>
                    <TopFlexComponent data={this.props.data} className={"topcomponent-items-extra"}/>
                </div>
                
                <div className='landingpage-accompaniments-content-mini'>
                    <MiniComponentContainer icons={['ear-muffs', 'book-open', 'circle-video']} data={this.props.data} />
                </div>
            </div>
                }
            </>
        )
    }
}

function MiniComponentContainer( props ) {
    return <>
        <div className='landingpage-accompaniments-mini-container-items'>
            { props.data.children?.map?.( (item, index) => <MiniComponent key={index} data={item} /> ) }
        </div>
    </>
}

function MiniComponent( props ) {
    
    return <div className='landingpage-accompaniments-mini'>
        <div className='landingpage-accompaniments-mini-icon'>
            <Icon iconType='light' iconName={props.data.icon} className="blue-color"/>
        </div>
        <div className='landingpage-accompaniments-mini-content'>
            <div>{props.data.title}</div>
            <div>{props.data.paragraph}</div>
            <LinkAncre link={props.data.__link} data={props.data.link} newTab />
        </div>
    </div>
}