import {useTranslation} from "react-i18next";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import React, {useEffect} from "react";
import globalStyles from "../globalstyles.module.css"
import authorizEco from "../../asset/images/authorization/authorizEco.png";
import authorizUrba from "../../asset/images/authorization/authorizUrba.png";
import authorizChabakat from "../../asset/images/authorization/authorizChabakat.png";
import authorizEService from "../../asset/images/authorization/authorizEService.png";
import styles from './authorization.module.css'
import {RepositoryCard} from "../../pagescomponents/respositorycard/RepositoryCard";
import {useNavigate} from "react-router-dom";
import {scrollToTopOnMount} from "../../util/functionsUtil";
const RefActivitiesPage=()=>{
    const navigate=useNavigate();
    const {t:tBC}=useTranslation("breadcrumb");
    const {t:tRA}=useTranslation("referentielAutorisation");
    const breadcrumbParts = [
        {text: tBC("Accueil"), link: '/'},
        {text: tBC("prepare_dossier"), link: '/'},
        {text: tBC("referentiel_autorisation"), link: '/'},
    ];
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    return(
        <>
            <div className="breadcrumb">
                <BreadCrumb parts={breadcrumbParts}/>
            </div>
            <div className={globalStyles.pageTitleContainer + " responsive-web"}>
                <div className={globalStyles.pageTitle}>{tRA("page_title_1")}</div>
                <div className={globalStyles.pageTitleDescription}>{tRA("page_description_1")}</div>
            </div>
            <div className={globalStyles.pageTitleContainerMobile + " responsive-mobile"}>
                <div className={globalStyles.pageTitleMobile}>{tRA("page_title_2")}</div>
                <div className={globalStyles.pageTitleDescription}>{tRA("page_description_1")}</div>
            </div>
            <div className={globalStyles.pageContentContainer}>
                <div className={styles.repositoryCardsContainer}>
                    <RepositoryCard label={tRA("eco")} icon={authorizEco}
                                    onClick={() => navigate("eco")}>
                        <RepositoryExample className={"responsive-mobile"}
                                           items={[tRA("vente_aluminium"),tRA("lavage_voitures"),tRA("cafe_restaurant"),tRA("affichage_publicitaire")]}/>
                    </RepositoryCard>
                    <RepositoryCard label={tRA("urba")} icon={authorizUrba}
                                    onClick={() => navigate("urba")}>
                        <RepositoryExample className={"responsive-mobile"}
                                           items={[tRA("construction_immeuble"),tRA("renovation_villa"),tRA("demolition_ancien_immeuble"),tRA("construction_groupe_immobilier")]}/>
                    </RepositoryCard>
                    <RepositoryCard label={tRA("chabakat")} icon={authorizChabakat}
                                    onClick={() => navigate("chabakat")}>
                        <RepositoryExample className={"responsive-mobile"}
                                           items={[tRA("raccordement_electricite"),tRA("pose_reseaux_et_equipements_telecommunication"),tRA("travaux_installation_ou_maintenance_reseaux_publics")]}/>
                    </RepositoryCard>
                    <RepositoryCard label={tRA("eservice")} icon={authorizEService}
                                    onClick={() => navigate("eservice")}>
                        <RepositoryExample className={"responsive-mobile"}
                                           items={[tRA("correction_autorisation"),tRA("revendication_dossier"),tRA("renouvellement_autorisation")]}/>
                    </RepositoryCard>
                </div>
                <div className={styles.repositoryCardsContainer}>
                    <RepositoryExample className={"responsive-web"}
                                       items={[tRA("vente_aluminium"),tRA("lavage_voitures"),tRA("cafe_restaurant"),tRA("affichage_publicitaire")]}/>
                    <RepositoryExample className={"responsive-web"}
                                       items={[tRA("construction_immeuble"),tRA("renovation_villa"),tRA("demolition_ancien_immeuble"),tRA("construction_groupe_immobilier")]}/>
                    <RepositoryExample className={"responsive-web"}
                                       items={[tRA("raccordement_electricite"),tRA("pose_reseaux_et_equipements_telecommunication"),tRA("travaux_installation_ou_maintenance_reseaux_publics")]}/>
                    <RepositoryExample className={"responsive-web"}
                                       items={[tRA("correction_autorisation"),tRA("revendication_dossier"),tRA("renouvellement_autorisation")]}/>
                </div>
            </div>

        </>
    )
}

export const RepositoryExample = (props) => {
    const {t:tRA}=useTranslation("referentielAutorisation");
    return (
        <div className={`${styles.repositoryExampleContainer} ${props.className}`}>
            <p className={styles.repositoryExampleLabel}>{tRA("exemples")}</p>
                <ul className={styles.repositoryExampleItems}>
                    {props.items.map((item,index)=><li key={index}>{item}</li>)}
                    <i>...</i>
                </ul>
        </div>
    )
}
export default RefActivitiesPage;