const ErrorPageIcon=()=>{
return (
    <svg width="700" height="500" viewBox="0 0 440 418" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M227.464 408.752C342.881 408.752 436.446 397.932 436.446 384.584C436.446 371.236 342.881 360.416 227.464 360.416C112.046 360.416 18.4814 371.236 18.4814 384.584C18.4814 397.932 112.046 408.752 227.464 408.752Z"
            fill="#BAE6FD"/>
        <path
            d="M357.658 370.111C347.01 370.111 338.238 378.073 336.93 388.365H378.4C377.092 378.073 368.32 370.111 357.672 370.111H357.658Z"
            fill="#075985"/>
        <path
            d="M105.899 352.199H44.1706C42.1376 352.199 40.4885 353.848 40.4885 355.881C40.4885 357.914 42.1376 359.563 44.1706 359.563H105.899C107.931 359.563 109.581 357.914 109.581 355.881C109.581 353.848 107.931 352.199 105.899 352.199Z"
            fill="#0369A1"/>
        <path
            d="M80.0388 299.726C79.7545 298.93 79.001 298.404 78.148 298.404H71.0682C70.2152 298.404 69.4475 298.944 69.1774 299.754L49.2601 356.734H100.795L80.0388 299.726Z"
            fill="#0369A1"/>
        <path d="M65.4952 310.303L62.3534 319.302H87.1611L83.8914 310.303H65.4952Z" fill="#ECEFF1"/>
        <path d="M57.4772 333.234L54.3353 342.233H95.5205L92.2365 333.234H57.4772Z" fill="#ECEFF1"/>
        <path d="M384.115 86.7915H364.781V388.622H384.115V86.7915Z" fill="#ECEFF1"/>
        <path d="M384.101 86.7915H377.59V388.622H384.101V86.7915Z" fill="#939CA6"/>
        <path
            d="M104.889 158.542H92.8336C92.0517 158.542 91.4262 157.917 91.412 157.135C91.3409 151.846 86.9906 147.539 81.7021 147.539C79.001 147.539 76.3851 148.69 74.537 150.709C74.1531 151.121 73.5703 151.278 73.03 151.093C72.4898 150.908 72.1202 150.439 72.0775 149.87C71.3667 141.852 64.7561 135.796 56.7095 135.796C48.663 135.796 41.4695 142.577 41.2847 150.894C41.2704 151.548 40.8013 152.116 40.1615 152.244C39.5218 152.387 38.8678 152.074 38.5835 151.477C36.3515 146.885 31.8023 144.041 26.7128 144.041C19.505 144.041 13.5767 149.913 13.5057 157.121C13.5057 157.903 12.8659 158.528 12.084 158.528H1.42165C0.639741 158.528 0 157.888 0 157.106C0 156.325 0.639741 155.685 1.42165 155.685H10.7334C11.5296 147.567 18.4103 141.198 26.7128 141.198C31.5321 141.198 35.9535 143.302 38.9674 146.885C40.9435 138.924 48.2081 132.953 56.7095 132.953C65.211 132.953 72.5751 138.824 74.4943 146.984C76.5984 145.506 79.1147 144.695 81.7021 144.695C88.0711 144.695 93.388 149.515 94.1557 155.699H104.889C105.671 155.699 106.311 156.339 106.311 157.121C106.311 157.903 105.671 158.542 104.889 158.542Z"
            fill="#38BDF8"/>
        <path
            d="M101.762 363.473C91.1134 363.473 82.3419 371.434 81.0339 381.727H122.503C121.195 371.434 112.424 363.473 101.776 363.473H101.762Z"
            fill="#075985"/>
        <path d="M98.6483 86.7915H79.3138V388.622H98.6483V86.7915Z" fill="#ECEFF1"/>
        <path d="M98.6339 86.7915H92.1228V388.622H98.6339V86.7915Z" fill="#939CA6"/>
        <path d="M409.207 100.169H56.8374V271.151H409.207V100.169Z" fill="#ECEFF1"/>
        <path
            d="M176.256 402.525H102.771C100.354 402.525 98.3922 404.487 98.3922 406.904C98.3922 409.32 100.354 411.282 102.771 411.282H176.256C178.673 411.282 180.635 409.32 180.635 406.904C180.635 404.487 178.673 402.525 176.256 402.525Z"
            fill="#0284C7"/>
        <path
            d="M145.491 340.058C145.15 339.12 144.255 338.48 143.245 338.48H134.829C133.805 338.48 132.91 339.12 132.569 340.086L108.855 407.913H170.2L145.491 340.058Z"
            fill="#0284C7"/>
        <path d="M128.176 352.64L124.423 363.359H153.964L150.069 352.64H128.176Z" fill="#ECEFF1"/>
        <path d="M118.622 379.949L114.883 390.668H163.916L160.006 379.949H118.622Z" fill="#ECEFF1"/>
        <path
            d="M283.036 409.051L322.799 347.266C324.107 345.233 323.525 342.518 321.492 341.21C319.459 339.902 316.743 340.485 315.435 342.518L275.672 404.302C274.364 406.335 274.947 409.051 276.98 410.359C279.013 411.667 281.728 411.084 283.036 409.051Z"
            fill="#0284C7"/>
        <path
            d="M352.213 416.983C353.194 417.211 354.204 416.784 354.758 415.946L359.322 408.866C359.876 408.013 359.819 406.904 359.194 406.094L314.98 349.441L281.785 401.018L352.228 416.969L352.213 416.983Z"
            fill="#0284C7"/>
        <path d="M351.005 395.602L344.025 386.66L328.031 411.496L339.163 414.026L351.005 395.602Z" fill="#ECEFF1"/>
        <path d="M333.22 372.813L326.226 363.856L299.698 405.084L310.815 407.601L333.22 372.813Z" fill="#ECEFF1"/>
        <path d="M409.207 100.169H56.8374V126.427H409.207V100.169Z" fill="#0284C7"/>
        <path d="M67.386 100.169L56.8374 114.883V126.427H66.86L85.6826 100.169H67.386Z" fill="#ECEFF1"/>
        <path d="M104.52 126.427L123.356 100.169H105.06L86.223 126.427H104.52Z" fill="#ECEFF1"/>
        <path d="M142.179 126.427L161.016 100.169H142.719L123.882 126.427H142.179Z" fill="#ECEFF1"/>
        <path d="M179.853 126.427L198.689 100.169H180.393L161.556 126.427H179.853Z" fill="#ECEFF1"/>
        <path d="M217.512 126.427L236.349 100.169H218.052L199.216 126.427H217.512Z" fill="#ECEFF1"/>
        <path d="M255.186 126.427L274.008 100.169H255.726L236.889 126.427H255.186Z" fill="#ECEFF1"/>
        <path d="M292.845 126.427L311.682 100.169H293.385L274.549 126.427H292.845Z" fill="#ECEFF1"/>
        <path d="M330.519 126.427L349.341 100.169H331.045L312.222 126.427H330.519Z" fill="#ECEFF1"/>
        <path d="M368.178 126.427L387.015 100.169H368.719L349.882 126.427H368.178Z" fill="#ECEFF1"/>
        <path d="M405.852 126.427L409.207 121.736V100.169H406.378L387.555 126.427H405.852Z" fill="#ECEFF1"/>
        <path d="M409.207 245.376H56.8374V271.634H409.207V245.376Z" fill="#0284C7"/>
        <path d="M67.386 245.376L56.8374 260.091V271.634H66.86L85.6826 245.376H67.386Z" fill="#ECEFF1"/>
        <path d="M104.52 271.634L123.356 245.376H105.06L86.223 271.634H104.52Z" fill="#ECEFF1"/>
        <path d="M142.179 271.634L161.016 245.376H142.719L123.882 271.634H142.179Z" fill="#ECEFF1"/>
        <path d="M179.853 271.634L198.689 245.376H180.393L161.556 271.634H179.853Z" fill="#ECEFF1"/>
        <path d="M217.512 271.634L236.349 245.376H218.052L199.216 271.634H217.512Z" fill="#ECEFF1"/>
        <path d="M255.186 271.634L274.008 245.376H255.726L236.889 271.634H255.186Z" fill="#ECEFF1"/>
        <path d="M292.845 271.634L311.682 245.376H293.385L274.549 271.634H292.845Z" fill="#ECEFF1"/>
        <path d="M330.519 271.634L349.341 245.376H331.045L312.222 271.634H330.519Z" fill="#ECEFF1"/>
        <path d="M368.178 271.634L387.015 245.376H368.719L349.882 271.634H368.178Z" fill="#ECEFF1"/>
        <path d="M405.852 271.634L409.207 266.943V245.376H406.378L387.555 271.634H405.852Z" fill="#ECEFF1"/>
        <path d="M409.833 100.169H402.923V271.649H409.833V100.169Z" fill="#939CA6"/>
        <path
            d="M79.6122 370.368C66.3482 370.368 55.4158 380.291 53.7809 393.114H105.444C103.809 380.291 92.8762 370.368 79.6122 370.368Z"
            fill="#7DD3FC"/>
        <path
            d="M377.206 378.897C363.942 378.897 353.009 388.821 351.375 401.644H403.037C401.402 388.821 390.47 378.897 377.206 378.897Z"
            fill="#7DD3FC"/>
        <path
            d="M52.4871 374.846C41.839 374.846 33.0674 382.807 31.7595 393.1H73.229C71.9211 382.807 63.1495 374.846 52.5014 374.846H52.4871Z"
            fill="#7DD3FC"/>
        <path
            d="M355.099 78.9294H316.004C315.222 78.9294 314.596 78.3039 314.582 77.522C314.511 70.3 308.583 64.4429 301.375 64.4429C296.286 64.4429 291.751 67.2862 289.504 71.8781C289.22 72.461 288.566 72.7879 287.926 72.6458C287.287 72.5036 286.817 71.9492 286.803 71.2952C286.618 62.9643 279.709 56.1973 271.378 56.1973C263.048 56.1973 256.707 62.2535 256.01 70.2716C255.968 70.8261 255.584 71.3094 255.058 71.4942C254.518 71.679 253.935 71.5227 253.551 71.1104C251.703 69.1059 249.101 67.9401 246.386 67.9401C241.097 67.9401 236.733 72.2477 236.676 77.5362C236.676 78.3181 236.036 78.9437 235.254 78.9437H223.199C222.417 78.9437 221.777 78.3039 221.777 77.522C221.777 76.7401 222.417 76.1004 223.199 76.1004H233.932C234.714 69.9162 240.031 65.0968 246.386 65.0968C248.973 65.0968 251.504 65.9072 253.594 67.3857C255.499 59.2254 262.735 53.354 271.378 53.354C280.022 53.354 287.144 59.3249 289.121 67.2862C292.12 63.7178 296.542 61.5996 301.375 61.5996C309.663 61.5996 316.558 67.9828 317.354 76.0862H355.113C355.895 76.0862 356.535 76.7259 356.535 77.5078C356.535 78.2897 355.895 78.9294 355.113 78.9294H355.099Z"
            fill="#38BDF8"/>
        <path
            d="M199.642 36.2093H169.304C168.522 36.2093 167.897 35.5837 167.882 34.8018C167.783 23.8551 158.798 14.9556 147.851 14.9556C140.146 14.9556 133.237 19.2774 129.853 26.2435C129.569 26.8406 128.915 27.1534 128.275 27.0112C127.636 26.869 127.166 26.3146 127.152 25.6606C126.882 13.0933 116.433 2.8574 103.866 2.8574C91.2982 2.8574 81.7305 11.9844 80.6643 24.0968C80.6216 24.6513 80.2378 25.1346 79.7118 25.3194C79.1715 25.5042 78.5887 25.3479 78.2048 24.9356C75.3757 21.8506 71.3667 20.0878 67.2155 20.0878C59.0979 20.0878 52.4019 26.6984 52.3024 34.816C52.3024 35.5979 51.6627 36.2235 50.8808 36.2235H33.1955C32.4135 36.2235 31.7738 35.5837 31.7738 34.8018C31.7738 34.0199 32.4135 33.3802 33.1955 33.3802H49.5302C50.3547 24.3669 58.0174 17.2445 67.2155 17.2445C71.2245 17.2445 75.1056 18.6235 78.219 21.0971C80.579 8.8994 91.1702 0.0283203 103.866 0.0283203C116.561 0.0283203 127.237 9.25481 129.541 21.3104C133.805 15.5669 140.501 12.1265 147.851 12.1265C159.878 12.1265 169.83 21.5379 170.669 33.3802H199.642C200.424 33.3802 201.064 34.0199 201.064 34.8018C201.064 35.5837 200.424 36.2235 199.642 36.2235V36.2093Z"
            fill="#38BDF8"/>
        <path
            d="M410.828 62.4245C387.484 51.1224 359.392 60.9034 348.104 84.2468L347.863 84.7444L432.408 125.645L432.65 125.148C443.952 101.804 434.171 73.7124 410.828 62.4245Z"
            fill="#7DD3FC"/>
        <path
            d="M420.566 58.5434L407.828 52.3877C402.355 49.7434 395.801 52.3877 393.711 58.1027L378.528 99.5864L401.743 110.817L424.845 73.158C428.015 67.9832 426.039 61.1877 420.566 58.5434Z"
            fill="#38BDF8"/>
        <path
            d="M437.128 124.351L345.944 80.2376C343.427 79.0149 340.399 80.067 339.177 82.5975C337.954 85.1138 339.006 88.1419 341.537 89.3645L432.721 133.478C435.238 134.701 438.266 133.649 439.488 131.118C440.711 128.588 439.659 125.574 437.128 124.351Z"
            fill="#38BDF8"/>
    </svg>

)
}
export default ErrorPageIcon;