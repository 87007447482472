import {
    SortIcon,
    SortUpIcon,
    SortDownIcon,
} from '../../../asset/icons/SortIcons.js';
import PropTypes from 'prop-types';
import styles from './thead.module.css'
const HeaderCell = ({ column,translate }) => {
    const cellProps=!column.nonSortable ? {...column.getHeaderProps(column.getSortByToggleProps())}:{};
    const cellColor=column.color?? "inherit";
    return (
        <th
            scope="col"
            className={styles.tableHeaderCell}
            {...cellProps}
            style={{...cellProps.style, background: cellColor}}
        >
            <div className={styles.cellInner}>
                {(() => {
                    if (column.render('Header') === 'Orders') {
                        return '';
                    } else {
                        return (
                            <>
                                <div className={styles.textCenterFullWidth}>
                                    {translate ? translate(column.render('Header')) : column.render('Header')}
                                </div>
                                <span className={styles.sortIconContainer}>
                            {(() => {
                                if (column.isSorted) {
                                    if (column.isSortedDesc) {
                                        return <SortDownIcon className={styles.sortDownIcon}/>;
                                    } else {
                                        return <SortUpIcon className={styles.sortUpIcon}/>;
                                    }
                                } else {
                                    return <SortIcon className={styles.sortIcon}/>;
                                }
                            })()}
                        </span>
                            </>
                        );
                    }
                })()}
            </div>
        </th>
    );
};
export default HeaderCell;
HeaderCell.propTypes = {
    column: PropTypes.shape({
        getHeaderProps: PropTypes.func.isRequired,
        getSortByToggleProps: PropTypes.func.isRequired,
        render: PropTypes.func.isRequired,
        isSorted: PropTypes.bool,
        isSortedDesc: PropTypes.bool,
    }).isRequired,
};