import React, { Component } from 'react'
import Icon from '../../components/util/Icon';
import LinkAncre from '../home/LinkAncre';
import TitleParagraph from '../home/TitleParagraph'

const StepsProfil = (props)=> {
        return (
            (props.data?.children || props.data?.title) &&
            <div className={`landing-page-step-profil ${props.className}`}>
                <TitleParagraph title={props.data.title} paragraph={props.data.paragraph} />
                <div className="flex-center" style={{padding:'1% 2%'}} >
                    <div className='landing-page-steps'>
                        {  props.data.children.map( ( step,index ) => <StepItem key={index} index={index} step={step} /> ) }
                    </div>
                </div>
            </div>
        )

}

const StepItem=( props )=>{
    return (
        <div className='landing-page-step-item'>
                <div style={{height:"50%", marginBottom :"15%"}}>
                    <div className='landing-page-step-icon'> <Icon iconType='light' iconName={props.step.icon} /> </div>
                    <div className='landing-page-step-title'>{parseInt(props.index)+1}. {props.step.title}</div>
                </div>
               <LinkAncre data={props.step.link} link={props.step.__link} className={"flex-center"}/>
        </div>
    )
}
export default StepsProfil;

