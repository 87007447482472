import {EmptyData} from "../../asset/images/svgImages";
import './emptydata.css'
import {useTranslation} from "react-i18next";
export const EmptySearchData=(props)=>{
   const {t:tC}=useTranslation("components")
    return(
        <div className="empty-data-container">
            <EmptyData/>
        <div className={"empty-data-title"}>{props.primaryMessage??tC("no_data_found_title")}</div>
        <div className={"empty-data-description"}>{props.secondaryMessage??tC("no_data_found_description")}</div>
        </div>
    )
}