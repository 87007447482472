const TagCard=(props)=>{
    const style=
        {   color:props.color,
            background:props.backgroud,
            padding:'8px 10px',
            width:"fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius:"4px",
            margin:props.margin,
            fontSize:props.size,
        }
    return (<>
    <div style={style}>
        {props.text}
    </div>
    </>);
}
export default TagCard;