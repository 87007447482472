export const useHandleStandardResult=(reactQuery)=>{
    const SUCCESS_STATUS_CODES = [200, 201, 202, 203, 204, 205, 206, 207, 208, 226];
    const ERROR_STATUS="ERROR"
    const ERROR_STATUS_CODES = [
        400, 401, 402, 403, 404, 405, 406, 407, 408, 409,
        410, 411, 412, 413, 414, 415, 416, 417, 418, 422,
        423, 424, 425, 426, 428, 429, 431, 451, 500, 501,
        502, 503, 504, 505, 506, 507, 508, 510, 511
    ];
    const isSuccess= reactQuery?.data ?  SUCCESS_STATUS_CODES.includes(reactQuery?.data?.status) :null;
    const isError=reactQuery?.data ? ERROR_STATUS_CODES.includes(reactQuery?.data?.status) :null ;
    const isErrorMessageExist= isError && reactQuery?.data?.message?.messageType===ERROR_STATUS && reactQuery?.data?.message?.messageContent!==null;
    const errorMessage=isErrorMessageExist ? reactQuery?.data?.message?.messageContent : null;
    const data = reactQuery?.data?.body;

    return {isSuccess,isError,isErrorMessageExist,errorMessage,data};
    
}