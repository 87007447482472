import React, { useEffect } from 'react';
import useHandleClassName from "../../hooks/useHandleClassName";

const BreadCrumb = ({ parts }) => {
    const {translate}=useHandleClassName();
    return (
        <div className="breadcrumb">
            {parts.map((part, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <span style={{color:"#9CA3AF"}}>&nbsp;&nbsp;/&nbsp;&nbsp;</span>}
                    {index === parts.length - 1 ? (
                        <span className={`${translate("regular")}`} style={{color:"#0369A1",fontSize:"14px"}}>{part.text}</span>
                    ) : (
                        <>
                            {part.link && <a className={`${translate("regular")}`} href={part.link} style={{color:"#9CA3AF",fontSize:"14px",textDecoration: "none" }}>{part.text}</a>}
                            {part.action && <span className={`${translate("regular")}`} onClick={part.action} style={{color:"#9CA3AF",fontSize:"14px",cursor:"pointer" }}>{part.text}</span>}
                        </>
                )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default BreadCrumb;