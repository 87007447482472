import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import globalStyles from "../globalstyles.module.css"
import {useTranslation} from "react-i18next";
import styles from "./nationalranking.module.css"
import icon from "../../asset/images/national-ranking/classement-logo.svg"
import {Tabs} from "antd";
import {NATIONAL_RANKING_COLUMNS} from "../../holders/tableColumns";
import Table from "../../components/table/Table";
import GlobalSearch from "../../components/search/GlobalSearch";
import {FilterButton} from "../../components/filters/FilterButton";
import {Select} from "../../components/select/Select";
import {RADAR_RANKING_DATA} from "../../util/constants";
import classNames from "classnames";
import {useGetRankingData, useGetRankingFilterCriteria, useGetRankingFilterManners} from "../../api/caller";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import ChampionTree from "../../pagescomponents/ranking/ChampionTree";
import HorizontalBarChart from "../../pagescomponents/ranking/HorizontalBarChart";
import {useFormatData} from "../../hooks/useFormatData";
import Btn from "../../components/button/Btn";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {scrollToTopOnMount} from "../../util/functionsUtil";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);
const RankingContext=createContext(
    {
        activePeriode:{index:0,label:""},
        rankingDataCriteria:{page: 1, size: 10, search: "", periode: "", nbRanking:0, region:"", localite:""}});
const NationalRankingPage=()=>{
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const [activePeriode,setActivePeriode]=useState({index:0,label:""});
    const [rankingDataCriteria,setRankingDataCriteria]=useState({
        page: 1,
        size: 10,
        search: "",
        periode: "",
        nbRanking:0,
        region:"",
        localite:"",
        sortField:{id:"rank",desc:false},
    })
    const {t:tBC}=useTranslation("breadcrumb");
    const tabRef=useRef(null);
    const breadcrumbParts = [
        {text: tBC("Accueil"), link: '/'},
        {text: tBC("J’explore les indicateurs"), link: '/'},
        {text: tBC("Classement national"), link: '/'},
    ];
    const {t:tNR}=useTranslation("nationalRanking")
    const items =[
        {
            key: '1',
            label: tNR("page_tab_1"),
            children: <Ranking/>,
        },
        {
            key: '2',
            label: tNR("page_tab_2"),
            children: <Champion/>,
        },
        {
            key: '3',
            label: tNR("page_tab_3"),
            children: <Top10/> ,
        },
        {
            key: '4',
            label: tNR("page_tab_4"),
            children:<RadarTab/>,
        }
    ];
    return(
        <>
            <div className="breadcrumb">
                <BreadCrumb parts={breadcrumbParts}/>
            </div>
            <div className={globalStyles.pageTitleContainer + " responsive-web"}>
                <div className={globalStyles.pageTitle}>{tNR("page_title")}</div>
                <div className={globalStyles.pageTitleDescription}>
                    {tNR("page_title_description")}
                </div>
            </div>
            <div className={globalStyles.pageTitleContainerMobile + " responsive-mobile"}>
                <div className={globalStyles.pageTitleMobile}>{tNR("page_title")}</div>
            </div>
            <div className={styles.pageContentContainer}>
                <RankingDescriptionCard/>
                <RankingContext.Provider value={{activePeriode,setActivePeriode,rankingDataCriteria,setRankingDataCriteria}}>
               <div ref={tabRef}>
                <Tabs animated defaultActiveKey="1" items={items}/>
               </div>
                </RankingContext.Provider>
            </div>
        </>
            )
}

const Ranking=()=>{
    const {activePeriode,setActivePeriode,rankingDataCriteria,setRankingDataCriteria}=useContext(RankingContext);
    const {formatFilterRankingPeriodeData}=useFormatData();
    const {currLng}=useHandleTranslation();
    const columns = React.useMemo(() => NATIONAL_RANKING_COLUMNS(), []);
    const [showFilter,setShowFilter]=useState(false);
    const [searchCriteria,setSearchCriteria]=useState("");
    const [pagination, setPagination] = [{page:rankingDataCriteria.page,size:rankingDataCriteria.size},(page)=>setRankingDataCriteria({...rankingDataCriteria,...page()})]
    const {t:tNR}=useTranslation("nationalRanking")
    const getRankingFilterPeriode=useGetRankingFilterCriteria("trim",currLng);
    const getRankingFilterRegion=useGetRankingFilterCriteria("région",currLng)
    const getRankingFilterManners=useGetRankingFilterManners(currLng);
    const getRankingData=useGetRankingData(rankingDataCriteria,currLng)


    const setSorting= (sorting)=>setRankingDataCriteria({...rankingDataCriteria,sortField:sorting[0]});
    const filterItemsContainerStyle=classNames({
        [styles.filterItemsContainer]:true,
        [styles.notShow]:!showFilter,
        [styles.filterItemsContainerAppear]:showFilter,
    })
    useEffect(() => {
        if(getRankingFilterPeriode.isFetched) setRankingDataCriteria({...rankingDataCriteria,periode:getRankingFilterPeriode?.data[0]?.value})
    }, [getRankingFilterPeriode.isFetched]);
    useEffect(() => {
        const index=getRankingFilterPeriode.data.findIndex((item)=> (item.value===rankingDataCriteria.periode));
        setActivePeriode({index,label:formatFilterRankingPeriodeData(getRankingFilterPeriode?.data[index]?.label)}) ;
    }, [rankingDataCriteria]);
    const onPeriodeChange=(string)=>{
     setRankingDataCriteria({...rankingDataCriteria,page: 1,periode: string??""});
    }
    const onClassementChange=(string)=>{
        setRankingDataCriteria({...rankingDataCriteria,page: 1,nbRanking: string??""});
    }
    const onRegionChange=(string)=>{
        setRankingDataCriteria({...rankingDataCriteria,page: 1,region: string??""});
    }
    const onSearchClick=()=>{
        if (searchCriteria===rankingDataCriteria.search && rankingDataCriteria.page===1) getRankingData.refetch();
        else{
            if(searchCriteria.length !==0) setRankingDataCriteria({...rankingDataCriteria,page: 1,search:searchCriteria});
        }
    }
    const onSearchChange=(string)=>{
        setSearchCriteria(string);
        if (string.length===0) setRankingDataCriteria({...rankingDataCriteria,search: ""});
    }
    const onChangeFilterButton=(isOpen)=>{
        setShowFilter(isOpen);
    }

    const sortRankingPeriode=(item1,item2)=>{
        if (item1.value.split("-")[1]> item2.value.split("-")[1]) return -1;
        else if (item1.value.split("-")[1]< item2.value.split("-")[1]) return 1;
        else {
            if (item1.value.split("-")[0]> item2.value.split("-")[0]) return -1;
            else return 1
        }
    }
    return(
        <div className={"py-3"}>
            <div className="flex justify-between items-center">
            <div className={"responsive-web"}>
                <div className={styles.pageTitle}>{tNR("tab_1_title")} <span className={styles.activePeriodeText}>{activePeriode.label}</span>
                </div>
                <div className={styles.pageTitleDescription}>
                    {tNR("tab_1_description")}
                </div>
            </div>
            <div className={"responsive-mobile"}>
                <div className={styles.pageTitleMobile}>{activePeriode.label}</div>
            </div>
             <div className="gap-2 h-fit hidden">
                 <Btn paddingX="11" paddingY="10" className="blue-button"
                      content={
                     <div className="flex gap-2">
                         <i className="fa-solid fa-download"></i><span className="responsive-web">Télécharger</span>
                     </div>
                 }
                      backgroundColor="#0369A1" color="#fff" ></Btn>
                 <Btn paddingX="11" paddingY="10" className="blue-button"
                      content={
                          <div className="flex gap-2">
                              <i className="fa-solid fa-print"></i><span className="responsive-web">Imprimer</span>
                     </div>
                      }
                     backgroundColor="#0369A1" color="#fff"></Btn>
             </div>

            </div>
            <div className={styles.searchContainer}>
                <div className={styles.searchItemContainer}>
                    <GlobalSearch loading={false} onChange={onSearchChange} onClick={onSearchClick}
                                  menu={[]}
                                  placeholder={tNR("tab_1_search_placeholder")} />
                </div>
                <div className={styles.filterContainer}>
                    <FilterButton onChange={onChangeFilterButton}/>
                </div>
            </div>
            <div className={filterItemsContainerStyle}>
                <Select label={tNR("tab_1_search_filter_1")}
                        options={
                         getRankingFilterPeriode
                        .data
                        .sort(sortRankingPeriode)
                        .map((item=>({...item,label:formatFilterRankingPeriodeData(item?.label)})))}
                        loading={getRankingFilterPeriode.isFetching} onChange={onPeriodeChange}
                        active={activePeriode.index+1}
                     />
                <Select label={tNR("tab_1_search_filter_2")}
                        options={getRankingFilterManners.data}
                        active={rankingDataCriteria.nbRanking + 1}
                        loading={getRankingFilterManners.isFetching} onChange={onClassementChange}
                />
                <Select label={tNR("tab_1_search_filter_3")}
                        options={getRankingFilterRegion.data}
                        loading={getRankingFilterRegion.isFetching} onChange={onRegionChange}
                        erasable
                />
            </div>
            <div className={styles.table} id={"#report"}>
                <Table
                    emptyDataPrimaryMessage={tNR("tab_1_table_emptydata")}
                    isLoading={getRankingData.isFetching}
                    isError={getRankingData.data.isError}
                    isSuccess={getRankingData.isFetched}
                    columns={columns}
                    rows={getRankingData?.data?.page??[]}
                    totalElements={getRankingData.data?.total}
                    setPagination={setPagination}
                    pagination={pagination}
                    sorting={[rankingDataCriteria.sortField]}
                    setSorting={setSorting}
                    translate={tNR}
                />
            </div>

        </div>
    )
}
const  Champion=()=>{
    const {activePeriode,rankingDataCriteria}=useContext(RankingContext);
    const {currLng}=useHandleTranslation();
    const getChampionDelai=useGetRankingData({...rankingDataCriteria,nbRanking:0,search:"",region:"",sortField:{id:"delai",desc:true},page:1,size:3},currLng);
    const getChampionAttractivite=useGetRankingData({...rankingDataCriteria,nbRanking:0,search:"",region:"",sortField:{id:"attractivite",desc:true},page:1,size:3},currLng);
    const getChampionDigital=useGetRankingData({...rankingDataCriteria,nbRanking:0,search:"",region:"",sortField:{id:"digital",desc:true},page:1,size:3},currLng);
    const getChampionEcosystem=useGetRankingData({...rankingDataCriteria,nbRanking:0,search:"",region:"",sortField:{id:"ecosystem",desc:true},page:1,size:3},currLng);
    const getChampionFiscalite=useGetRankingData({...rankingDataCriteria,nbRanking:0,search:"",region:"",sortField:{id:"fiscalite",desc:true},page:1,size:3},currLng);
    const {simpleFormat}=useFormatData();
    const {t:tNR}=useTranslation("nationalRanking")

    return(
        <div className={"py-3"}>
            <div className={"responsive-web"}>
                <div className={styles.pageTitle}>{tNR("tab_2_title")} <span className={styles.activePeriodeText}>{activePeriode.label}</span>
                </div>
                <div className={styles.pageTitleDescription}>
                    {tNR("tab_2_description")}
                </div>
            </div>
            <div className={"responsive-mobile"}>
                <div className={styles.pageTitleMobile}>{activePeriode.label}</div>
            </div>
            <div className={styles.championContainer}>
                <ChampionTree title={tNR("tab_2_chart_1")} data={simpleFormat(getChampionDelai.data.page,["localite","delai"],["label","value"])}/>
                <ChampionTree title={tNR("tab_2_chart_2")} data={simpleFormat(getChampionAttractivite.data.page,["localite","attractivite"],["label","value"])}/>
                <ChampionTree title={tNR("tab_2_chart_3")} data={simpleFormat(getChampionDigital.data.page,["localite","digital"],["label","value"])}/>
                <ChampionTree title={tNR("tab_2_chart_4")} data={simpleFormat(getChampionEcosystem.data.page,["localite","ecosystem"],["label","value"])}/>
                <ChampionTree title={tNR("tab_2_chart_5")} data={simpleFormat(getChampionFiscalite.data.page,["localite","fiscalite"],["label","value"])}/>
            </div>
        </div>
    )
}
const Top10=()=>{
    const {currLng}=useHandleTranslation();
    const {activePeriode,rankingDataCriteria}=useContext(RankingContext);
    const getRankingData=useGetRankingData({...rankingDataCriteria,page: 1,
        size: 10,
        search: "",
        nbRanking:0,
        region:"",
        localite:"",
    },currLng);
    const {simpleFormat}=useFormatData();
    const {t:tNR}=useTranslation("nationalRanking")

    return(
        <div className={"py-3"}>
            <div className={"responsive-web"}>
                <div className={styles.pageTitle}>{tNR("tab_3_title")} <span className={styles.activePeriodeText}>{activePeriode.label}</span>
                </div>
                <div className={styles.pageTitleDescription}>
                    {tNR("tab_3_description")}
                </div>
            </div>
            <div className={"responsive-mobile"}>
                <div className={styles.pageTitleMobile}>{activePeriode.label}</div>
            </div>
            <div className={"p-8"}>
                <HorizontalBarChart data={simpleFormat(getRankingData.data.page,["localite","score"],["label","value"])}/>
            </div>
        </div>)
}
const  RadarTab=()=>{
    const {activePeriode,rankingDataCriteria}=useContext(RankingContext);
    const {t:tNR}=useTranslation("nationalRanking")
    const {currLng,getDirX,currDir}=useHandleTranslation();
    const getRankingData=useGetRankingData({
        ...rankingDataCriteria,
        region:"",
        page: 1,
        size: 12,
        nbRanking:1,
        },currLng)
    const [radarData,setRadarData]=useState({
        labels: [],
        datasets: []
    });

    const fillDataSet=()=>{return getRankingData.data?.page?.map((item,index)=>({
        label: item?.localite,
        data: [item.delai, item.attractivite,item.digital,item.ecosystem,item.fiscalite],
        backgroundColor: RADAR_RANKING_DATA[index].backgroundColor,
        borderColor: RADAR_RANKING_DATA[index].borderColor,
        borderWidth: 1,
    }))
    }
    useEffect(() => {
        if (getRankingData.isFetched) {
           setRadarData(
             {
                ...radarData,
                 labels: [tNR("tab_1_table_col_4"),tNR("tab_1_table_col_5"),tNR("tab_1_table_col_6"),tNR("tab_1_table_col_7"),tNR("tab_1_table_col_8")],
                 datasets: fillDataSet()
            }
           )
        }
    }, [getRankingData.isFetched,currLng]);

    const radarOptions={
        scale: {
            pointLabels: {
                fontColor: "#000",
            },
            gridLines: {
                color: '#000'
            },
            angleLines: {
                color: '#000'
            }
        },
    };
    const mobileOptions= {
      ...radarOptions,
        aspectRatio:0,
        plugins: {
            legend: {
                position: 'bottom',
                align:"start",
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 10
                }
            }
        }
    }

    const webOptions= {
        ...radarOptions,
        aspectRatio:2,
        plugins: {
            legend: {
                rtl:currDir()==="rtl",
                position: getDirX(),
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding: 10
                }
            }
        }
    }
        return(
            <div className={"py-3"}>
                <div className={"responsive-web"}>
                    <div className={styles.pageTitle}>{tNR("tab_4_title")} <span
                        className={styles.activePeriodeText}>{activePeriode.label}</span>
                    </div>
                    <div className={styles.pageTitleDescription}>
                        {tNR("tab_4_description")}
                    </div>
                </div>
                <div className={"responsive-mobile"}>
                    <div className={styles.pageTitleMobile}>{activePeriode.label}</div>
                </div>
                <div className={`${styles.radarContainer} responsive-web`}>
                    <Radar data={radarData} options={webOptions}/>
                </div>
                <div className={`${styles.radarContainer} responsive-mobile`}>
                    <Radar data={radarData} options={mobileOptions}/>
                </div>
            </div>
        )
}

const RankingDescriptionCard = () => {
    const {t:tNR}=useTranslation("nationalRanking")
    const {getInverseDirX}=useHandleTranslation();
    return (
        <div className={styles.rankingCardContainer}>
            <div className={styles.rankingCardIcon}>
                <img className="w-full" src={icon}/>
            </div>
            <div className={styles.rankingCardText}>
                <span className={styles.rankingCardTextTitle}>{tNR("page_card_title")}</span>
                <span className={`${styles.rankingCardTextDescription}`}>
                    {tNR("page_card_description_1")}
                   <strong> {tNR("page_card_description_2")}</strong>
                </span>
                <strong>{tNR("page_card_description_3")}</strong>

                <div className={styles.rankingCardTextLinkContainer}>
                    <a className={styles.rankingCardTextLink} href={"/classement-national/methodology-detail"}>{tNR("page_card_link")}</a>
                    <i className={`fa-solid fa-arrow-${getInverseDirX()} ${globalStyles.blueColor}`}></i>
                </div>

            </div>
        </div>
    )
}
export default NationalRankingPage;