import React, { useState, useEffect } from 'react';
import Btn from '../../components/button/Btn';
import LinkAncre, {LinkAdapter} from '../home/LinkAncre';
import TitleParagraph from '../home/TitleParagraph';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import VideoPlayer from '../../pages/article/comments/VideoPlayer';
import Tag from '../../components/util/Tag';
import {useGetArticleEvidence} from "../../api/caller";
import {ArticleCardData} from "../articlecard/ArticleCard";
import {useTranslation} from "react-i18next";
import useHandleTranslation from "../../hooks/useHandleTranslation";

const Articles=(props)=> {

    const {currLng}=useHandleTranslation();
    const getArticleEvidence=useGetArticleEvidence(currLng);
    const newsCards = getArticleEvidence.data?.page?.map((article, index) => (
        <ArticleCardData data={article} key={index}/>
    ));

    return (
        getArticleEvidence.isFetched && getArticleEvidence.isSuccess && (
            <div className='landing-page-news topcomponent-call'>
                <TitleParagraph title={props.data?.title} paragraph={props.data?.paragraph} />
                <div className='landing-page-news-scroll-container'>
                    <div className='landing-page-news-container '>{newsCards}</div>
                </div>
            </div>
        )
    );
}


export default Articles;