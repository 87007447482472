import styles from "./select.module.css"
import globalStyles from "../../globalStyles.module.css"
import {forwardRef, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import useHandleClassName from "../../hooks/useHandleClassName";
import {autoCompleteBold, generateIndexFromString} from "../../util/functionsUtil";
import {Empty, Tag} from "antd";
import * as React from "react";
import {tagColors} from "../../util/constants";
import {useTranslation} from "react-i18next";
import useComponentVisible from "../../hooks/useComponentVisible";
import {CloseOutlined} from "@ant-design/icons";

 const SelectForm=(props)=> {
     const {ref,isOpen,setOpenStatus}=useComponentVisible();
     const {t:tER}=useTranslation("formErrors");
     const {t:tC}=useTranslation("components")
     const [activeOption,setActiveOption]=useState(null);
    const [search,setSearch]=useState("");
    const [options,setOptions]=useState(props.options)
    const {translate}=useHandleClassName();
    const selectedOptionRef = useRef(null);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        if(props.active && props.active<=options.length) {
            !props.multi && setActiveOption(options[props.active-1]);
             props.multi && setActiveOption([...activeOption,options[props.active-1]]);
        }
    }, [props.active,options]);

    const onOptionChange=(option)=>{
      if(props.multi){
          if(!(activeOption && activeOption.includes(option) && activeOption?.map(item=>item?.label)?.includes(option?.label))) {
              props.onChange && props.onChange(option.value? [...((activeOption??[]).map(item=>item.value)),option.value]:[...(activeOption??[]),option]);
              setActiveOption([...(activeOption??[]),option]);
          }
      }
       if(!props.multi)  {
           props.onChange && props.onChange(option?.value?? option);
           setActiveOption(option);
       }
        setOpenStatus(false);
    }
    const onSearchChange=(e)=>{
        setSearch(e.target.value);
        const newOptions=props
            .options
            .filter((option)=> option?.label? option.label.toLowerCase().includes(e.target.value.toLowerCase()) : option.toLowerCase().includes(e.target.value.toLowerCase()) )
        setOptions(newOptions)
    }

    const onSelectClick=()=> {
            props.onClick && props.onClick(isOpen);
            setOpenStatus(!isOpen)
    }

    const onCloseTag=(option)=>{
        const newActiveOptions=activeOption.filter(item=>(item.label??item)!==(option.label??option));
        if (activeOption.length!==0) setActiveOption(newActiveOptions.length===0? null:newActiveOptions);
        else {setActiveOption(null);
        }
    }
     const caretStyles=classNames({
         "fa-solid fa-caret-down px-2.5 !text-[#6B7280] hover:cursor-pointer transition-transform":true,
         "rotate-180":isOpen,
     })
    let icon =  <i className={caretStyles}/>;
    icon= props.loading ? <i className="fa-solid fa-spinner-third fa-spin px-2.5 !text-[#6B7280]"/> : icon;

    const selectedOption = classNames({
        [styles.formOptionSelected]:activeOption,
        [styles.formOptionNotSelected]:!activeOption,
        [styles.formOptionError]:!!props.errors?.[props.name]?.message,
        [styles.formSelectedOption]:true,
        [styles.openFormSelect]:isOpen,
    })
    const optionsClass=classNames({
        [styles.options]:true,
        [globalStyles.notDisplay]:props.loading || !isOpen,
    })
     const formLabelStyles=classNames({
         [styles.formLabel]:true,
         [styles.activeFormLabel]:isOpen,
         "!text-red-600": !!props.errors?.[props.name]?.message
     })
     const optionsStyles=(active)=>classNames({
         [styles.option]:true,
         [styles.activeOptions]:active,
     })

     const getValues=()=>{
        if (!activeOption) return null;
       return  props.multi ? activeOption.map(item=>item.value?? item):activeOption.value??activeOption;
     }
     useEffect(()=>{
        if(activeOption) props.setter && props.setter(props.name,getValues(),{ shouldValidate: true });
        else props.setter && props.setter(props.name,null);
     },[activeOption]);
     useEffect(()=>{
         if(activeOption) setActiveOption(null);
     },[props.options])
     const onKeyDown=(e) => { if (e.keyCode === 9) e.preventDefault() };
     const checkActiveOption = (option, activeOption) => {
         if (props.multi) {
             if (option.label) {
                 return activeOption?.map(item => item.label).includes(option.label);
             } else {
                 return activeOption.includes(option);
             }
         } else {
             if (option.label) {
                 return activeOption?.label === option?.label;
             } else {
                 return activeOption === option;
             }
         }
     };
     const isActiveOption = (option) => {
         if (props.multi) {
             if (option.label) {
                 return activeOption?.map(item => item.label).includes(option.label);
             } else {
                 return activeOption.includes(option);
             }
         } else {
             if (option.label) {
                 return activeOption?.label === option?.label;
             } else {
                 return activeOption === option;
             }
         }
     };
     return (
        <div className={`${styles.selectButton} ${props.className}`} ref={ref}>
            <div className={formLabelStyles}>
                {props.label}
                {props.important && <span className="text-red-500"> *</span>}
            </div>
            <div className={selectedOption} name={props.name} onClick={onSelectClick} ref={selectedOptionRef}>
                <div className="inline-grid">
                 <div className={styles.formSelectedText}>
                    {!props.multi && (activeOption ? activeOption.label ?? activeOption :
                        <span className="text-gray-500">{props.placeholder ?? props.label}</span>)
                    }

                         {props.multi && (activeOption ?
                             <div className="flex">{activeOption.map((item, index) => <Tag closeIcon key={index}
                                                                                                closable={false}
                                                                                                className="flex gap-0.5 cursor-text"
                                                                                                onClick={(e)=>e.stopPropagation()}
                                                                                                color={tagColors[generateIndexFromString(item.label ?? item, 0, tagColors.length - 1)]}>
                             <span>{item.label ?? item}</span>
                             <CloseOutlined className="hover:text-gray-800" onClick={() => onCloseTag(item)} />
                         </Tag>)}
                             </div>
                             : props.label)}


                </div>
                </div>
                {icon}
            </div>
            <div className={optionsClass}>
                {props.search &&
                    <div className={styles.searchInputContainer}>
                        <input className={`${styles.searchInputItem} ${translate("regular")}`}
                               placeholder={`${tC("select_search")} ...`} onChange={onSearchChange}
                               onKeyDown={onKeyDown}/>
                        <i className="fa-thin blue-color fa-magnifying-glass"></i>
                    </div>}
                <div className={styles.optionsItemContainer}>
                {options && options?.length!==0 ? options.map((option, index) =>(<div
                        className={optionsStyles(checkActiveOption(option))} key={index}
                        onClick={() => onOptionChange(option)}>{autoCompleteBold(search, option.label ?? option)
                        .map((element, key) => (element.isBold ? (
                            <b key={key}>{element.label}</b>) : element.label))}</div>))
                    : <div className={styles.noData}><Empty description={"Aucun donnée"}/></div>
                }
                </div>
            </div>
            <div className={styles.errorMessage}>{tER(props.errors?.[props.name]?.message)}</div>
        </div>
    )
 }
export default SelectForm;