import * as yup from "yup";
import {SUIVI_DOSSIER_PREFIX} from "../util/constants";

const frenchValidationWithoutNumbersRegex = /^[a-zA-Z\s]*$/;
const frenchValidationWithNumbersRegex = /^[a-zA-Z0-9\s]*$/;
const frenchValidationWithSpecialCaractersRegex = /^[a-zA-ZÀ-ÿ0-9\s.!@#$%^&*()-_=+[\]{}|;:'",<>/?]*$/;
const arabicValidationRegex  = /^[\u0600-\u06FF\u0750-\u077F\uFB50-\uFC3F\uFE70-\uFEFC\s]*$/;


export const SUIVI_DOSSIER_SCHEMA = yup.object().shape({
    reference: yup
        .string()
        .required("REFERENCE_REQUIRED")
        .matches( new RegExp(`^(${SUIVI_DOSSIER_PREFIX.join('|')})-([A-Za-z]{3,})-([A-Za-z]{3,})-([0-9]+)/([0-9]{4,})$`), "REFERENCE_FORMAT"),
});

export const SIGN_UP_SCHEMA = {
    STEPB: yup.object().shape({
        lastName: yup.string().required("SIGNUP_LAST_NAME_REQUIRED").matches(frenchValidationWithoutNumbersRegex,"FR_REQUIRED"),
        firstName: yup.string().required("SIGNUP_FIRST_NAME_REQUIRED").matches(frenchValidationWithoutNumbersRegex,"FR_REQUIRED"),
        cin: yup.string().required("SIGNUP_CIN_REQUIRED").matches(frenchValidationWithNumbersRegex,"SIGNUP_CIN_MATCH"),
        lastNameAr: yup.string().required("SIGNUP_LAST_NAME_AR_REQUIRED").matches(arabicValidationRegex,"AR_REQUIRED"),
        firstNameAr: yup.string().required("SIGNUP_FIRST_NAME_AR_REQUIRED").matches(arabicValidationRegex,"AR_REQUIRED"),
        civilite: yup.string().required("SIGNUP_CIVILITE_REQUIRED"),
    }),
    STEPC: yup.object().shape({
        adresse: yup.string().required("SIGNUP_ADRESSE_REQUIRED"),
        email: yup.string().email("SIGNUP_EMAIL_MATCH").required("SIGNUP_EMAIL_REQUIRED"),
        tel: yup.string().matches(/^\d{9}$/, "SIGNUP_TEL_MATCH").required("SIGNUP_TEL_REQUIRED"),
        ville: yup.string().required("SIGNUP_VILLE_REQUIRED"),
    }),
    STEPC2: yup.object().shape({
        ice: yup.string().required("SIGNUP_ICE_REQUIRED").matches(/^\d+$/, "SIGNUP_ICE_MATCH").length(15, "SIGNUP_ICE_MATCH"),
        raisonSocial: yup.string().required("SIGNUP_RAISON_SOCIAL_REQUIRED"),
        rc: yup.string().matches(/^\d+$/, "SIGNUP_RC_MATCH").required("SIGNUP_RC_REQUIRED"),
        adresse: yup.string().required("SIGNUP_ADRESSE_REQUIRED"),
        email: yup.string().email("SIGNUP_EMAIL_MATCH").required("SIGNUP_EMAIL_REQUIRED"),
        tel: yup.string().matches(/^\d{9}$/, "SIGNUP_TEL_MATCH").required("SIGNUP_TEL_REQUIRED"),
        ville: yup.string().required("SIGNUP_VILLE_REQUIRED"),
    }),
    STEPC3: yup.object().shape({
        ice: yup.string()
                 .required("SIGNUP_ICE_REQUIRED")
                 .matches(/^\d{15}$/, "SIGNUP_ICE_MATCH"),

        raisonSocial: yup.string().required("SIGNUP_RAISON_SOCIAL_REQUIRED"),
        rc: yup.string().required("SIGNUP_RC_REQUIRED"),
        patente: yup.string().required("SIGNUP_PATENTE_REQUIRED"),
        formJuridique: yup.string().required("SIGNUP_FORM_JURIDIQUE_REQUIRED"),
        fonction: yup.string().required("SIGNUP_FONCTION_REQUIRED"),
    }),
    STEPD: yup.object().shape({
        nomFacturisation: yup.string().required("SIGNUP_NOM_FACTURATION_REQUIRED"),
        adresseFacturisation: yup.string().required("SIGNUP_ADRESSE_FACTURATION_REQUIRED"),
        villePaysFacturisation: yup.string().required("SIGNUP_VILLE_PAYS_FACTURATION_REQUIRED"),
        typeCompteFacturation: yup.string().required("SIGNUP_TYPE_COMPTE_FACTURATION_REQUIRED"),
        cinFacturisation: yup.string().when('typeCompteFacturation', {
            is: value => value === 'Personne physique',
            then: schema => schema.required("SIGNUP_CIN_FACTURATION_REQUIRED"),
        }),
        rcFacturisation: yup.string().when('typeCompteFacturation', {
            is: value => value === 'Personne morale',
            then: schema => schema.matches(/^\d+$/, "SIGNUP_RC_MATCH").required("SIGNUP_RC_FACTURATION_REQUIRED"),
        }),
        ICEFacturisation: yup.string().when('typeCompteFacturation', {
            is: value => value === 'Personne morale',
            then: schema => schema
                .required("SIGNUP_ICE_FACTURATION_REQUIRED")
                .matches(/^\d+$/, "SIGNUP_ICE_MATCH")
                .length(15, "SIGNUP_ICE_MATCH"),
        }),
    }),
    STEPE: yup.object().shape({
        userName: yup.string()
            .required("SIGNUP_USERNAME_REQUIRED")
            .matches(/^\S*$/, "SIGNUP_USERNAME_NO_SPACE")
            .matches(frenchValidationWithSpecialCaractersRegex,"FR_REQUIRED")
            .matches(/^[a-zA-Z0-9.]*$/, "SIGNUP_USERNAME_NO_SPECIAL_CHAR"),
        password: yup.string()
             .required("SIGNUP_PASSWORD_REQUIRED")
             .matches(frenchValidationWithSpecialCaractersRegex,"FR_REQUIRED")
             .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,"SIGNUP_PASSWORD_MATCH"),
        password2: yup.string().oneOf([yup.ref('password'), null], "SIGNUP_PASSWORD_CONFIRMATION_REQUIRED").required("SIGNUP_PASSWORD_CONFIRMATION_REQUIRED"),
    }),
    STEPC4: yup.object().shape({
        email: yup.string().email("SIGNUP_EMAIL_MATCH").required("SIGNUP_EMAIL_REQUIRED"),
        tel: yup.string().matches(/^\d{9}$/, "SIGNUP_TEL_MATCH").required("SIGNUP_TEL_REQUIRED"),
        treatFileType: yup.array().required("SIGNUP_TREAT_FILE_TYPE_REQUIRED"),
        adresse: yup.string().required("SIGNUP_ADRESSE_REQUIRED"),
        ville: yup.string().required("SIGNUP_VILLE_REQUIRED"),
    }),
    STEPH: yup.object().shape({
        departement: yup.string().required("SIGNUP_DEPARTEMENT_REQUIRED"),
        fonction: yup.string().required("SIGNUP_FONCTION_REQUIRED"),
    }),
    STEPH2: yup.object().shape({
        organisme: yup.string().required("SIGNUP_ORGANISME_REQUIRED"),
        fonction: yup.string().required("SIGNUP_FONCTION_REQUIRED"),
    }),
    STEPI: yup.object().shape({
        procedures: yup.array()
    }),
    STEPIA: yup.object().shape({
        procedure: yup.string().required("SIGNUP_PROCEDURE_REQUIRED"),
        activities: yup.array().nullable().when('procedure', {
            is: value => !!value,
            then: schema => schema.required("SIGNUP_ACTIVITIES_REQUIRED"),
        }),
    }),
    STEPC5: yup.object().shape({
        adresse: yup.string().required("SIGNUP_ADRESSE_REQUIRED"),
        email: yup.string().email("SIGNUP_EMAIL_MATCH").required("SIGNUP_EMAIL_REQUIRED"),
        tel: yup.string().matches(/^\d{9}$/, "SIGNUP_TEL_MATCH").required("SIGNUP_TEL_REQUIRED"),
        ville: yup.string().required("SIGNUP_VILLE_REQUIRED"),
        numAutorisation: yup.string().required("SIGNUP_NUM_AUTORISATION_REQUIRED"),
        numordre: yup.string().required("SIGNUP_NUM_ORDRE_REQUIRED"),
    }),
};

export const SIGN_PASSWORD_CHANGE = yup.object().shape({
    password: yup.string()
        .required("SIGNUP_PASSWORD_REQUIRED")
        .matches(frenchValidationWithSpecialCaractersRegex,"FR_REQUIRED")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,"SIGNUP_PASSWORD_MATCH"),
    repassword: yup.string().oneOf([yup.ref('password'), null], "SIGNUP_PASSWORD_CONFIRMATION_REQUIRED").required("SIGNUP_PASSWORD_CONFIRMATION_REQUIRED"),
});
export const SIGN_PASSWORD_FORGOT = yup.object().shape({
    email: yup.string().email("SIGNUP_EMAIL_MATCH").required("SIGNUP_EMAIL_REQUIRED"),
});

