import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./css/article.module.css";
import GlobalSearch from "../../components/search/GlobalSearch";
import {
  TagList,
  TagListSkeleton,
} from "../../components/cards/tagcard/TagList";
import { Pagination } from "antd";
import {
  useGetArticleDataByCriteria,
  useGetAutoCompleteArticle,
  useGetTagsData,
} from "../../api/caller";
import {
  ArticleCardData,
  ArticleCardSkeleton,
} from "../../pagescomponents/articlecard/ArticleCard";
import { EmptySearchData } from "../../components/emptyData/EmptyData";
import { useParams } from "react-router-dom";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {scrollToTopOnMount} from "../../util/functionsUtil";

const ArticleListPage = () => {
    const  {menu}  = useParams();
    const { t: tBC } = useTranslation('breadcrumb');
    const { t: tART } = useTranslation('article');
    const {currLng,isLngFr,isLngAr}=useHandleTranslation();
    useEffect(() => {
        scrollToTopOnMount();
    }, [menu]);
  const [articleCriteria, setArticleCriteria] = useState({
    noPage: 1,
    pageSize: 8,
    search: "",
    tags: "",
    menu: menu,
    lng:"fr",
  });
  const [searchCriteria, setSearchCriteria] = useState("");
  const [autoCompletePrefix, setPrefix] = useState("");
  const getArticleData = useGetArticleDataByCriteria(articleCriteria);
  const getTagsData = useGetTagsData(menu,currLng);
  const [tags,setTags]=useState([]);
  const getAutoCompleteArticle = useGetAutoCompleteArticle(
    autoCompletePrefix,
    menu,
    currLng
  );

  const breadcrumbParts = [
    { text: tBC("Actualites"), link: "/" },
    { text: tBC("Articles"), link: "/" },
    { text: tBC(menu), link: "/" },
  ];

  useEffect(() => {
  setArticleCriteria({...articleCriteria,lng:currLng});
  }, [currLng]);
    useEffect(() => {
        if(getTagsData.isFetched) {
            if (isLngAr) setTags(["الكل",...getTagsData.data]);
            if (isLngFr) setTags(["tous",...getTagsData.data]);
        }

    }, [getTagsData.isFetched]);

  const onPaginationChange = (page, pageSize) => {
    setArticleCriteria({ ...articleCriteria, noPage: page });
  };
  const onSearchClick = () => {
    if (searchCriteria === articleCriteria.search) getArticleData.refetch();
    else {
      if (searchCriteria.length !== 0)
        setArticleCriteria({...articleCriteria, search: searchCriteria});
    }
  }
    const onSearchChange=(string)=>{
        setPrefix(string);
        setSearchCriteria(string);
        if (string.length===0) setArticleCriteria({...articleCriteria,search: ""});
    }
    const onSearchMenuClick=(string)=>{
        setSearchCriteria(string);
        if (string===articleCriteria.search) getArticleData.refetch();
        else{
            if(string.length !==0) setArticleCriteria({...articleCriteria,search:string});
        }
    }
    const onTagsChange=(tagList)=>{
        if (tagList.includes("tous") || tagList.includes("الكل")) setArticleCriteria({...articleCriteria,tags:""});
       else {setArticleCriteria({...articleCriteria,tags:tagList.join("+")});}
    }

    return (
    <div>
                 <div className="breadcrumb">
                        <BreadCrumb parts={breadcrumbParts} />
                    </div>
              <div className={styles.pageTitleContainer+" responsive-web"}>
                            <div className={styles.pageTitle}>{tBC(menu)}</div>
                            <div className={styles.pageTitleDescription}>
                                {tART("title_description")}
                            </div>
                        </div>
                    <div className={styles.pageTitleContainerMobile+" responsive-mobile"}>
                            <div className={styles.pageTitleMobile}>{tBC(menu)}</div>
                        </div>
                   <div className={styles.pageContentContainer}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <GlobalSearch onMenuClick={onSearchMenuClick} onChange={onSearchChange} onClick={onSearchClick} placeholder={tART("content_search_placeholder")}
                                              loading={getArticleData.isFetching} menu={getAutoCompleteArticle.data?.data || []} />
                                {getTagsData.isFetched && !getTagsData.isFetching && <div className="py-6"><TagList tags={tags}  onChange={onTagsChange} /></div>}
                                {getTagsData.isFetching && <TagListSkeleton />}
                            </div>
                            <div className={styles.articleContentContainer}>
                                {getArticleData.isSuccess && !getArticleData.isFetching && getArticleData.data.page?.map((article, index) => (
                                    <ArticleCardData data={article} key={index}/>
                                ))}
                                 {getArticleData.isFetching && Array.from({ length: 8 }).map((article, index) => (
                                    <ArticleCardSkeleton key={index} />))}

                            </div>
                            {(getArticleData.isError || getArticleData.data?.page?.length===0 ) && <EmptySearchData search={searchCriteria} />}
                            <div className={styles.paginationContainer}>
                                {getArticleData.isSuccess && getArticleData.data?.page.length!==0 &&
                                    <Pagination current={articleCriteria.noPage} onChange={onPaginationChange}
                                        pageSize={articleCriteria.pageSize} total={getArticleData.data?.pagination?.totalPage}
                                        showSizeChanger={false} />}
                            </div>
                        </div>

    </div>
  );
};
export default ArticleListPage;