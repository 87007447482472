import React, { Component, useState } from 'react'

export default class MenuComponent extends Component {

    constructor(props) {
        super( props );
        this.state = {
            itemSelected : ""
        }
    }
    render() {
        return (
            <div className='menu-globalcomponent'>
                {
                    this.props.menuItems.map( (menuItem, index) => <MenuItemComponent key = {index} 
                                                                                      index = {index} 
                                                                                      itemSelected = {this.state.itemSelected} 
                                                                                      handleCategorieChecked = {
                                                                                                                    (index) =>{
                                                                                                                        this.setState( { itemSelected : index }  , this.props.handleCategorieChecked( index ) )
                                                                                                                    }
                                                                                                                }
                                                                                      menuItem={menuItem}/> )
                }
            </div>
        )
    }
}

function MenuItemComponent( props ) {
    return (
        <div className='menu-categorie-globalcomponent'>
            <div className='menu-categorie-name-globalcomponent' onClick={ ()=>props.handleCategorieChecked( props.index===props.itemSelected?"":props.index ) }>{props.menuItem.categorie }</div>
            <div className='menu-categorie-list-items-globalcomponent' style={{ "display" : props.index === props.itemSelected ? "block" : "none" }}>{   props.menuItem.items  }</div>
        </div>
    )
}


