import {Viewer, Worker} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useParams} from "react-router-dom";

const PdfViewer=()=>{
    const {fileId}=useParams();
    const url=decodeURIComponent(fileId);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return(
        <div className="fixed top-0 w-full" style={{height:"100vh"}}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={url}
                    plugins={[
                        defaultLayoutPluginInstance,
                    ]}
                />
            </Worker>
        </div>
    )
}
export default PdfViewer