import React, {useState} from 'react'
import {TopGridComponent} from '../home/TopComponent'
import { Tabs } from 'antd';
import LinkAncre from '../home/LinkAncre'
import Icon from '../../components/util/Icon';
import TitleParagraph from '../home/TitleParagraph'

const Consult=(props)=> {
    const [activeKey, setActiveKey] = useState(1)
    const iconsTabs = [ "arrow-up-a-z" , "file" , "list-check" , "laptop-medical" , "book" ];
    const namesTabs = [ "Rokhas index" , "Carte des autorisations" , "Enquete publique" , "Simulation des procedures" , "Réferentiel des activités" ];
        return (
            <div className={`${props.className}`}>
                <div className='landingpage-consult'>
                    <TitleParagraph title={props.data.title} paragraph={props.data.paragraph}/>
                    <TopGridComponent data={props.data} useImg={true}/>
                </div>                

                <div className='landingpage-consult-mobile'>
                    <TitleParagraph title={props.data.title} titleColor={"#fff"} paragraph={props.data.paragraph}/>
                    <Tabs   defaultActiveKey="1" type="card" 
                            onChange={( activeKey ) => {   setActiveKey(activeKey) ;   }}
                            items={
                                new Array(props.data.children?.length).fill(null).map((_, i) => {
                                    const id = String(i + 1);
                                    return {
                                        label: <span> <Icon iconType='light' iconName={iconsTabs[i]}/><span className='actived-consult-span'>{props.data.children[i]?.title.split(":")[1]}</span>  </span>,
                                        key: id,
                                        children: <div className='consult-item-mobile'>
                                            <div>
                                                <div className='consult-item-mobile-title'>{ props.data.children[i]?.title.split(":")[0] }</div>
                                                <div className='consult-item-mobile-description'>{ props.data.children[i]?.paragraph }</div>
                                            </div>
                                            <div className={"consult-item-conatainer-part2"}>
                                                <LinkAncre data={ props.data.children[i]?.link } link={ props.data.children[i]?.__link } className="flex-center"/>
                                                <div className='consult-item-mobile-img'> <Icon iconName={props.data.children[i]?.icon} className="img-half" iconClassName="img-fixed"/> </div>
                                            </div>
                                        </div>,
                                    };
                                })
                            }
                    />
                </div>
            </div>
        )

}

export default Consult;