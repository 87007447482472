import {useState} from "react";
import {Skeleton} from 'antd';
export const TagList=(props)=>{
    const [activeIndex,setActiveIndex]=useState([0]);
    const normalTagStyle=
        {   color:props.color,
            background:props.backgroud,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius:"4px",
            padding:'8px 20px',
            cursor: "pointer",
            border: "1px solid #6B7280",
            width:"fit-content",
            height: "fit-content",
            transition: "all 0.2s ease",
        }
     const activeTagStyle={
        color: props.activeColor,
        background: props.activeBackground,
        transform:"scale(1.08)",
     }
     const containerStyle={
        display:"flex",
        alignItems: "center",
        gap:"10px",
        flexWrap:"wrap",
     }
     const style=(index)=>{
        return activeIndex.includes(index)? {...normalTagStyle,...activeTagStyle}:normalTagStyle;
     }
     const onClick=(index)=>{
        if(activeIndex.includes(index)){
            const newActiveIndex=activeIndex.filter((item)=>item!==index);
            props.onChange(props.tags.filter((tag,ind)=>newActiveIndex.includes(ind)));
            setActiveIndex(newActiveIndex);
        }
        else{
            const newActiveIndex=[...activeIndex,index]
            props.onChange(props.tags.filter((tag,ind)=>newActiveIndex.includes(ind)));
            setActiveIndex(newActiveIndex);
        }
     }
    return (<div style={containerStyle}>
        {props.tags.map((tag,index)=> (
            <div key={index} style={style(index)} onClick={()=>onClick(index)}>
            {tag}
            </div>
        ))}

    </div>);
}

export const TagListSkeleton=(props)=>{
    const containerStyle={
        display:"flex",
        height:"60px",
        alignItems: "center",
        width: "50%",
    }
    return (<div style={containerStyle}>
        {Array.from({length:6}).map((value,index)=> (
            <Skeleton.Button key={index} active={true} size="large"/>
        ))}

    </div>);
}

TagList.defaultProps={
  activeColor:"#FFFFFF",
  activeBackground:"#0369A1",
  color:"#6B7280",
  background:"%fff"
}
