import {
    LogoFr, Compass, SharedPoint, Watermill, Landmark, CompassV2, User, UserTie, Users, LogoAr
} from '../../asset/images/svgImages';
import styles from "./sign.module.css"
import globalStyles from "../../globalStyles.module.css"
import globalPagesStyles from "../globalstyles.module.css";
import SignProfileCard from "../../pagescomponents/signupcard/SignProfileCard";
import InputForm from "../../components/input/InputForm";
import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import SelectForm from "../../components/select/SelectForm";
import {Progress, Radio, Switch} from "antd";
import Btn from "../../components/button/Btn";
import ProcessCard from "../../pagescomponents/signupcard/ProcessCard";
import classNames from "classnames";
import {useForm,Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SIGN_UP_SCHEMA, SUIVI_DOSSIER_SCHEMA} from "../../holders/schemas";
import {useGetAllDepartements, useGetDynamicList, useGetSignUpProcedures, usePostSignUpData} from "../../api/caller";
import {
    CIVILITE_OPTIONS,
    DYNAMIC_LIST_PARAMS,
    SIGNUP_TYPE_AUTORIZATION_OPTIONS
} from "../../util/constants";
import InputCompleteForm from "../../components/input/InputCompleteForm";
import Modal from "../../components/modal/Modal";
import useHandleClassName from "../../hooks/useHandleClassName";
import {useTranslation} from "react-i18next";
import {toHome, toLogin, spaceTo_, scrollToTopOnMount} from "../../util/functionsUtil";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import useHandleTranstation from "../../hooks/useHandleTranslation";
import InputPhoneForm from "../../components/input/InputPhoneForm";
import SignCarousel from "../../pagescomponents/login/SignCarousel";
import LegalFooter from "../../pagescomponents/login/LegalFooter";
import Stepper from "../../pagescomponents/login/Stepper";
const FIRST_CATEGORIE=["Citoyen"];
const SECOND_CATEGORIE=["Représentant collectivité territoriale"]
const THIRD_CATEGORIE=["Membre de commission"]
const FOURTH_CATEGORIE=["Représentant personne morale"];
const FIFTH_CATEGORIE=["Représentant promoteur","Ingénieur spécialisé"];
const SIXTH_CATEGORIE=["Représentant opérateur télécom","Représentant opérateur réseaux publics"];
const SEVENTH_CATEGORIE=["Architecte","Ingénieur géomètre topographe"];

const PROFILES_CARD =()=> {
    const {t:tS}=useTranslation("signup")
    return [
    {
        title: tS("profile1_title"),
        description: tS("profile1_description"),
        value:tS("profile1_value"),
        icon: <User />
    },
    {
        title:  tS("profile2_title"),
        description: tS("profile2_description"),
        value:tS("profile2_value"),
        icon: <UserTie />
    },
    {
        title:  tS("profile3_title"),
        value:tS("profile3_value"),
        description: tS("profile3_description"),
        icon: <Compass />
    },
    {
        title: tS("profile4_title"),
        value:tS("profile4_value"),
        description: tS("profile4_description"),
        icon: <CompassV2 />
    },
        {
            title: tS("profile5_title"),
            value: tS("profile5_value"),
            description: tS("profile5_description"),
            icon: <i className="fa-solid fa-user-helmet-safety text-xl text-[#374151]"/>
        },
        {
            title: tS("profile6_title"),
            value: tS("profile6_value"),
            description: tS("profile6_description"),
            icon: <i className="fa-solid fa-helmet-safety text-xl text-[#374151]"/>
        },
        {
            title: tS("profile7_title"),
        value:tS("profile7_value"),
        description: tS("profile7_description"),
        icon: <Landmark />
    },
    {
        title:  tS("profile8_title"),
        value:tS("profile8_value"),
        description: tS("profile8_description"),
        icon: <Users />
    },
    {
        title: tS("profile9_title"),
        value:tS("profile9_value"),
        description: tS("profile9_description"),
        icon: <SharedPoint />
    },
    {
        title:  tS("profile10_title"),
        value: tS("profile10_value"),
        description: tS("profile10_description"),
        icon: <Watermill />
    }
]};
const registerForm = (schema) => {
    const { register,setValue,watch,formState, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    return { register, formState, handleSubmit,setValue,watch};
}

const StepsContext=createContext();
const SignUp=()=> {
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const {t:tS}=useTranslation("signup")
    const [isModalOpen,setModalStatus]=useState(false);
    const [modalMessage,setModalMessage]=useState(null);
    const [activeStep,setActiveStep]=useState(0);
    const [signUpFormData,setSignUpFormData]=useState({});
    const [selectedProfile,setSelectedProfile]=useState({label:"",value:"",icon:<></>});
    const [steps,setSteps]=useState([{key:"0",name:"",slide:()=><></>,props:{}}]);
    const [isSignBegin,setSignStatus]=useState(false)
    const sendSignUpData=usePostSignUpData();
    const {isLngAr,isLngFr,getDirX,getInverseDirX,currLng}=useHandleTranslation();
    const profilChooserStyles=classNames({
        "transition-all duration-700":true,
        "fixed":isSignBegin,
        [globalStyles.notDisplayV2]:isSignBegin,
        [globalStyles.show]:!isSignBegin
    })

    const signFormStyles=classNames({
        "transition-all duration-700":true,
        "fixed":!isSignBegin,
        [globalStyles.show]:isSignBegin,
        [globalStyles.notDisplayV2]:!isSignBegin
    })
    const forms={
        etapB:registerForm(SIGN_UP_SCHEMA.STEPB),
        etapC:registerForm(SIGN_UP_SCHEMA.STEPC),
        etapC2:registerForm(SIGN_UP_SCHEMA.STEPC2),
        etapC3:registerForm(SIGN_UP_SCHEMA.STEPC3),
        etapD:registerForm(SIGN_UP_SCHEMA.STEPD),
        etapE:registerForm(SIGN_UP_SCHEMA.STEPE),
        etapC4:registerForm(SIGN_UP_SCHEMA.STEPC4),
        etapH:registerForm(SIGN_UP_SCHEMA.STEPH),
        etapH2:registerForm(SIGN_UP_SCHEMA.STEPH2),
        etapI:registerForm(SIGN_UP_SCHEMA.STEPI),
        etapC5:registerForm(SIGN_UP_SCHEMA.STEPC5),
    }
    const [stepsOptions,setStepsOptions]=useState(
        {
            etapB:{display:true,skip:false},
            etapC:{display:true,skip:false},
            etapC2:{display:true,skip:false},
            etapC3:{display:false,skip:false},
            etapD:{display:true,skip:false},
            etapE:{display:true,skip:false},
            etapF:{display:true,skip:false},
            etapC4:{display:true,skip:false},
            etapH:{display:true,skip:false},
            etapH2:{display:true,skip:false},
            etapI:{display:true,skip:true},
            etapC5:{display:true,skip:false}});

    const judgeOnStep=(suffix,bool)=>{
        setStepsOptions({...stepsOptions,[`etap${suffix}`]:{display: bool,skip: false }});
    }
    const PROFILES_STEPS= {
        first: [
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 1, name: "etapC", slide: StepC,props:{}},
            {key: 2, name: "etapD", slide: StepD,props:{}},
            {key: 3, name: "etapE", slide: StepE,props:{}},
            {key: 4, name: "etapF", slide: StepF,props:{}}
            ],
        second: [
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 2, name: "etapC4", slide: StepC4,props:{}},
            {key: 1, name: "etapH", slide: StepH,props:{}},
            {key: 3, name: "etapI", slide: StepI,props:{}},
            {key: 4, name: "etapE", slide: StepE,props:{}},
            {key: 5, name: "etapF", slide: StepF,props:{}}],
        third: [
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 2, name: "etapC4", slide: StepC4,props:{}},
            {key: 1, name: "etapH2", slide: StepH2,props:{}},
            {key: 3, name: "etapI", slide: StepI,props:{}},
            {key: 4, name: "etapE", slide: StepE,props:{}},
            {key: 5, name: "etapF", slide: StepF,props:{}}
        ],
        fourth:[
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 1, name: "etapC2", slide: StepC2,props:{}},
            {key: 2, name: "etapD", slide: StepD,props:{}},
            {key: 3, name: "etapE", slide: StepE,props:{}},
            {key: 4, name: "etapF", slide: StepF,props:{}}],
        fifth:[
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 1, name: "etapC", slide: StepC,props:{organizedCompany:true}},
            {key: 1, name: "etapC3", slide: StepC3,props:{}},
            {key: 2, name: "etapD", slide: StepD,props:{}},
            {key: 3, name: "etapE", slide: StepE,props:{}},
            {key: 4, name: "etapF", slide: StepF,props:{}}],
        sixth: [
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 1, name: "etapH2", slide: StepH2,props:{}},
            {key: 2, name: "etapC", slide: StepC,props:{}},
            {key: 3, name: "etapD", slide: StepD,props:{}},
            {key: 4, name: "etapE", slide: StepE,props:{}},
            {key: 5, name: "etapF", slide: StepF,props:{}}],
        seventh:[
            {key: 0, name: "etapB", slide: StepB,props:{}},
            {key: 1, name: "etapC5", slide: StepC5,props:{organizedCompany:true}},
            {key: 1, name: "etapC3", slide: StepC3,props:{}},
            {key: 2, name: "etapD", slide: StepD,props:{}},
            {key: 3, name: "etapE", slide: StepE,props:{}},
            {key: 4, name: "etapF", slide: StepF,props:{}}],
    }


    const toNextSlide=()=>{
        if(activeStep<steps.length-1) setActiveStep(activeStep+1);
    }
    const handleSumbit=(data)=>{
       setSignUpFormData({...signUpFormData,...data});
        toNextSlide();
    }
    const handleValidateLastStep=(data)=>{
        setSignUpFormData({...signUpFormData,...data});
        sendSignUpData.mutate({data:{...signUpFormData,...data},lng:currLng});

    }
    useEffect(()=>{
        if(sendSignUpData.customHandler.isErrorMessageExist) {
            setModalStatus(true);
            setModalMessage(sendSignUpData.customHandler.errorMessage);
        }
    },[sendSignUpData.customHandler.isErrorMessageExist])

    useEffect(()=>{
        if(sendSignUpData.customHandler.isSuccess) toNextSlide();
    },[sendSignUpData.customHandler.isSuccess])
    const onError=(error)=>{
        console.log(forms[steps[activeStep].name].formState.errors);
    }
    const onNextBtnClick=(handler)=>{
    (forms[steps.filter(item=>(stepsOptions[item.name]).display)[activeStep].name]).handleSubmit(handler,onError)();
    }
    const onPrevBtnClick=()=>{
        if(activeStep>0) setActiveStep(activeStep-1);
        else if(activeStep===0){
            setSignStatus(false);
            setSelectedProfile({label:"",value:"",icon:<></>})
        }
    }
    const isLastStep=(steps.filter((item)=>(stepsOptions[item.name])?.display).length-2===activeStep);
    const isOverLastStep=(steps.filter((item)=>(stepsOptions[item.name])?.display).length-2<activeStep);

    const onProfileClick=(profile)=>{
        setSelectedProfile(profile);
        setSignUpFormData({})
        if(FIRST_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.first);
        else if(SECOND_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.second);
        else if(THIRD_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.third);
        else if(FOURTH_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.fourth);
        else if(FIFTH_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.fifth);
        else if(SIXTH_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.sixth);
        else if(SEVENTH_CATEGORIE.includes(profile.value)) setSteps(PROFILES_STEPS.seventh);
        setSignStatus(true);
        forms.etapB.setValue("profile",profile.value);
    }

    const pageContentContainerStyles=classNames({
    [styles.pageContentContainerAr]:isLngAr,
    [styles.pageContentContainerFr]:isLngFr,
    [styles.pageContentContainer]:true
    })
    return (<div className="flex w-full gap-2">
        <div className={`${styles.carouselContainer} ${globalPagesStyles.responsiveWeb}`}>
        <SignCarousel/>
        </div>
        <div className={pageContentContainerStyles}>
            <div className="px-4">
                <div className={styles.logoContainer}>
                    <div className={styles.selectedProfile}>
                        {selectedProfile?.icon}
                        {selectedProfile?.label}
                    </div>

                    <a href='/'>
                        {isLngAr ? <LogoAr/> : <LogoFr/>}
                    </a>
                </div>
                <div className={profilChooserStyles}>
                    <div>
                    <p className={styles.pageTitle}>{tS("profiles_title")}</p>
                        <p className={styles.pageTitleDescription}>{tS("profiles_description")}</p>
                    </div>
                    <div className={`${styles.profileChooserContainer} ${styles.gridDispaly}`}>
                        {PROFILES_CARD().map((profile, index) => (
                            <SignProfileCard key={index}
                                             title={profile.title}
                                             description={profile.description}
                                             value={profile.value}
                                             icon={profile.icon}
                                             onClick={onProfileClick}
                            />
                        ))
                        }
                    </div>
                </div>
                <div className={signFormStyles}>
                    <StepsContext.Provider value={{judgeOnStep,selectedProfile, stepsOptions}}>
                        <Stepper active={activeStep} steps={steps} validator={forms} displayed={stepsOptions}/>
                    </StepsContext.Provider>
                </div>
                {isSignBegin && !isOverLastStep && <div className="flex flex-col gap-6">
                    <div className="flex justify-around items-center">
                        <i className={`fa-solid fa-arrow-${getDirX()}-long text-gray-300 text-2xl hover:scale-125 transition-all !text-primary cursor-pointer`}
                           onClick={onPrevBtnClick}/>

                        <div className="flex w-fit items-center py-2 gap-8">
                            {stepsOptions[steps[activeStep].name]?.skip &&
                                <div className={styles.skipButton} onClick={toNextSlide}>{tS("skip_link")}</div>}
                            {!isLastStep &&
                                <SignUpBtn label={tS("next_button")} iconName={getInverseDirX()} paddingX={25}
                                           paddingY={10} onClick={() => onNextBtnClick(handleSumbit)}/>}
                            {isLastStep && <SignUpBtn label={tS("valid_button")} paddingX={25} paddingY={10}
                                                      isLoading={sendSignUpData.isLoading}
                                                      disabled={sendSignUpData.isLoading} iconName="check" loading
                                                      onClick={() => onNextBtnClick(handleValidateLastStep)}/>}
                        </div>
                    </div>
                    <Progres step={activeStep + 1}
                             steps={steps.filter((item) => stepsOptions[item.name]?.display).length - 1}/>
                </div>
                }
            </div>
            <LegalFooter/>
        </div>
        <SignUpErrorModal isOpen={isModalOpen} message={modalMessage} handleClose={()=>setModalStatus(false)}/>
    </div>)
}

const StepB = ({validate}) => {
    const {t:tS}=useTranslation("signup");
    const {t:tSO}=useTranslation("options");
    const {isLngFr,otherLng,translateClassName}=useHandleTranslation();
    const [civiliteOptions,]=useState(CIVILITE_OPTIONS.map((item)=>({value:item.value,label:tSO(item.label)})));
    const {getInverseDivDir,currDir}=useHandleTranstation();
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepB_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepB_description")}</p>
            </div>
            <div className={`${styles.gridDispaly} gap-x-2 py-1`}>
                <SelectForm label={tS("stepB_input1_label")}
                            options={civiliteOptions}
                            setter={validate?.setValue}
                            errors={validate?.formState?.errors}
                            name={"civilite"}
                            important
                />
                <InputForm register={validate.register} name={"cin"} label={tS("stepB_input2_label")} placeholder={tS("stepB_input2_label")} errors={validate?.formState?.errors} important/>
                <InputForm register={validate.register} name={isLngFr?"lastName":"lastNameAr"} label={tS("stepB_input3_label")} placeholder={tS("stepB_input3_label")} errors={validate?.formState?.errors} important/>
                <InputForm register={validate.register} name={isLngFr?"firstName":"firstNameAr"} label={tS("stepB_input4_label")} placeholder={tS("stepB_input4_label")} errors={validate?.formState?.errors}  important/>
            </div>
            <p className={styles.pageTitleDescription}>{tS("stepB_title2")}</p>
            <div className={`${styles.gridDispaly} gap-x-2`}>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={isLngFr?"firstNameAr":"firstName"} label={tS("stepB_input5_label")} rootClassName={globalStyles[translateClassName("regular",otherLng)]} placeholder={tS("stepB_input5_label")} dir={getInverseDivDir()}  important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={isLngFr?"lastNameAr":"lastName"} label={tS("stepB_input6_label")} rootClassName={globalStyles[translateClassName("regular",otherLng)]} placeholder={tS("stepB_input6_label")} dir={getInverseDivDir()}   important/>
            </div>
        </div>
    )
}
const StepC = ({validate,organizedCompany,architect}) => {
    const {t:tS}=useTranslation("signup")
    const {currLng}=useHandleTranslation();
    const getCities=useGetDynamicList(DYNAMIC_LIST_PARAMS.CITIES_LIST[currLng]);
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepC_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepC_description")}</p>
            </div>
            <div className={`${styles.gridDispaly} gap-x-2 py-8`}>
                <SelectForm label={tS("stepC_input1_label")}
                            options={getCities.data}
                            loading={getCities.isFetching}
                            setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"ville"}
                            search
                            important
                />
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"adresse"}
                           label={tS("stepC_input2_label")} placeholder={tS("stepC_input2_label")} important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"email"}
                           label={tS("stepC_input3_label")} placeholder={tS("stepC_input3_label")} important/>
                <InputPhoneForm register={validate.register} setter={validate.setValue} errors={validate?.formState?.errors} name={"tel"}
                           label={tS("stepC_input4_label")} placeholder={tS("stepC_input4_label")} inputDir={"ltr"} important/>
            </div>
            <div className="flex gap-4 px-4">
                <StepsContext.Consumer>
                    {({judgeOnStep, stepsOptions}) =>
                        <>
                    {organizedCompany && (validate.setValue("companyOrganisation",stepsOptions.etapC3.display) || <div className="flex gap-3  items-center">
                        <span className={styles.radioFieldStepD}>{tS("stepC_input6_label")}</span>
                        <Switch checked={stepsOptions.etapC3.display} onChange={(bool) =>{judgeOnStep("C3",bool); validate.setValue("companyOrganisation",bool);}}/>
                    </div>)}
                        <div className="flex gap-3 items-center">
                            <span
                                className={styles.radioFieldStepD}>{tS("stepC_input5_label")}</span>
                            <Switch checked={stepsOptions.etapD.display} onChange={(bool) => {
                                judgeOnStep("D",bool);
                                validate.setValue("skipFact", !bool);
                            }}/>
                        </div>
                   </>
                    }
                </StepsContext.Consumer>
            </div>
        </div>
    )
}
const StepC2 = ({validate}) => {
    const {t: tS} = useTranslation("signup")
    const {currLng}=useHandleTranslation();
    const getCities=useGetDynamicList(DYNAMIC_LIST_PARAMS.CITIES_LIST[currLng]);
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepC_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepC_description")}</p>
            </div>
            <div className={`${styles.gridDispaly} gap-x-2 pt-2`}>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"raisonSocial"}
                           label={tS("stepC2_input1_label")} placeholder={"Raison sociale"} important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"rc"}
                           label={tS("stepC2_input2_label")} placeholder={tS("stepC2_input2_label")} important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"ice"} label={tS("stepC2_input3_label")} placeholder={tS("stepC2_input3_label")} important/>
                <SelectForm label={tS("stepC2_input4_label")}
                            options={getCities.data}
                            loading={getCities.isFetching}
                            setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"ville"}
                            search
                            important
                />
            </div>
            <InputForm register={validate.register} errors={validate?.formState?.errors} name={"adresse"}  label={tS("stepC2_input5_label")} placeholder={tS("stepC2_input5_label")} important/>
            <div className="grid grid-cols-2 gap-x-2 pb-8">
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"email"} label={tS("stepC2_input6_label")} placeholder={tS("stepC2_input6_label")} important/>
                <InputPhoneForm register={validate.register} setter={validate.setValue} errors={validate?.formState?.errors} name={"tel"}
                                label={tS("stepC2_input7_label")} placeholder={tS("stepC2_input7_label")} important/>
            </div>
            <StepsContext.Consumer>
                {({judgeOnStep,stepsOptions}) =>
                    <div className="flex gap-6 items-center">
                            <span
                                className={styles.radioFieldStepD}>{tS("stepC2_input8_label")}</span>
                        <Switch checked={stepsOptions.etapD.display} onChange={(bool) => {judgeOnStep("D",bool);validate.setValue("skipFact",!bool);}}/>
                    </div>
                }
            </StepsContext.Consumer>
        </div>
    )
}
const StepC3 = ({validate}) => {
    const {t:tS}=useTranslation("signup")
    const {currLng}=useHandleTranslation();
    const getfonctions=useGetDynamicList(DYNAMIC_LIST_PARAMS.FONCTION_LIST[currLng]);
    const [formeJuridiqueOptions,]=useState(["S.A","S.A.R.L","Amicale","Association","S.C.I"])

    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepC_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepC_description")}</p>
            </div>

            <div className={`${styles.gridDispaly} gap-x-2 pt-8`}>
                <SelectForm       setter={validate.setValue}
                                  errors={validate?.formState?.errors}
                                  name={"formJuridique"}
                                  label={tS("stepC3_input5_label")}
                                  loading={getfonctions.isFetching}
                                  options={formeJuridiqueOptions}
                />

                <SelectForm   setter={validate.setValue}
                              errors={validate?.formState?.errors}
                              name={"fonction"}
                              label={tS("stepC3_input6_label")}
                              loading={getfonctions.isFetching}
                              options={getfonctions.data}
                />
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"raisonSocial"}
                           label={tS("stepC3_input1_label")} placeholder={tS("stepC3_input1_label")} important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"rc"} label={tS("stepC3_input2_label")}
                           placeholder={tS("stepC3_input2_label")} important/>

                <InputForm name={"ice"} errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepC3_input3_label")} placeholder={tS("stepC3_input3_label")} important/>

                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"patente"} label={"Patente"}
                           placeholder={tS("stepC3_input4_label")} important/>
            </div>
        </div>
    )
}
const StepC4=({validate})=>{
    const {t:tS}=useTranslation("signup")
    const {t:tSO}=useTranslation("options")
    const [typeTraitement,setTraitementType]=useState(SIGNUP_TYPE_AUTORIZATION_OPTIONS.map(item=>({label:tSO(item.label),value:item.value})));
    const {currLng}=useHandleTranslation();
    const getCities=useGetDynamicList(DYNAMIC_LIST_PARAMS.CITIES_LIST[currLng]);
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepC_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepC_description")}</p>
            </div>
            <div className={`${styles.gridDispaly} gap-x-2 py-12 `}>
                <SelectForm   setter={validate.setValue}
                              errors={validate?.formState?.errors}
                              name={"treatFileType"}
                              label={tS("stepC4_input1_label")}
                              options={typeTraitement}
                              multi
                              important
                />
                <SelectForm label={tS("stepC4_input2_label")}
                            options={getCities.data}
                            loading={getCities.isFetching}
                            setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"ville"}
                            search
                            important
                />
                <InputForm  register={validate.register} errors={validate?.formState?.errors} name={"email"}  label={tS("stepC4_input3_label")} placeholder={tS("stepC4_input3_label")} important/>
                <InputPhoneForm register={validate.register} setter={validate.setValue} errors={validate?.formState?.errors} name={"tel"}
                                label={tS("stepC4_input4_label")} placeholder={tS("stepC4_input4_label")} important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"adresse"}  label={tS("stepC4_input5_label")} placeholder={tS("stepC4_input5_label")} important/>
            </div>
        </div>
    )
}
const StepC5 = ({validate,organizedCompany}) => {
    const {t:tS}=useTranslation("signup")
    const {currLng}=useHandleTranslation();
    const {selectedProfile}=useContext(StepsContext);
    const getCities=useGetDynamicList(DYNAMIC_LIST_PARAMS.CITIES_LIST[currLng]);
    const getOrders=useGetDynamicList((DYNAMIC_LIST_PARAMS.ORDERS_LIST?.[spaceTo_(selectedProfile.value)])?.[currLng]);
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepC_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepC_description")}</p>
            </div>
            <div className={`${styles.gridDispaly} gap-x-2 py-8`}>
                <SelectForm label={tS("stepC5_input1_label")}
                            options={getCities.data}
                            loading={getCities.isFetching}
                            setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"ville"}
                            search
                            important
                />
                <SelectForm label={tS("stepC5_input8_label")}
                            options={getOrders.data}
                            loading={getOrders.isFetching}
                            setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"numordre"}
                            search
                            important
                />
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"adresse"}
                           label={tS("stepC5_input2_label")} placeholder={tS("stepC5_input2_label")} important/>
                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"numAutorisation"}
                           label={tS("stepC5_input7_label")} placeholder={tS("stepC5_input2_label")} important/>

                <InputForm register={validate.register} errors={validate?.formState?.errors} name={"email"}
                           label={tS("stepC5_input3_label")} placeholder={tS("stepC5_input3_label")} important/>
                <InputPhoneForm register={validate.register} setter={validate.setValue} errors={validate?.formState?.errors} name={"tel"}
                                label={tS("stepC5_input4_label")} placeholder={tS("stepC5_input4_label")} important/>
            </div>
            <div className="flex gap-4 px-4">
                <StepsContext.Consumer>
                    {({judgeOnStep, stepsOptions}) =>
                        <>
                            {organizedCompany && (validate.setValue("companyOrganisation",stepsOptions.etapC3.display) || <div className="flex gap-3  items-center">
                                <span className={styles.radioFieldStepD}>{tS("stepC5_input6_label")}</span>
                                <Switch checked={stepsOptions.etapC3.display} onChange={(bool) =>{judgeOnStep("C3",bool); validate.setValue("companyOrganisation",bool);}}/>
                            </div>)}
                            <div className="flex gap-3 items-center">
                            <span
                                className={styles.radioFieldStepD}>{tS("stepC5_input5_label")}</span>
                                <Switch checked={stepsOptions.etapD.display} onChange={(bool) => {
                                    judgeOnStep("D",bool);
                                    validate.setValue("skipFact", !bool);
                                }}/>
                            </div>
                        </>
                    }
                </StepsContext.Consumer>
            </div>
        </div>
    )
}
const StepD = ({validate}) => {
    const {t:tS}=useTranslation("signup")
    const [selectedPersonne, setSelectedPersonne] = useState("Personne physique");
    const onChangeStatusPersonnel = (e) => {
        setSelectedPersonne(e.target.value);

    }
    useEffect(() => {
        if (selectedPersonne === "Personne physique") {
            validate.setValue("rcFacturisation", undefined);
            validate.setValue("ICEFacturisation", undefined);
        } else if (selectedPersonne === "Personne morale") validate.setValue("cinFacturisation", undefined);
        validate.setValue("typeCompteFacturation", selectedPersonne);
    }, [selectedPersonne])
   const personneMoraleInputsStyles=classNames({
       [styles.formTwoContainerStepD]:true,
       [globalStyles.notDisplayV2]:selectedPersonne==="Personne physique"
   });
    const personneMoraleInputsContainerStyles=classNames({
        "transition-all duration-800 max-h-64":true,
        ["!max-h-0"]:selectedPersonne==="Personne physique"
    });
    const personnePhysiqueInputsStyles=classNames({
        "transition-all duration-1000":true,
        [globalStyles.notDisplayV2]:selectedPersonne==="Personne morale",
    });

    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepD_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepD_description")}</p>
            </div>

            <div className={styles.formContainerStepD}>
                <span className={styles.radioFieldStepD}>{tS("stepD_input5_label")}</span>
                <Radio.Group defaultValue="Personne physique" buttonStyle="solid" onChange={onChangeStatusPersonnel}>
                    <Radio.Button value="Personne physique">{tS("stepD_input5_label2")}</Radio.Button>
                    <Radio.Button value="Personne morale">{tS("stepD_input5_label3")}</Radio.Button>
                </Radio.Group>
            </div>
            <div className={`${styles.gridDispaly} gap-x-2`}>
                <InputForm name={"nomFacturisation"} errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepD_input1_label")}
                           placeholder={tS("stepD_input1_label")}
                           important/>
                <InputForm name={"villePaysFacturisation"} errors={validate?.formState?.errors}
                           register={validate.register} label={tS("stepD_input2_label")}
                           placeholder={tS("stepD_input2_label")}
                           important/>
            </div>
            <div className={personneMoraleInputsContainerStyles}>
            <div className={personneMoraleInputsStyles}>
                <InputForm name={"ICEFacturisation"}
                           errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepD_input7_label")} placeholder={tS("stepD_input7_label")} important/>
                <InputForm name={"rcFacturisation"}
                           errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepD_input6_label")} placeholder={tS("stepD_input6_label")} important/>
            </div>
            </div>
            <div  className={`${styles.gridDispaly} gap-x-2`}>
                <InputForm name={"adresseFacturisation"} errors={validate?.formState?.errors}
                           register={validate.register}
                           label={tS("stepD_input4_label")} placeholder={tS("stepD_input4_label")} important/>
                <InputForm name={"cinFacturisation"}
                           rootClassName={personnePhysiqueInputsStyles}
                           errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepD_input3_label")} placeholder={tS("stepD_input3_label")} important/>

            </div>

        </div>
    )
}
const StepE = ({validate}) => {
    const {t: tS} = useTranslation("signup")
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepE_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepE_description")}</p>
            </div>
            <div className={styles.formContainerStepE}>
                <InputForm name={"userName"} errors={validate?.formState?.errors} inputDir="ltr" suffix="@Rokhas.ma"
                           register={validate.register} label={tS("stepE_input1_label")} placeholder={tS("stepE_input1_label")}
                           important/>
                <InputForm name={"password"} errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepE_input2_label")} placeholder={tS("stepE_input2_label")} important password
                           cannotPaste/>
                <InputForm name={"password2"} errors={validate?.formState?.errors} register={validate.register}
                           label={tS("stepE_input3_label")} placeholder={tS("stepE_input3_label")} important password
                           cannotPaste/>
            </div>

        </div>
    )
}
const StepF = ({validate}) => {
    const {t: tS} = useTranslation("signup")
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepF_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepF_description")}</p>
            </div>
            <div className="mx-auto flex flex-col items-center justify-center gap-10 pt-24">
                <i className="fa-regular fa-square-check !text-primary text-7xl"></i>
                <div className="flex flex-col items-center gap-3">
                    <span className={styles.pageSignUpMessage}>{tS("stepF_description2_1")}</span>
                    <span className={styles.pageSignUpMessage}>{tS("stepF_description2_2")}</span>

                </div>
                <SignUpBtn label={tS("login_button")} onClick={()=>toLogin()} paddingX={100} paddingY={12}/>
                <a className={styles.signLink} onClick={()=>toHome()}>{tS("return_button")}</a>
            </div>

        </div>
    )
}

const StepH=({validate,validators})=>{
    const {t:tS}=useTranslation("signup")
    const [departementInput,setDeparetementInput]=useState("");
    const {currLng,getDirX}=useHandleTranslation();
    const [domaine,setDomaine]=useState("");
    const getDeparetments=useGetAllDepartements("fr","departement",validators.etapC4.watch("treatFileType"),departementInput);
    const getfonctions=useGetDynamicList(DYNAMIC_LIST_PARAMS.FONCTION_LIST[currLng]);
    const onChangeDepartements=(search)=>{
        setDeparetementInput(search);
    }
    const onMenuClickDepartment=(value)=>{
        validate.setValue("departement",value);
        validate.setValue("departementCode",(getDeparetments?.data?.page?.filter(item=>item.name===value))[0]?.id)
    }
    useEffect(()=>{
        if (validators.etapC4.watch("treatFileType")) setDomaine(validators.etapC4.watch("treatFileType")[0]);
    },[validators])
    return (
        <div className="w-11/12 h-full flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepH_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepH_description")}</p>
            </div>
            <div className={styles.formContainerStepH}>

                <InputCompleteForm loading={getDeparetments.isFetching}
                                   menu={getDeparetments?.data?.page?.map(item => item.name)}
                                   onChange={onChangeDepartements} errors={validate?.formState?.errors}
                                   name={"departement"} label={tS("stepH_input1_label")}
                                   onMenuClick={onMenuClickDepartment}
                                   inputDir={getDirX()}
                                   placeholder={tS("stepH_input1_label")}
                                   info={tS("stepH_input1_info")}
                                   important/>
                <SelectForm setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"fonction"}
                            label={tS("stepH_input2_label")}
                            loading={getfonctions.isFetching}
                            options={getfonctions.data}
                            important
                />
            </div>
        </div>
    )
}
const StepH2 = ({validate,validators})=>{
    const {t:tS}=useTranslation("signup")
    const {currLng}=useHandleTranslation();
    const [departementInput,setDeparetementInput]=useState("");
    const getDeparetments=useGetAllDepartements("fr","organisme",validators.etapC4.watch("treatFileType"),departementInput);
    const getfonctions=useGetDynamicList(DYNAMIC_LIST_PARAMS.FONCTION_LIST[currLng]);
    const onChangeDepartements=(search)=>{
        setDeparetementInput(search);
    }
    const onMenuClickDepartment=(value)=>{
       validate.setValue("organisme",value);
        validate.setValue("organismeCode",(getDeparetments?.data?.page?.filter(item=>item.name===value))[0]?.id)
    }
    return (
        <div className="w-11/12 flex flex-col gap-2 h-full">
            <div>
                <p className={styles.pageTitle}>{tS("stepH_title")}</p>
                <p className={styles.pageTitleDescription}>{tS("stepH_description")}</p>
            </div>
            <div className={styles.formContainerStepH}>

                <InputCompleteForm loading={getDeparetments.isFetching}
                                   menu={getDeparetments?.data?.page?.map(item => item.name)}
                                   name={"organisme"}
                                   inputDir={"ltr"}
                                   onChange={onChangeDepartements} errors={validate?.formState?.errors}
                                   onMenuClick={onMenuClickDepartment}
                                   label={tS("stepH2_input1_label")}
                                   placeholder={tS("stepH2_input1_label")}
                                   info={tS("stepH2_input1_info")}
                                   important/>
                <SelectForm setter={validate.setValue}
                            errors={validate?.formState?.errors}
                            name={"fonction"}
                            label={tS("stepH2_input2_label")}
                            loading={getfonctions.isFetching}
                            options={getfonctions.data}
                            important
                />
            </div>
        </div>
    )
}
const StepI = ({validate,validators})=>{
    const {t:tS}=useTranslation("signup")
    const {t:tSO}=useTranslation("options")
    const [showForm,setShowForm]=useState(false);
    const getProcedures=useGetSignUpProcedures("fr",validators.etapB.watch("profile"));
    const {setValue, control,formState, handleSubmit } = useForm({
        resolver: yupResolver(SIGN_UP_SCHEMA.STEPIA),
    });
    const [selectedProcedure,setSelectedProcedure]=useState(null);
    const [procedureOptions,setProcedureOptions]=useState([])
    const [activitiesOptions,setActivitiesOptions]=useState([])
    const [formData,setFormData]=useState([]);
    const [transitionEvents,setTransitionEvents]=useState(false);
    const [toBeDeletedIndex,setDeleteIndex]=useState(null);
    const [zigzagIndex,setZigZagIndex]=useState(null);
    const processContainerRef=useRef(null);
    const formContainerStyle=classNames({
        [styles.formItemsContainer]:true,
        [globalStyles.notShow]:!showForm,
        [styles.formItemsContainerAppear]:showForm,
    })

    const plusIconStyle=classNames({
        "fa-solid fa-plus fixed transition-all duration-500 ":true,
        [globalStyles.notShow]:showForm,
        [globalStyles.show]:!showForm,
    })
    const minusIconStyle=classNames({
        "fa-solid fa-minus fixed transition-all duration-500":true,
        [globalStyles.notShow]:!showForm,
        [globalStyles.show]:showForm,
    })
    const procedureEventStyle=classNames({
        [globalStyles.notDisplayV2]:!transitionEvents,
        [`${globalStyles.show} ${styles.procedureEventAppear}`]:transitionEvents,
    })
    const animationProcedureStyle=(index)=>classNames({
        "transition-all duration-500":toBeDeletedIndex,
        ["-translate-x-full"]:toBeDeletedIndex && index===toBeDeletedIndex,
        ["-translate-y-[6.6rem]"]:toBeDeletedIndex &&  index>toBeDeletedIndex,
        [globalStyles.animateZigZag]:index===zigzagIndex
    })
    const onChangeProcedures=(procedure)=>{
     setSelectedProcedure(procedure);
    }

    const onAddFormData=(data)=>{
     const procedure = getProcedures.data.data.filter(item => item.value === selectedProcedure)[0];
        const procedureForm = {
            procedure:{
                label: procedure.label,
                value: procedure.value,
            },
            options: {
                option: procedure.options.map(item => ({
                    label: item.label,
                    value: item.value
                }))
            },
            activities: {
                activitie: procedure.options.filter(item => data.activities.includes(item.value)).map(item => ({
                    label: item.label,
                    value: item.value
                }))
            }
        }

        const checkIndex=formData.findIndex(item=>item.procedure?.label===procedureForm.procedure?.label);
        if(checkIndex>=0) {
            const child=processContainerRef.current.children[checkIndex];
            child.scrollIntoView({ behavior: 'smooth',  block: 'start', inline:"nearest"});
            setZigZagIndex(checkIndex+1);
        }
        else{
            setTransitionEvents(false);
            const newFormData=[procedureForm,...formData];
            validate.setValue("procedures",newFormData);
            setFormData(newFormData);
        }

    }
    const mapProcedureOptions=(object)=>{
        const encodedLabel=encodeURIComponent(object.label);
        return {label:tSO(encodedLabel),value:object.value};
    }
    const mapProcedureLabel=(label)=>{
        const encodedLabel=encodeURIComponent(label);
        return tSO(encodedLabel);
    }
    const onDeleteProcedure=(procIndex)=>{
        setDeleteIndex(procIndex);
    }
    const deleteProcedure=()=>{
        if (!toBeDeletedIndex) return;
        const newFormData=formData.filter((item,index)=>index!==toBeDeletedIndex-1);
        setFormData(newFormData)
        validate.setValue("procedures",newFormData);
        setDeleteIndex(null);
    }
    const initZigZagIndex=()=>{
        setZigZagIndex(null);
    }

    useEffect(()=>{
        if(getProcedures.isFetched){
            setProcedureOptions(getProcedures.data?.data?.map(item=>mapProcedureOptions(item)));
        }
    },[getProcedures.isFetched])
    useEffect(()=>{
        if(selectedProcedure){
            setActivitiesOptions(getProcedures.data?.data.filter((item) => item.value === selectedProcedure)[0]?.options?.map(item=>mapProcedureOptions(item)));
        }
    },[selectedProcedure])

    useEffect(()=>{
        if (!transitionEvents) setTransitionEvents(true);
    },[formData])

   return (
       <div className="w-11/12 flex flex-col gap-2 h-full">
           <div>
               <p className={styles.pageTitle}>{tS("stepI_title")}</p>
               <p className={styles.pageTitleDescription}>{tS("stepI_description")}</p>
           </div>
           <div>
               <div className={styles.stepProcessTitle} onClick={() => setShowForm(!showForm)}>
                   <div>
                       <i className={plusIconStyle}/>
                       <i className={minusIconStyle}/>
                   </div>
                   <span>{tS("stepI_input3_label")}</span>
               </div>
               <div>

               </div>
               <div className={formContainerStyle}>
                   <SelectForm
                               setter={setValue}
                               errors={formState?.errors}
                               name={"procedure"}
                               label={tS("stepI_input1_label")}
                               loading={getProcedures.isFetching}
                               onChange={onChangeProcedures}
                               options={procedureOptions}
                               search
                   />
                   <SelectForm
                               setter={setValue}
                               errors={formState?.errors}
                               name={"activities"}
                               label={tS("stepI_input2_label")}
                               options={activitiesOptions}
                               // onChange={onChangeActivities}
                               multi search
                   />
                   <div
                       className="flex justify-center items-center gap-1 text-md hover:!text-gray-300 rounded-md !text-white bg-primary w-fit  px-4 py-4 mb-2 cursor-pointer hover:shadow-sm  transition-colors duration-300 mx-auto"
                       onClick={handleSubmit(onAddFormData,(errors)=>console.log(errors))}>
                           <i className="fa-solid fa-plus"/>
                           <span className="!text-sm">{tS("stepI_button_label")}</span>
                   </div>
               </div>
               <div className={styles.stepProcessListTitle}>{tS("stepI_label1")} </div>
               <div className={styles.stepProcessContainer}>
                   <div ref={processContainerRef} className={styles.stepProcessItemContainer}>
                        <div className={procedureEventStyle}>
                            {formData.length!==0 &&
                                <div className={animationProcedureStyle(1)} onTransitionEnd={deleteProcedure} onAnimationEnd={initZigZagIndex}>
                                    <ProcessCard key={1} title={mapProcedureLabel(formData[0]?.procedure?.label)}
                                                 description={formData[0]?.activities?.activitie?.map(item => mapProcedureLabel(item.label))}
                                                 onDelete={() => onDeleteProcedure(1)}/>
                                </div>
                            }

                                </div>
                            {formData.slice(1).map(
                                (item, index) =>
                               <div className={animationProcedureStyle(index+2)} onTransitionEnd={()=>deleteProcedure()} onAnimationEnd={initZigZagIndex}>
                               <ProcessCard               key={index+2}
                                                          title={mapProcedureLabel(item.procedure.label)}
                                                          description={item.activities?.activitie?.map(item =>mapProcedureLabel(item.label))}
                                                          onDelete={() => onDeleteProcedure(index+2)}/>
                              </div>
                       )}
                   </div>
               </div>


           </div>
       </div>
   )
}

const Progres = ({step = 0, steps = 1}) => {
    return (
        <div className="w-full py-1">
            <div className="w-2/3 mx-auto">
                    <Progress  percent={(step / steps) * 100} showInfo={false} status="active"/>
            </div>
            <div className={styles.progressText}>
                <span>{step}</span>
                <span>/</span>
                <span>{steps}</span>
            </div>
        </div>
    )
}

const SignUpBtn=({label,disabled=false,iconName,color="#ffffff",backgroudColor="#0369A1",borderColor="#0369A1",paddingX=0,paddingY=0,onClick=()=>{},isLoading=false})=>{
    return (
        <Btn
             className="flex items-center justify-center" color={color}
             backgroundColor={disabled ? "#C6C6C6" : backgroudColor} borderColor={disabled ? "#C6C6C6" :borderColor}
             paddingX={paddingX} paddingY={paddingY}
             onClick={!disabled ? onClick: ()=>{}}
             hover
        >
            <div className="flex gap-4 items-center">
                {label}
                {isLoading ? <i className="fa-solid fa-spinner-third fa-spin"/> : iconName && <i className={`fa-solid fa-${iconName}`}/>}
            </div>
        </Btn>
    )
}

const SignUpErrorModal=({isOpen,handleClose,message="Une erreur s'est produite lors de l'inscription."})=> {
    const {translate} = useHandleClassName();
    const {t:tS}=useTranslation("signup")
    return  (<Modal isOpen={isOpen}>
        <>
            <div className={`ed-modal-title ${translate("bold")}`}>{tS("error_modal_title")}</div>
            <div className={`ed-modal-message ${translate("regular")}`}>
                {message}
            </div>
            <button className={`ed-modal-close-btn ${translate("regular")}`} onClick={handleClose}>
                {tS("error_modal_return")}
            </button>
        </>
    </Modal>)
}



export default SignUp;