import styles from './ranking.module.css'
import {useEffect, useState} from "react";
import classNames from "classnames";
import useHandleTranslation from "../../hooks/useHandleTranslation";
const ChampionTree=(props)=>{
    const [percentValues,setPercentValues]=useState([0,0,0]);
    const {isLngFr}=useHandleTranslation();
    const maxValue=props.data.reduce((maxValue,currentValue)=>(Math.max(maxValue,currentValue.value)),0);
    useEffect(() => {
        setPercentValues([250,220,180]);
    }, [props.data]);
 const barTextStyle=classNames({
     [styles.barText]:true,
     [styles.barTextFr]:isLngFr,
     [styles.barTextHidden]:props.data.length===0
 })

        return(
        <div className={styles.championTreeContainer}>
            <div className={styles.championTreeTitle}>{props?.title}</div>
            <div className={styles.barsContainer}>
                <div className={styles.barContainer}>
                    <ChampionMessage text={props?.data[1]?.value}/>
                <div className={`${styles.firstBar} ${styles.bar}`} style={{height:`${percentValues[1]}px`}}>
                     <div className={barTextStyle}>{props?.data[1]?.label}</div>
                </div>
                </div>
                <div className={styles.barContainer}>
                    <ChampionMessage text={props?.data[0]?.value}/>
                <div className={`${styles.secondBar} ${styles.bar}`} style={{height:`${percentValues[0]}px`}}>
                   <div className={barTextStyle}>{props?.data[0]?.label}</div>
                </div>
                </div>
                <div className={styles.barContainer}>
                    <ChampionMessage text={props?.data[2]?.value}/>
                <div className={`${styles.thirdBar} ${styles.bar}`} style={{height:`${percentValues[2]}px`}}>
                     <div className={barTextStyle}>{props?.data[2]?.label}</div>
                </div>
                </div>
            </div>
            <div className={styles.ranksContainer}>
                <div className={styles.rank}>
                    2
                </div>
                <div className={styles.rank}>
                    1
                </div>
                <div className={styles.rank}>
                    3
                </div>
            </div>
        </div>
    )
}
const ChampionMessage=(props)=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="30" viewBox="0 0 47 30" fill="none">
            <path
                d="M0 6C0 2.68629 2.68629 0 6 0H41C44.3137 0 47 2.68629 47 6V18.4068C47 21.7205 44.3137 24.4068 41 24.4068H25.7742L23.121 30L20.0887 24.4068H6C2.6863 24.4068 0 21.7205 0 18.4068V6Z"
                fill="#4B5563"/>
            <text
                id="GT"
                fontSize="9"
                fontWeight="bold"
                letterSpacing="0.7"
                fill="#F0F1F2"

            >
                <tspan x="22.843" y="16" textAnchor="middle">
                    {props.text}
                </tspan>
            </text>
        </svg>
    )
}
export default ChampionTree;