import styles from './search.module.css'
import Icon from '../util/Icon';
import {LoadingOutlined} from "@ant-design/icons";
import * as React from "react";
import useHandleClassName from "../../hooks/useHandleClassName";
import {useEffect, useRef, useState} from "react";
import {autoCompleteBold} from "../../util/functionsUtil";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import useHandleTranstation from "../../hooks/useHandleTranslation";
import useComponentVisible from "../../hooks/useComponentVisible";

const GlobalSearch=(props)=>{
    const {translate}=useHandleClassName();
    const inputRef=useRef(null);
    const [searchInput,setSearchInput]=useState()
    const {ref,isOpen:isMenuOpen,setOpenStatus:setMenuStatus}=useComponentVisible();

    const {isLngAr,isLngFr}=useHandleTranstation();
    const onSearchMenuClick=(index)=>{
        setMenuStatus(false);
        inputRef.current.value =props.menu[index];
      props.onMenuClick(props.menu[index]);
    }
    useEffect(() => {
        setMenuStatus(props.menu && props.menu?.length !== 0)
    }, [props.menu]);
    const onSearchChange=(e)=>{
        if (e.target.value.length<3) setMenuStatus(false);
        props.onChange(e.target.value);
        setSearchInput(e.target.value);
    }
    const onSearchClick=()=>{
        setMenuStatus(false);
        props.onClick && props.onClick();
    }
    const menuClass=classNames({
        [styles.searchMenu]:true,
        [styles.notDisplay]:!isMenuOpen,
    })
    const inputStyleByLng=classNames({
        [styles.rtlSearchInput]:isLngAr,
        [styles.ltrSearchInput]:isLngFr,
        [styles.openMenuBorderRadius]:isMenuOpen
    })
    const buttonStyleByLng=classNames({
        [styles.rtlSearchButton]:isLngAr,
        [styles.ltrSearchButton]:isLngFr,
        [styles.openMenuBorderRadius]:isMenuOpen
    })
    const searchInputStyles=classNames({
        [styles.articleSearchInput]:true,
        [styles.openMenuBorder]:isMenuOpen
    })
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
           if (event.target.value.trim()!=="") onSearchClick()
        }
    };
    return (
        <>
        <div className={styles.articleSearchContainer} ref={ref}>
            <input ref={inputRef} onKeyPress={handleKeyPress} className={`${searchInputStyles} ${inputStyleByLng} ${translate("regular")}`} onChange={(e)=>onSearchChange(e)} placeholder={props.placeholder}/>
            <div className={`${styles.articleSearchButton} ${buttonStyleByLng}`}><button onClick={onSearchClick} className={"button-none-button "+styles.whiteColor}>{props.loading ?
                <i className="fa-solid fa-spinner-third fa-spin"/> :
                <i className="fa-solid fa-magnifying-glass"/>}</button>
            </div>
        </div>
            <div className={"relative"}>
     <div className={`${menuClass} ${translate("regular")}`}>
        {props.menu.map((item,index)=><div key={index} className={styles.searchMenuItem} onClick={()=>onSearchMenuClick(index)}>{autoCompleteBold(searchInput,item)
            .map((element,key)=>(element.isBold ?(<b key={key}>{element.label}</b>):element.label))}</div>)}
    </div>
            </div>
        </>
    )
}
export default GlobalSearch;