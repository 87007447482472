import React, { Component } from 'react'
import Icon from '../../components/util/Icon';
import LinkAncre from './LinkAncre'


export class TopGridComponent extends Component {
    render() {
        
        return (
            <div className='topcomponent'>
                <ItemsImg className={this.props.className} items={this.props.data.children}/>
                <ItemsTitle className={this.props.className} items={this.props.data.children}/>
                <ItemsDescription className={this.props.className} items={this.props.data.children}/>
                <ItemsLink className={this.props.className} items={this.props.data.children}/>
            </div>
        )
    }
}
export class TopFlexComponent extends Component {
    render() {

        return (
            <div className='topflexcomponent'>
                <Items className={this.props.className} items={this.props.data.children}/>
            </div>
        )
    }
}


const Item =( props )=> {
    return (
        <div className={'topflexcomponent-item'}>
            <div className='topcomponent-item-img'>
                <Icon iconType='light' iconName={props.data.icon}/>
            </div>
            <div className='topcomponent-item-title'>{props.data.title}</div>
            <div className='topcomponent-item-description'>{props.data.paragraph}</div>
            <LinkAncre data={ props.data.link } link={ props.data.__link } newTab />
        </div>
    )
}

const ItemsImg =( props )=> {
    return (
        <div className={'topcomponent-item'}>
            {props.items?.map?.((item,index) => (
                <div key={index} className='topcomponent-item-img'>
                <Icon iconType='light' iconName={item.icon}/>
                </div>))}

        </div>
    )
}
const ItemsTitle =( props )=> {
    return (
        <div className={'topcomponent-item'}>
            {props.items?.map?.((item,index) => (
                <div key={index} className='topcomponent-item-title'>{item.title} </div>))}
        </div>
    )
}
const ItemsDescription =( props )=> {
    return (
        <div className={'topcomponent-item'}>
            {props.items?.map?.((item,index) => (
                <div key={index} className='topcomponent-item-description'>{item.paragraph}</div>
                ))}
        </div>
    )
}
const ItemsLink =( props )=> {
    return (
        <div className={'topcomponent-item'}>
            {props.items?.map?.((item,index) => (
                <LinkAncre key={index} data={ item.link } link={ item.__link } newTab />
            ))}
        </div>
    )
}
const Items =( props )=> {
    return (
        <div className={`topcomponent-items ${props.className}`}>
            {
                props.items?.map?.(    (item,index) => <Item key={index} data={item}/>    )
            }  
        </div>
    )
}
