import React, {Component, useEffect, useState} from 'react'

export default function ButtonRadio(props) {
const [isChecked,setCheckStatus]=useState(false);

useEffect(()=>{
   ;setCheckStatus(props.isChecked)},[props.isChecked])
        return (
        <div className='global-component-button-radio'>
            <span className='global-component-button-radio-point' onClick={()=>{props.onCheck(!isChecked);setCheckStatus(!isChecked);}} style={ { "backgroundColor": isChecked ?"#0369A1":"#ccc"} }><span style={ { "left" : isChecked ? "53%" : "0" } }></span></span>
            <span className='global-component-button-radio-label'>{props.label}</span>
        </div>
        )

}
