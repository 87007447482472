import styles from './signupcard.module.css'
import useHandleTranslation from "../../hooks/useHandleTranslation";
const SignProfileCard=({title,description,value,iconName,icon,onClick})=>{
    const {getInverseDirX}=useHandleTranslation();
    return(
        <div className={styles.cardContainer} onClick={()=>onClick({label:title,value,icon})}>
            <div className="flex">
            <div className={styles.iconContainer}>
                {iconName? (<i className={`fa-thin fa-${iconName} text-3xl ${styles.icon}`}/>): icon }
            </div>
            <div className={styles.cardContentContainer}>
                <label className={styles.cardTitle}>{title}</label>
                <label className={styles.cardDescription}>{description}</label>
            </div>
            </div>
            <div className={styles.cardArrowContainer}>
                <i className={`fa-solid text-[#0369A1] fa-${getInverseDirX()}`}></i>
            </div>
        </div>
    )
}
export default SignProfileCard;