import PropTypes from 'prop-types';
import {EmptySearchData} from "../../emptyData/EmptyData";

const TEmptyBody = ({
                        columnSize,
                        error,
                        primaryMessage,
                        secondaryMessage,
                    }) => {
    return (
        <tbody className="bg-white divide-y divide-gray-200">
        <tr>
            <td colSpan={columnSize}>
                <div className="py-12">
                <EmptySearchData primaryMessage={primaryMessage} secondaryMessage={""}/>
                </div>
            </td>
        </tr>
        </tbody>
    );
};
export default TEmptyBody;

TEmptyBody.propTypes = {
    columnSize: PropTypes.number,
    error: PropTypes.bool,
    primaryMessage: PropTypes.string,
    secondaryMessage: PropTypes.string,
    emptyDataSecondaryMessage: PropTypes.string,
    emptyDataPrimaryMessage: PropTypes.string,
};