import './modal.css'
import classNames from "classnames";
const Modal = ({ isOpen, children }) => {
    const modalStyle=classNames({
        ["modal"]:true,
        ["hide-modal"]:!isOpen,
        ["show-modal"]:isOpen
    })
    const modalContainerStyle=classNames({
        ["modal-overlay"]:true,
        ["close-modal"]:!isOpen,
    })
    return (
        <>
                <div className={modalContainerStyle}>
                    <div className={modalStyle}>
                        {children}
                    </div>
                </div>
        </>
    );
};

export default Modal;