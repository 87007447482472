import PropTypes from 'prop-types';
const BodyCellAction = ({ actionCell, actions }) => {
    return (
        <>
            <td
                {...actionCell.getCellProps()}
                className="px-6 py-4 whitespace-nowrap"
                role="cell"
            >
                {actions.map((action, index) => {

                })}
            </td>
        </>
    );
};
export default BodyCellAction;
BodyCellAction.propTypes = {
    actionCell: PropTypes.object,
    actions: PropTypes.array,
};