import axios from 'axios';
import { BASE_URL } from '../resources/current';
const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
};

const source = axios.CancelToken.source();

const _axios = axios.create({
    baseURL: BASE_URL,
    cancelToken: source.token,
    withCredentials: false,
});
axios.defaults.headers.common['Content-Type'] = 'application/json';
const getAxiosClient = () => _axios;

const HttpService = {
    HttpMethods,
    getAxiosClient,
};

export default HttpService;