 import styles from './globalLoader.module.css'
 import spinnerLogo from '../../asset/images/rokhass-spinner.png'
 import classNames from "classnames";
 import {useEffect} from "react";
const GlobalLoader =(props)=> {
    const loaderStyle=classNames({
        [styles.loaderContainer]:true,
        [styles.notDisplay]:!props.loading
    })
    useEffect(() => {
      document.body.style.overflow=props.loading ? "hidden":"auto";
    }, [props.loading]);
        return (
            <div className={loaderStyle}>
            <div className={styles.loaderItemContainer}>
                <img src={spinnerLogo} alt="" className={styles.loaderImg}/>
            </div>
            </div>
                )

}
export default GlobalLoader;