import styles from "./sign.module.css";
import React, {createContext, useContext, useEffect, useState} from "react";
import {LogoFr} from "../../asset/images/svgImages";
import Btn from "../../components/button/Btn";
import classNames from "classnames";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import globalPagesStyles from "../globalstyles.module.css";
import SignCarousel from "../../pagescomponents/login/SignCarousel";
import LegalFooter from "../../pagescomponents/login/LegalFooter";
import {useTranslation} from "react-i18next";
import {toLogin, toSignUp} from "../../util/functionsUtil";
import Stepper from "../../pagescomponents/login/Stepper";
import {SIGN_PASSWORD_FORGOT} from "../../holders/schemas";
import useRegisterForm from "../../hooks/useRegisterForm";
import {useSendEmailResetPassword} from "../../api/caller";
import useHandleClassName from "../../hooks/useHandleClassName";
import Modal from "../../components/modal/Modal";
import InputForm from "../../components/input/InputForm";
import {SIGN_UP_URL} from "../../util/constants";
import {useNavigate} from "react-router-dom";



const StepsContext=createContext();
const PasswordForget=()=>{
    const {isLngAr,isLngFr}=useHandleTranslation();
    const [activeStep,setActiveStep]=useState(0);
    const [isModalOpen, setModalStatus]=useState(false);
    const  [modalMessage,setModalMessage]=useState(null);
    const stepsOptions= {stepA:{display:true,skip:false},stepB:{display:true,skip:false}}
    const steps=[
        {key: 0, name: "stepA", slide: EmailCheckerStep,props:{}},
        {key: 1, name: "stepB", slide: EmailNotificationStep,props:{}}
    ]
    const toLastStep=()=>{
        setActiveStep(1);
    }
    const forms= {
        stepA: useRegisterForm(SIGN_PASSWORD_FORGOT),
    }
    const pageContentContainerStyles=classNames({
        [styles.pageContentContainerAr]:isLngAr,
        [styles.pageContentContainerFr]:isLngFr,
        [styles.pageContentContainer]:true
    })
    return (
        <div className="flex w-full gap-2">
            <div className={`${styles.carouselContainer} ${globalPagesStyles.responsiveWeb}`}>
                <SignCarousel/>
            </div>
            <div className={pageContentContainerStyles}>
                <div className={styles.minHight}>
                    <div className={styles.logoContainer}>
                        <LogoFr/>
                    </div>
                    <StepsContext.Provider value={{toLastStep,setModalMessage,setModalStatus}}>
                    <Stepper active={activeStep} validator={forms} steps={steps} displayed={stepsOptions}/>
                    </StepsContext.Provider>
                </div>
                <LegalFooter/>
            </div>
            <SendEmailErrorModal isOpen={isModalOpen} message={modalMessage} handleClose={()=>setModalStatus(false)}/>
        </div>)
}

 const EmailCheckerStep = ({validate}) =>{
     const {toLastStep,setModalMessage,setModalStatus} = useContext(StepsContext);
     const {t: tS} = useTranslation("signup")
     const sendEmailResetPassword=useSendEmailResetPassword();
     const navigate=useNavigate();
     const onSubmitLogin=(data)=>{
         sendEmailResetPassword.mutate(data.email);
     }

     useEffect(() => {
      if (sendEmailResetPassword.customHandler.isSuccess ) toLastStep();
     }, [sendEmailResetPassword.customHandler.isSuccess]);


     useEffect(() => {
         if (sendEmailResetPassword.customHandler.isErrorMessageExist ){
             setModalMessage(sendEmailResetPassword.customHandler.errorMessage);
             setModalStatus(true);
         }
     }, [sendEmailResetPassword.customHandler.isError]);

     return (
        <div className="flex flex-col justify-between h-full">
        <div>
            <p className={styles.pageTitle}>{tS("sign_changepass_emailnotif_title")}</p>
            <p className={styles.pageTitleDescription}>{tS("sign_changepass_emailnotif_description0")}</p>
        </div>
     <div className="grid grid-rows-2  gap-2 w-2/3 mx-auto">
         <div className="flex flex-col gap-6">
             <label className={styles.forgetPassInputLabel}>{tS("sign_changepass_emailenter_input1_label")}</label>
             <InputForm containerClassName="!rounded-md" register={validate?.register} name={"email"} placeholder={tS("sign_changepass_emailenter_input1_placeholder")}
                        errors={validate?.formState?.errors} important withoutLabel/>
         </div>
         <div>
             <div className="pt-3 w-1/2 mx-auto pb-9">
                 <SignInBtn label={tS("sign_changepass_emailenter_button")} isLoading={sendEmailResetPassword.isLoading} paddingY={18} onClick={validate?.handleSubmit(onSubmitLogin)}/>
             </div>
             <div className={styles.singIn} >
                 {tS("sign_changepass_legal_footer_label")}
                 <span className={styles.signLink} onClick={()=>navigate(SIGN_UP_URL)}>{tS("sign_changepass_legal_footer_link")}</span>
             </div>

         </div>
     </div>
            </div>
 )
 }
 const EmailNotificationStep=()=>{
     const {t: tS} = useTranslation("signup")
     return (
       <div className="w-11/12 flex flex-col justify-between gap-2 h-full">
           <div>
               <p className={styles.pageTitle}>{tS("sign_changepass_emailnotif_title")}</p>
               <p className={styles.pageTitleDescription}>{tS("sign_changepass_emailnotif_description")}</p>
           </div>
           <div className="mx-auto flex flex-col items-center justify-center gap-10">
               <i className="fa-regular fa-square-check !text-primary text-7xl"></i>
               <div className="flex flex-col items-center gap-3">
                   <span className={styles.pageSignUpMessage}>{tS("sign_changepass_emailnotif_description2_1")}</span>
                   <span className={styles.pageSignUpMessage}>{tS("sign_changepass_emailnotif_description2_2")}</span>

               </div>
               <SignInBtn label={tS("login_button")} onClick={()=>toLogin()} paddingX={100} paddingY={12}/>
               <a className={styles.signLink} onClick={() => toHome()}>{tS("return_button")}</a>
           </div>

       </div>
   )
 }

const SignInBtn = ({
                       label,
                       disabled = false,
                       iconName,
                       color = "#ffffff",
                       backgroudColor = "#0369A1",
                       borderColor = "#0369A1",
                       paddingX = 0,
                       paddingY = 0,
                       onClick = () => {
                       },
                       isLoading = false
                   }) => {
    return (
        <Btn
            className="blue-button flex items-center justify-center" color={color}
            backgroundColor={disabled ? "#C6C6C6" : backgroudColor} borderColor={disabled ? "#C6C6C6" : borderColor}
            rootClassName={"!max-w-full"}
            paddingX={paddingX} paddingY={paddingY}
            onClick={!disabled ? onClick : () => {
            }}
        >
            <div className="flex gap-4 items-center">
                {label}
                {isLoading ? <i className="fa-solid fa-spinner-third fa-spin"/> : iconName && <i className={`fa-solid fa-${iconName}`}/>}
            </div>
        </Btn>
    )
}

const SendEmailErrorModal=({isOpen,handleClose,message})=> {
    const {translate} = useHandleClassName();
    const {t:tSE}=useTranslation("serverErrors")
    const {t:tS}=useTranslation("signup")

    return  (<Modal isOpen={isOpen}>
        <>
            <div className={`ed-modal-title ${translate("bold")}`}>{tS("error_modal_title")}</div>
            <div className={`ed-modal-message ${translate("regular")}`}>
                {tSE(message)}
            </div>
            <button className={`ed-modal-close-btn ${translate("regular")}`} onClick={handleClose}>
                {tS("error_modal_return")}
            </button>
        </>
    </Modal>)
}


export default PasswordForget;