import React, { useEffect } from 'react'
import "../../pagescomponents/styles.css" ;

const Btn= (props)=> {
    return (
      <div className={`global-btn-container ${props.hover ? "global-btn-container-hover":""} ${props.rootClassName}`}>
        <span  className={"global-btn " + props.className } target={"_blank"}
            style={ { "color" : props.color ,
                      "backgroundColor" : props.backgroundColor , 
                      "borderColor" : props.borderColor,
                       "padding": `${props.paddingY}px ${props.paddingX}px`,
        } }
            onClick={ props.onClick }>
            {props.children}
            {props.content}
                
        </span>
      </div>
    )

}
export default Btn;
