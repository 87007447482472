import React, {useEffect, useRef, useState} from 'react';
import { PropTypes } from 'prop-types';
import Step from './Step';
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import '../component.css';

let styles = {
  stepper: {
    display: 'flex',
    margin: '0 3%',
    padding:"0px 1%",
    whiteSpace: 'nowrap',
  },
  arrow:{
    marginTop: `${40}px`,
    position:'relative',
    height:"100%",
    zIndex:1,
    padding: "0px 5px"
  },
  blueArrow:{
    color:'#0369A1'
  },
  disabledArrow:{
    color:'#9CA3AF'
  }
};

const Stepper=({ activeStep, steps, disabledSteps,
                activeColor, completeColor, defaultColor, circleFontColor,
                activeTitleColor, completeTitleColor, defaultTitleColor,
                size, circleFontSize, titleFontSize,
                circleTop, titleTop, completeOpacity, activeOpacity, defaultOpacity,
                completeTitleOpacity, activeTitleOpacity, defaultTitleOpacity, barStyle,
                defaultBorderColor, completeBorderColor, activeBorderColor, defaultBorderStyle,
                completeBorderStyle, activeBorderStyle, defaultBarColor, completeBarColor, lineMarginOffset, defaultBorderWidth,maxSteps,className})=>{

  const containerRef = useRef(null);
  const activeStepRef=useRef(null);
  const COMPLETED_STATUS="done";
  const ACTIVE_STATUS="active";
  const PREVIEW_STATUS="preview";
  const [currentIndex, setCurrentIndex] = useState(0);
  const swiperSlideStyles = {
    flex: `0 0 calc(100% /${maxSteps} )`,
    height: '100%',
    position: 'relative',
    transitionProperty: 'transform',
    display: 'block',
  };
  const onRightOutlinedClick=()=>{
    if(currentIndex===steps.length-maxSteps) return;
    scrollToIndex(currentIndex+1);
  }
  const onLeftOutlinedClick=()=>{
    if(currentIndex===0) return;
    scrollToIndex(currentIndex-1);
  }
  const scrollToIndex = (index,funct=(()=>{})) => {
    setCurrentIndex((prevState)=>index);
    const horizontalScrollElement = containerRef.current;
    if (horizontalScrollElement) {
      const child = horizontalScrollElement.children[index];
      child.scrollIntoView({ behavior: 'smooth',  block: 'nearest', inline: 'start'});
    }
  };
  const isLefBtnDisabled=()=>{
    return currentIndex === 0
  }
  const isRightBtnDisabled=()=>{
    return currentIndex===steps.length-maxSteps;
  }
  let stepperStyle=Object.assign(
      {},
       styles.stepper,
      steps.length<=7 ? {justifyContent:'center'}:{},
  )
  const scrollVertically=()=>{
    const verticalScrollElement = document.getElementById('data-display');
    if (verticalScrollElement) {
      verticalScrollElement.scrollIntoView({ behavior: 'smooth',  block: 'start', inline:"nearest"});
    }
  }
  useEffect( () => {
      scrollVertically();
      scrollHorizontally();
  }, [activeStepRef.current]);

  const scrollHorizontally=()=>{
    if(activeStepRef.current!==null ){
      const index= activeStep<steps.length-maxSteps ? activeStep-maxSteps:steps.length-maxSteps;
      setTimeout(()=>scrollToIndex(Number(index)),300);
    }
    else if(steps.length>maxSteps) setTimeout(()=>scrollToIndex(steps.length-maxSteps),300);
    }

  const isStepActive=(index)=>(!(disabledSteps || []).includes(index) && index === activeStep);
  return (
    <div className={`${className} swiper-container`} dir="ltr">
      <div style={styles.arrow} ><button   onClick={onLeftOutlinedClick} className={"button-none-button swiper-button-prev"}>{(<LeftOutlined style={!isLefBtnDisabled()? styles.blueArrow:styles.disabledArrow}/>)}</button></div>
      <div style={stepperStyle} className={"swiper-wrapper"} ref={containerRef} >
      { steps.map((step, index) => (
          <Step
              {...isStepActive(index)?{ref:activeStepRef}:{}}
            key={index}
            width={100 / steps.length}
            title={step.label}
            icon={step.icon}
            href={step.href}
            onClick={step.onClick}
            active={step.status===ACTIVE_STATUS}
            completed={step.status===COMPLETED_STATUS}
            first={index === 0}
            isLast={index === steps.length - 1}
            index={index}
            activeColor={activeColor}
            completeColor={completeColor}
            defaultColor={defaultColor}
            circleFontColor={circleFontColor}
            activeTitleColor={activeTitleColor}
            completeTitleColor={completeTitleColor}
            defaultTitleColor={defaultTitleColor}
            size={size}
            circleFontSize={circleFontSize}
            titleFontSize={titleFontSize}
            circleTop={circleTop}
            titleTop={titleTop}
            defaultOpacity={defaultOpacity}
            completeOpacity={completeOpacity}
            activeOpacity={activeOpacity}
            defaultTitleOpacity={defaultTitleOpacity}
            completeTitleOpacity={completeTitleOpacity}
            activeTitleOpacity={activeTitleOpacity}
            barStyle={barStyle}
            defaultBorderColor={defaultBorderColor}
            completeBorderColor={completeBorderColor}
            activeBorderColor={activeBorderColor}
            defaultBorderStyle={defaultBorderStyle}
            defaultBorderWidth={defaultBorderWidth}
            completeBorderStyle={completeBorderStyle}
            activeBorderStyle={activeBorderStyle}
            defaultBarColor={defaultBarColor}
            completeBarColor={completeBarColor}
            lineMarginOffset={lineMarginOffset}
            description={step.description}
            slideStyles={swiperSlideStyles}
          />
        )) }
      </div>
      <div  style={styles.arrow}> <button onClick={onRightOutlinedClick} className={"button-none-button swiper-button-next"}>{ (<RightOutlined style={!isRightBtnDisabled()?styles.blueArrow:styles.disabledArrow}/>)}</button></div>
    </div>
  );
}

Stepper.defaultProps = {
  activeStep: null,
  maxSteps:7,
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
  activeColor: PropTypes.string,
  completeColor: PropTypes.string,
  defaultColor: PropTypes.string,
  activeTitleColor: PropTypes.string,
  completeTitleColor: PropTypes.string,
  defaultTitleColor: PropTypes.string,
  circleFontColor: PropTypes.string,
  size: PropTypes.number,
  circleFontSize: PropTypes.number,
  titleFontSize: PropTypes.number,
  circleTop: PropTypes.number,
  titleTop: PropTypes.number,
  defaultOpacity: PropTypes.string,
  completeOpacity: PropTypes.string,
  activeOpacity: PropTypes.string,
  defaultTitleOpacity: PropTypes.string,
  completeTitleOpacity: PropTypes.string,
  activeTitleOpacity: PropTypes.string,
  barStyle: PropTypes.string,
  defaultBarColor: PropTypes.string,
  completeBarColor: PropTypes.string,
  defaultBorderColor: PropTypes.string,
  completeBorderColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
  defaultBorderStyle: PropTypes.string,
  completeBorderStyle: PropTypes.string,
  activeBorderStyle: PropTypes.string,
  lineMarginOffset: PropTypes.number,
  defaultBorderWidth: PropTypes.number
};

export default Stepper;
