import styles from "../errorPages/errorPages.module.css";
import ErrorPageIcon from "../../asset/images/ErrorPageIcon";


const ErrorBoundaries=(props)=>{
    return (
        <div className="flex justify-center">
        <div className="relative">
            <span className={styles.errorTitle}>{props.text}</span>
            <ErrorPageIcon/>
        </div>
    </div>
    )
}
export default ErrorBoundaries;