import React, { Component } from 'react'

const  TitleParagraph =(props)=>{
        return (
            <div className='title-paragraph'>
                <Title title={props.title} color={props.titleColor}/>
                <Paragraph paragraph={props.paragraph} color={props.paragraphColor}/>
            </div>
        )
}


const Title =( props )=> {
    return (
        <h2 className='title' style={{color:props.color}} >{props.title}</h2>
    )
}

const Paragraph= ( props )=> {
    return (
        <div className='paragraph' style={{color:props.color}}>{props.paragraph}</div>
    )
}
export default TitleParagraph;
